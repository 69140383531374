import React from 'react';
import './style.scss';

const Checkbox = ({isCircle=false, ...rest}) => {
return(
    <span className={`blue-checkbox ${isCircle ? 'circle' : ''}`}>
        <input type='checkbox' {...rest}/>
        <span></span>
    </span>
)
}

export default Checkbox;