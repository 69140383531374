import React from 'react';
// import Logo from '../../assets/images/404_desktop.png';
import './style.css'


const PageNotFound = () => {
  return (
    <div id="error404">
    <div className="content-container text-center">
      <div className="container">
        <div className="img-contr">
          {/* <img src={Logo} className="img-fluid for-desktop" alt="desktop" /> */}
          {/* <img src="/assets/images/404_mobile.png" className="img-fluid for-mobile" alt="mobile" /> */}
        </div>
        <p className="error-message">
The page you are looking for is not available
          {' '}
          <br />
            or has been moved.
        </p>
        </div>
    </div>
  </div>
  )
}

export default PageNotFound