import React, { useState, useEffect, useRef } from 'react';
import arrow from "../../../assets/images/arrow.png";
import arrowActive from "../../../assets/images/arrow-active.png";

import './style.scss';
import SearchBar from '../../SearchBar';
import Checkbox from './../Checkbox/index';

const CustomDropdown = ({
    icon,
    options,
    onChange,
    customDropdownStyle,
    placeHolder = 'select',
    value = null,
    propWidth,
    showSearchBar,
    showValue,
    showClear,
    handleClear,
    type = null,
    className,
    isHtmlOption = false,
    optionLocation = '', // up - for upside
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const wrapperRef = useRef(null);
    const innerWrapperRef = useRef(null);
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)
                && innerWrapperRef.current && !innerWrapperRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, innerWrapperRef]);

    const toggle = (e) => {
        if (e.target.tagName !== 'INPUT') {
            setIsOpen(!isOpen)
        }

    };
    const handleClick = (value) => {
        setIsOpen(false)
        onChange(value)
    }
    const handleSelectClick = (selectValue) => {
        if (selectValue === 'clear') {
            onChange([]);
            return;
        }
        let updatedValue = [...value];
        if (value.includes(selectValue)) {
            updatedValue.splice(value.indexOf(selectValue), 1)
        }
        else {
            updatedValue.push(selectValue)
        }
        onChange(updatedValue)
    }
    const handleOptionChange = (value) => {
        setInputValue(value)
    }
    const selectedValue = () => {
        try {
            let selectedValue = placeHolder;
            if (type === 'multi') {
                if (value?.length > 0)
                    selectedValue = value.map((item, ind) => <span className='value-tile' key={item}>{options.find(x => x.value === item)?.name}</span>)
                else
                    selectedValue = placeHolder;
            }
            else
                selectedValue = isHtmlOption ? options?.find(x => x.value === value)?.htmlName || placeHolder : options?.find(x => x.value === value)?.name || placeHolder;

            return selectedValue
        } catch (err) {
            console.error(err)
        }
    }
    const filteredOptionsByname = options && options.filter(optionObj => optionObj?.name?.toLowerCase()?.startsWith(inputValue?.toLowerCase()) || optionObj?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()));

    const newOptionsArray = inputValue !== '' ? filteredOptionsByname : options;
    return (<div onClick={toggle} className={`custom-dropdown-wrap ${type === 'multi' ? 'multi-select' : ''} ${className}`} {...rest}>
        <div className={`${customDropdownStyle ? 'user-profile user-unique' : 'user-profile'}`} ref={wrapperRef}>
            {icon && <img className='icon' src={icon} alt='icon' />
            }            <div className={`user-name ${type === 'multi' && value?.length > 0 ? 'blur-corner' : ''}`}>
                {type === 'multi' ? <span style={isOpen ? { color: '#0945B5' } : {}} className='uname' >{selectedValue()}</span>
                    : isHtmlOption ?
                        <span style={isOpen ? { color: '#0945B5' } : {}} className='uname' dangerouslySetInnerHTML={{ __html: selectedValue() }}></span>
                        :
                        <span style={isOpen ? { color: '#0945B5' } : {}} className='uname' >{selectedValue()}</span>}
            </div>
            <img className={`dropdown-icon ${isOpen ? 'icon-active' : ''}`} src={isOpen ? arrowActive : arrow} alt='arrow' />
        </div>
        <div style={propWidth ? { width: propWidth } : {}} className={`collapse-toggle collapse ${optionLocation} ${isOpen ? 'show' : ''}`} >
            <div className='dropdown-wrapper' ref={innerWrapperRef}>
                {showSearchBar && <SearchBar placeholder="Search"
                    handleOnChange={(e) => handleOptionChange(e.target.value)}
                />}
                {showClear && ((type !== 'multi' && value) || (type === 'multi' && value.length > 0)) && <div className='clearfix'><span className='clear-dropdown' onClick={() => type === 'multi' ? handleSelectClick('clear') : handleClick('clear')}>Clear Selection</span></div>}
                {type === 'multi' ?
                    <ul className='dropdown-body'>{Array.isArray(newOptionsArray) && newOptionsArray.map((item, ind) => (
                        <li className='checkbox-li' key={`${item.value}-${ind}`} onClick={(e) => { handleSelectClick(item.value); e.stopPropagation() }} >
                            <Checkbox checked={value.includes(item.value)} />
                            {showValue ? item.value : item.name}
                        </li>
                    ))}
                    </ul>
                    :
                    <ul>

                        {Array.isArray(newOptionsArray) && newOptionsArray.map((item, ind) => (
                            isHtmlOption ?
                                <li className={item.value === value ? 'current' : ''} key={`${item.value}-${ind}`} onClick={() => handleClick(item.value)} dangerouslySetInnerHTML={{ __html: item.htmlName }}></li>
                                :
                                <li className={item.value === value ? 'current' : ''} key={`${item.value}-${ind}`} onClick={() => handleClick(item.value)} >{showValue ? item.value : item.name}</li>
                        ))}
                    </ul>}
            </div>
        </div>
    </div>
    )
}

export default CustomDropdown;