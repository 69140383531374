import React, { useEffect, useRef } from 'react';
import './index.scss'; // Ensure you have the CSS file for styling

const CustomModal = ({ children, onClose }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="custom-modal">
            <div className="custom-modal-content" ref={modalRef}>
                {children}
            </div>
        </div>
    );
};

export default CustomModal;
