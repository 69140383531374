import React from "react";
import Image from "../Image";
import dummy from "../../../assets/images/female.svg";
import homeIcon from "../../../assets/images/homeIcon.svg";
import "./style.scss";

const SelectDidiCard = ({ id, name = "", address = "", tola = "", village = "", handleSelect, selectedDidis = [], didiImgUrl=null }) => {
    return(
        <div className="select-didi-card"> 
            <label htmlFor={id}>
                <input type="checkbox" id={id} onChange={e => handleSelect(e, id)} checked={selectedDidis?.some(x => x === id)}/>
                <div className="dp-wrap"><Image imgUrl={didiImgUrl} placeholder={dummy} alt='Didi img' className='item-img' /></div>
                <div className="didi-detail-wrap">
                    <h3>{name}</h3>
                    <div className="address"><p><img src={homeIcon} alt="home icon" />{address}, {tola}</p>
                        <p>{village?.toLowerCase()}</p>
                    </div>
                </div>
            </label>
        </div>
    )
}

export default SelectDidiCard;