import React, { useState, useEffect } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
// import { ModalHeader } from 'reactstrap';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import './style.scss';
import PreLoader from '../../Preloader';
import { commonService } from "../../../../services/common";
import GlobalConfig from '../../../../config/globalconfig';
import UploadFile from '../../UploadFile';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { useDispatch } from 'react-redux';
import { setApiError, resetApiError } from '../../../../storeManager/common/actions';


const inputFields = () => ({
    file1: {
        id: 'file1',
        value: '',
        accept: '.jpg, .jpeg, .png',
        fileName: '',
        isError: false,
        errorMsg: ''
    },
    file2: {
        id: 'file2',
        value: '',
        accept: '.jpg, .jpeg, .png',
        fileName: '',
        isError: false,
        errorMsg: ''
    },
    file3: {
        id: 'file3',
        value: '',
        accept: '.jpg, .jpeg, .png',
        fileName: '',
        isError: false,
        errorMsg: ''
    },
    file4: {
        id: 'file4',
        value: '',
        accept: '.jpg, .jpeg, .png',
        fileName: '',
        isError: false,
        errorMsg: ''
    },
    file5: {
        id: 'file5',
        value: '',
        accept: '.jpg, .jpeg, .png',
        fileName: '',
        isError: false,
        errorMsg: ''
    }
})

const UploadModal = ({ modalParameters: { formName, villageId, flowType, callBackFn = null }, closePopup }) => {
    const [formInfo, setFormInfo] = useState(inputFields());
    // const [file, setFile] = useState('');
    // const [isError, setIsError] = useState('');
    // const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetApiError())
    }, [])
    const handleChange = (e, id) => {
        const updatedForm = { ...formInfo };
        const updatedFormElement = { ...updatedForm[id] };
        updatedFormElement.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedFormElement.value = e.currentTarget.files[0]
        if ((e.currentTarget.files[0].size / 1024).toFixed(4) > 500) {
            updatedFormElement.errorMsg = `File size exceeds the max allowed size`
            updatedFormElement.isError = true
        } else if (e.currentTarget.files[0].type !== 'image/jpeg'
            && e.currentTarget.files[0].type !== 'image/png') {
            updatedFormElement.errorMsg = `File Format do not support`
            updatedFormElement.isError = true
        } else {
            updatedFormElement.errorMsg = ``
            updatedFormElement.isError = false
        }
        updatedForm[id] = updatedFormElement;
        setFormInfo(updatedForm);
    }

    const onSubmit = async () => {
        setIsLoading(true)
        const formElementsArray = [];
        const formKeys = Object.keys(formInfo);
        formKeys.forEach((key) => {
            if (formInfo[key].value)
                formElementsArray.push(formInfo[key].value);
        });
        try {
            const formData = new FormData();
            if (formName === 'C') {
                for (const image of formElementsArray) {
                    if (image)
                        formData.append('formC', image);
                }
            }

            if (formName === 'D') {
                for (const image of formElementsArray) {
                    if (image)
                        formData.append('formD', image);
                }
            }

            formData.append('villageId', JSON.stringify(villageId));
            formData.append('flowType', flowType);
            const response = await commonService.uploadFormCandD(formData);
            const res = response.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setIsSuccess(true);
                callBackFn && callBackFn(formName);
            }
            else
                // dispatch(setApiError())
                setApiErrorMsg(res.message)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            setApiErrorMsg(error?.response?.data?.error)
            catchErrorHandler(error, dispatch(setApiError()));
            console.error(error)
        }
    }
    const handleDelete = (id) => {
        const updatedForm = { ...formInfo };
        const updatedFormElement = { ...updatedForm[id] };
        updatedFormElement.fileName = '';
        updatedFormElement.errorMsg = ``;
        updatedFormElement.isError = false;
        updatedFormElement.value = '';
        updatedForm[id] = updatedFormElement;
        setFormInfo(updatedForm);
    }
    const formElementsArray = [];
    const formKeys = Object.keys(formInfo);
    formKeys.forEach((key) => {
        formElementsArray.push(formInfo[key]);
    });

    if (isLoading) {
        return <PreLoader />
    }
    return (
        <div className='upload-modal'>
            {isSuccess ?
                <ModalBody>
                    <h3>File Uploaded Successfully</h3>
                </ModalBody>
                : <ModalBody>
                    <ol>
                        {formElementsArray && formElementsArray.map((item, ind) => {
                            return (<li>
                                <UploadFile
                                    id={item.id}
                                    value={item.value}
                                    accept={item.accept}
                                    handleChange={e => handleChange(e, item.id)}
                                    fileName={item.fileName}
                                    isError={item.isError}
                                    errorMsg={item.errorMsg}
                                    key={item.id}
                                    handleDelete={handleDelete}
                                    showPreview={true}
                                />
                            </li>)
                        })
                        }
                    </ol>
                    <ul className='upload-note'>
                        <li>Max upload file size: 500 KB</li>
                        <li>Accepted format photo</li>
                    </ul>
                </ModalBody>}
            {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>}
            <ModalFooter>
                {isSuccess ? <>
                    <button className="primary-btn" onClick={closePopup}>
                        OK
                    </button>
                </>
                    :
                    <>
                        <button className="secondary-btn ml" onClick={closePopup}>
                            Cancel
                        </button>
                        <button className="primary-btn" onClick={onSubmit} disabled={formElementsArray.some(x => x.isError) || !formElementsArray.some(x => x.value !== '')}>
                            Submit
                        </button>
                    </>
                }
            </ModalFooter>
        </div>
    )
}
export default UploadModal