import { CLOSE_MODAL, OPEN_MODAL } from './constants';

const initialState = {
  modalParameters: {},
  showModal: false,
  modalName: '',
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        modalParameters: action.data.modalParameters,
        modalName: action.data.modalName,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        modalParameters: null,
        modalName: '',
      };
    default:
      return state;
  }
}
