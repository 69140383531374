import React, { useEffect, useState } from 'react';
import UploadFile from '../../UploadFile';
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import GlobalConfig from '../../../../config/globalconfig';
import { resetApiError, setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import InProcessing from '../../InProcessing';
import axios from 'axios';
import UploadImageIcon from '../../Preloader/UploadImageIcon';
import "./style.scss"

const LivelihoodIconUploadModal = ({ modalParameters: { userType, bodyMsg, setImage, setImageName, setIsdefaultImg }, closePopup }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formInfo, setFormInfo] = useState({
        id: 'users',
        value: '',
        accept: '.jpg, .jpeg, .png',
        fileName: '',
        isError: false,
        errorMsg: ''
    });
    const [dataUploadPercentage, setDataUploadPercentage] = useState();
    const [fileCount, setFileCount] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetApiError());
    }, [dispatch]);

    const handleChange = (e) => {
        const updatedForm = { ...formInfo };
        setApiErrorMsg('');
        updatedForm.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedForm.value = e.currentTarget.files[0];
        if (e.currentTarget.files[0].type !== 'image/jpeg' && e.currentTarget.files[0].type !== 'image/png') {
            updatedForm.errorMsg = 'File Format do not support';
            updatedForm.isError = true;
        } else {
            updatedForm.errorMsg = '';
            updatedForm.isError = false;
        }
        setFormInfo(updatedForm);
    };

    const onSubmit = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', formInfo?.value);
        formData.append('languageCodes', "en");
        formData.append('driveType', "postSelection");

        axios.post(`${GlobalConfig.API_ROOT}/content-manager/content/saveFileContent`, formData, {
            onUploadProgress: (progressEvent) => {
                setDataUploadPercentage((progressEvent.progress * 100).toFixed(1));
            }
        }).then((response) => {
            const res = response.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setImage(formInfo.value && URL.createObjectURL(formInfo.value))
                setImageName(formInfo.fileName)
                setIsdefaultImg('')
                const result = res.data.match(/\d+/);
                const number = parseInt(result[0], 10);
                setFileCount(number);
                setIsSuccess(true);
                closePopup()
            } else {
                setApiErrorMsg(res.message);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            setApiErrorMsg(error?.message);
            catchErrorHandler(error, dispatch(setApiError()));
        });
    };

    const handleDelete = () => {
        const updatedForm = { ...formInfo };
        updatedForm.fileName = '';
        updatedForm.errorMsg = '';
        updatedForm.isError = false;
        updatedForm.value = '';
        setApiErrorMsg('');
        setFormInfo(updatedForm);
    };

    if (isLoading) {
        return <InProcessing page={'livelihood'} title="File uploading is in progress" uploadPercentage={Math.trunc(dataUploadPercentage)} maxPrct={100} />;
    }

    return (
        <div className='livelihood-upload-modal upload-modal'>
            {isSuccess ?
                <ModalBody>
                    <h3>{fileCount} Images Uploaded Successfully</h3>
                </ModalBody>
                : <ModalBody>
                    <h4>{bodyMsg}</h4>
                    <br />
                    <UploadImageIcon
                        id={formInfo.id}
                        value={formInfo.value}
                        accept={formInfo.accept}
                        handleChange={handleChange}
                        imagePath={formInfo.value && URL.createObjectURL(formInfo.value)}
                        isError={formInfo.isError}
                        errorMsg={formInfo.errorMsg}
                        handleDelete={handleDelete}
                        showPreview={true}
                    />
                </ModalBody>}
            {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>}
            <ModalFooter>
                <button className="secondary-btn ml" onClick={closePopup}>
                    Cancel
                </button>
                <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                Upload
                </button>
            </ModalFooter>
        </div>
    );
};

export default LivelihoodIconUploadModal;
