import React, { useEffect, useState } from 'react';
import './style.scss';
import SearchBar from '../../../components/SearchBar';
import Table from '../../../components/Common/Table';
import TableColumns from './TableColumns';
import FileIcon from '../../../assets/images/file-text.svg';
import { openModal } from '../../../storeManager/modal/actions';
import { useDispatch } from 'react-redux';
import { MODALS } from '../../../storeManager/modal/constants';
import CustomModal from '../../../components/Common/Modal';
import { dashBoardService } from '../../Dashboard/dashoardService';
import PreLoader from '../../../components/Common/Preloader';
import { setApiError } from '../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../services/commonUtils';
import GlobalConfig from '../../../config/globalconfig';
import arrow from "../../../assets/images/arrow.png";
import arrowActive from "../../../assets/images/arrow-active.png";
import { useNavigate } from 'react-router-dom';

const Programs = ({tableData, setTableData, fetchProgram, setIsLoading, isLoading}) => {
  const dispatch = useDispatch();
  let uniqueReferenceTypes = new Set();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState([]);
  const [status, setStatus] = useState([]);
  const [filters, setFilters] = useState({ states: [], status: [] });
  const [stateList, setStateList] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [isCollapsedState, setIsCollapsedState] = useState(true);
  const [isCollapsedStatus, setIsCollapsedStatus] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const { roleName } =
  JSON.parse(localStorage.getItem("userAuth")) || "";

  const handleCreateProgram = () => {
    dispatch(
      openModal(MODALS.CREATE_PROGRAM_MODAL, {
        parentClass: 'create-program-list',
        modalTitle: 'Create Program',
        bodyMsg: 'Upload Image',
      })
    );
  };
  const navigate = useNavigate();
  const handleShowFilter = () => setShowFilter(true);
  const handleCloseFilter = () => setShowFilter(false);

  const handleStateChange = (state) => {
    setFilters((prevFilters) => {
      const updatedStates = prevFilters.states.includes(state)
        ? prevFilters.states.filter((s) => s !== state)
        : [...prevFilters.states, state];
      return { ...prevFilters, states: updatedStates };
    });
  };

  const statusOptions = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 2 },
  ];

  const handleStatusChange = (statusOption) => {
    setFilters((prevFilters) => {
      const updatedStatus = prevFilters.status.some((s) => s.value === statusOption.value)
        ? prevFilters.status.filter((s) => s.value !== statusOption.value)
        : [...prevFilters.status, statusOption];
      return { ...prevFilters, status: updatedStatus };
    });
  };

  const applyFilters = () => {
    setSelectedState(filters.states);
    const statusValues = filters.status.map((s) => s.value);
    setStatus(statusValues);
    const selectedStateObjs = allStates.filter(stateObj => filters.states.includes(stateObj.name));
    setSelectedStateId(selectedStateObjs.map(obj => obj.id));
    handleCloseFilter();
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = tableData.filter((program) => {
    const isStateMatch = selectedState.length === 0 || selectedState.includes(program.referenceType);
    const isStatusMatch = status.length === 0 || status.includes(program.status === 'Active' ? 1 : 2);
    const isSearchMatch = 
      program.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      program.programId?.toString().includes(searchQuery) ||
      program.referenceName?.toLowerCase().includes(searchQuery?.toLowerCase());

  if (program?.referenceType !== null && program?.referenceType !== '') {
    uniqueReferenceTypes.add(program.referenceType);
  }
    return isStateMatch && isStatusMatch && isSearchMatch;
  });



  // const fetchStates = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await dashBoardService.getBreadCrumbApi(roleName);
  //     const response = res?.data?.data?.localeResponse?.states || [];
  //     if (res?.data.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
  //       setAllStates(response);
  //       const stateName = response.map(i => i?.name);
  //       setStateList(stateName);
  //     } else {
  //       dispatch(setApiError());
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     catchErrorHandler(error, dispatch(setApiError()));
  //     setIsLoading(false);
  //   }
  // };

  const handleReset = () => {
    setFilters({ states: [], status: [] });
    setSelectedState([]);
    setSelectedStateId([]);
    setStatus([]);
  };

  const handleDetailPage = (detail) => {
    navigate("/super-admin/program-details", { state: { detail } });
  };

  useEffect(() => {
    // fetchStates();
    fetchProgram({"filterEnable":false, "referenceIds":[], "status":[]})
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className='outer-programs'>
      <div className='programs-upper-section'>
        <SearchBar className="program-search-bar" placeholder='Search program name, program id, geographical name' value={searchQuery} onChange={handleSearch} />
        <div className='programs-upper-section-right'>
          {/* <button className='primary-btn programs-create-btn' onClick={handleCreateProgram}>
            + Create Program
          </button> */}
          <button className='programs-filter-btn' onClick={handleShowFilter}>
            Filter by
          </button>
        </div>
      </div>
      <div className='programs-lower-section'>
        {filteredData.length > 0 ? (
          <Table className='programs-table pending-table' columns={TableColumns({ handleDetailPage })} data={filteredData} checkbox={false} selectAll={false} />
        ) : (
          <div className='program-noData-sec'>
            <img src={FileIcon} />
            <span>No programs created</span>
          </div>
        )}
      </div>
      <CustomModal />
      {showFilter && (
        <div className='custom-offcanvas'>
          <div className='offcanvas-header'>
            <h5 className='offcanvas-title'>Filters</h5>
            <button type='button' className='close' onClick={handleCloseFilter}>
              &times;
            </button>
          </div>
          <div className='offcanvas-body'>
            <form>
              <div className='form-group'>
                <div className="program-filter-taugle-sec">
                  <label>Geographical Unit</label>
                  <img className={`dropdown-icon ${isCollapsedState ? 'icon-active' : ''}`} src={isCollapsedState ? arrowActive : arrow} onClick={() => setIsCollapsedState(!isCollapsedState)} alt='arrow' height={28} />
                </div>
                {isCollapsedState && [...uniqueReferenceTypes].map((state) => (
                  <div className='form-group-outer' key={state}>
                    <input
                      type='checkbox'
                      id={state}
                      checked={filters.states.includes(state)}
                      onChange={() => handleStateChange(state)}
                    />
                    <label htmlFor={state}>{state}</label>
                  </div>
                ))}
              </div>
              <div className='form-group'>
                <div className="program-filter-taugle-sec">
                  <label>Status</label>
                  <img className={`dropdown-icon ${isCollapsedStatus ? 'icon-active' : ''}`} src={isCollapsedStatus ? arrowActive : arrow} onClick={() => setIsCollapsedStatus(!isCollapsedStatus)} alt='arrow' height={28} />
                </div>
                {isCollapsedStatus && (
                  <div className='form-group-status'>
                    {statusOptions.map((statusOption) => (
                      <div className='form-group-outer' key={statusOption.value}>
                        <input
                          type='checkbox'
                          id={`status${statusOption.value}`}
                          value={statusOption.value}
                          checked={filters.status.some((s) => s.value === statusOption.value)}
                          onChange={() => handleStatusChange(statusOption)}
                        />
                        <label htmlFor={`status${statusOption.value}`}>{statusOption.label}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className='program-filter-sidebar-btn-sec'>
                <span className='program-filter-reset' onClick={handleReset}> Reset</span>
                <button type='button' className='primary-btn' onClick={applyFilters}>
                  Apply Filters
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showFilter && <div className='offcanvas-backdrop' onClick={handleCloseFilter}></div>}
    </div>
  );
};

export default Programs;
