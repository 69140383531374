export const userRoles = {
    userManager: "USER_MANAGER",
    BPC: "BPC",
    BPM: "BPM",
    SPM: "SPM",
    UPCM:'UPCM',
    DPC: 'DPC',
    DPM: 'DPM',
    SPC: 'SPC',
    NPM: "NPM",
    SuperAdmin: "SUPER_ADMIN",
    
}