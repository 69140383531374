
import './style.css';

const PreLoader = () => {
   

return(
    <div className='preloader'>
<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
)
}

export default PreLoader;