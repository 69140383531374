import React from 'react';
import approve from '../../../assets/images/green-dot.png'
import reject from '../../../assets/images/red-dot.png'
import rightArrow from '../../../assets/images/circle-arrow-blue.svg'


const TableColumns = ({ handleDetailPage }) => {
    const modifyYesNoSymbol = str => {
        switch (str) {
            case 'Active':
                return <div className='prog-status-wrap'><img src={approve} width={18} height={18} alt='icon' /> {str}</div>
            case 'Inactive':
                return <div className='prog-status-wrap'><img src={reject} width={18} height={18} alt='icon' /> {str}</div>
            // case 'NOT_MARKED':
            //         return '-'
            default:
                return '-'
        }
    }

    const handleDateformat = (_date) => {
        let _formattedDate = new Date(parseInt(_date))
        _formattedDate = _formattedDate.toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric"
        });
        return _formattedDate
    }

    return [
        {
            key: "programId",
            text: `Program ID`,
            isSort: true
        },{
            key: "name",
            text: `Program Name`,
            isSort: true
        },
        {
            key: "referenceType",
            text: `Mapped Geographical Unit`,
            isSort: true
        },
        {
            key: "referenceName",
            text: `Geographical Name`,
            isSort: true
        },
        {
            key: "startDate",
            text: `Start date`,
            formatter: (cell, row, rowIndex) => {
                return handleDateformat(cell)
            },
            isSort: true
        },
        {
            key: "endDate",
            text: `End date`,
            formatter: (cell, row, rowIndex) => {
                return handleDateformat(cell)
            },
            isSort: true
        },
        {
            key: "status",
            text: `Status`,
            formatter: (cell, row, rowIndex) => {
                return modifyYesNoSymbol(cell)
            },
            isSort: true
        },
        {
            key: "action",
            text: `Action`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return <img className='program-action' src={rightArrow} width={24} height={24} alt='icon' onClick={() => handleDetailPage({ programId: row?.programId, programName: row?.name, state: row?.referenceName })} />
            },
        },

    ]
}
export default TableColumns;