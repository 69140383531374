import React, { useEffect } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import SuccessIcon from '../../../../assets/images/successIcon.svg'

const SuccessModal = ({ modalParameters: { info, icon = SuccessIcon, showActionBtn }, closePopup }) => {

    useEffect(() => {

        setTimeout(() => {
            closePopup();
        }, 4000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <ModalBody>
                <img className='success-icon' src={icon} alt="icon" />
                {info}
            </ModalBody>
            <ModalFooter>
                {showActionBtn && <button className="secondary-btn ml" onClick={closePopup}>
                    Ok
                </button>}
            </ModalFooter>
        </div>
    )
}
export default SuccessModal