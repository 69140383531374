import { getResponse } from "../../pages/Dashboard/conifg";
import { ROLE_TYPE } from "./constant";

export const dropDownTransformer = (data, role) => {
  let arr = [];
  arr = data?.[getResponse[role]]?.map((item) => ({
    value: item.name,
    id: item.id,
    name: item.name
  }));

  return arr;
};

export const handleDropDownShow = (role) => {
  if (role === ROLE_TYPE.NATIONAL) {
    return {
      showState: true
    }
  } else if (role === ROLE_TYPE.STATE) {
    return {
      showState: true,
      showDistrict: true,
      showBlock: false,
      showVillage: false
    }
  } else if (role === ROLE_TYPE.DISTRICT) {
    return {
      showDistrict: true,
      showBlock: true,
      showVillage: false
    }

  } else if (role === ROLE_TYPE.BLOCK) {
    return {
      showBlock: true,
      showVillage: true,
    }
  }
}