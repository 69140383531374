import React,{useEffect} from 'react';
import circleIcon from '../../../assets/images/check-circle.svg';
import error from '../../../assets/images/red-cross.svg';
import './style.css';

const ToastMsg = ({ otpMsg, success, page }) => {
    useEffect( () => {
        const toastElement = document.querySelector('#toast');
        const baseClass = 'show';
        const pageClass = page === 'admin' ? 'admin-toast' : '';
        toastElement.className = `${baseClass} ${pageClass}`;

        let myTimeout = setTimeout(() => {
            toastElement.className = toastElement.className.replace(baseClass, '').trim();
        }, 5000);

        return () => {
            clearTimeout(myTimeout);
        };
      },[])

    

return(
    <div id="toast">
        <div id="img">
            <img src={success ? circleIcon : error} alt='icon' />
        </div>
        <div id="desc">{otpMsg}</div>
    </div>
)
}

export default ToastMsg;