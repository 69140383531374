import React, { useEffect, useState } from 'react'
import UploadFile from '../../UploadFile'
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { commonService } from "../../../../services/common";
import GlobalConfig from '../../../../config/globalconfig';
import PreLoader from '../../Preloader';
import { resetApiError, setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import errorIcon from '../../../../assets/images/red-info-icon.svg';

const LanguageUploadModal = ({ modalParameters: { userType, bodyMsg }, closePopup }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formInfo, setFormInfo] = useState({
        id: 'users',
        value: '',
        accept: '.xls, .xlsx',
        fileName: '',
        isError: false,
        errorMsg: ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetApiError())
    }, [])

    const handleChange = (e) => {
        const updatedForm = { ...formInfo };
        setApiErrorMsg('')
        updatedForm.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedForm.value = e.currentTarget.files[0]
        // if ((e.currentTarget.files[0].size / 1024).toFixed(4) > 500) {
        //     updatedForm.errorMsg = `File size exceeds the max allowed size`
        //     updatedForm.isError = true
        // } else 
        if (e.currentTarget.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            && e.currentTarget.files[0].type !== 'application/vnd.ms-excel') {
            updatedForm.errorMsg = `File Format do not support`
            updatedForm.isError = true
        } else {
            updatedForm.errorMsg = ``
            updatedForm.isError = false
        }
        setFormInfo(updatedForm);
    }

    const onSubmit = async () => {
        const { referenceId } =
            JSON.parse(localStorage.getItem("userAuth")) || "";
        const stateId = referenceId[0]?.id;
        setIsLoading(true)
        try {
            const formData = new FormData();
            formData.append('userFile', formInfo?.value);
            const response = await commonService.uploadLanguage(formData, userType, stateId);
            const res = response.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                if (res?.data?.length > 0) {
                    dispatch(openModal(MODALS.ERROR_LIST_MODAL,
                        {
                            parentClass: "center-modal error-list-modal-wrap",
                            modalTitle: `Error`,
                            errorList: res?.data,
                        }))
                }
                else
                    setIsSuccess(true);
            }
            else
                setApiErrorMsg(res.message)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            setApiErrorMsg(error?.message)
            catchErrorHandler(error, dispatch(setApiError()));
            console.error(error)
        }
    }
    const handleDelete = () => {
        const updatedForm = { ...formInfo };
        updatedForm.fileName = '';
        updatedForm.errorMsg = ``;
        updatedForm.isError = false;
        updatedForm.value = '';
        setApiErrorMsg('')
        setFormInfo(updatedForm);
    }

    if (isLoading) {
        return <PreLoader />
    }

    return (
        <div className='upload-modal'>
            {isSuccess ?
                <ModalBody>
                    <h3>File Uploaded Successfully</h3>
                </ModalBody>
                : <ModalBody>
                    <p>{bodyMsg}</p>
                    <br />
                    <UploadFile
                        id={formInfo.id}
                        value={formInfo.value}
                        accept={formInfo.accept}
                        handleChange={e => handleChange(e)}
                        fileName={formInfo.fileName}
                        isError={formInfo.isError}
                        errorMsg={formInfo.errorMsg}
                        key={formInfo.id}
                        handleDelete={handleDelete}
                    />
                    <ul className='upload-note'>
                        {/* <li>Max upload file size: 500 KB</li> */}
                        <li>Accepted format: .xls or .xlsx</li>
                    </ul>
                </ModalBody>}
            {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>}
            <ModalFooter>
                {isSuccess ? <>
                    <button className="primary-btn" onClick={closePopup}>
                        OK
                    </button>
                </>
                    :
                    <>
                        <button className="secondary-btn ml" onClick={closePopup}>
                            Cancel
                        </button>
                        <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                            Submit
                        </button>
                    </>
                }
            </ModalFooter>
        </div>
    )
}

export default LanguageUploadModal