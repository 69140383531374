import React, { useState, useEffect } from 'react';
import circleArrowImg from '../../../assets/images/circle-arrow.svg'
import './style.scss';

const TabHead = ({ isActive, count, title }) => {
    return (
        <div className={`tab-head-outer ${isActive ? 'active': ''}`}>
            <div className='tab-head-inner'>
                {count && <div className='count'>
                    {count}
                </div>}
                <div className='tab-title'>
                    {title}
                </div>
                <div className='img-arrow'>
                    <img src={circleArrowImg} alt='tab icon' />
                </div>
            </div>

        </div>
    )
}

export default TabHead;