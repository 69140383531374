import GlobalConfig from "../../../config/globalconfig";
// const userAuth = JSON.parse(localStorage.getItem("userAuth")) || "";
export const sectionToggleOptions = (type, role) => [
  {
    value: type === "map" ? ['District', 'Block'].includes(role) ? "Summary View" : "Map View" : "Chart View",
  },
  {
    value: "Source Data",
  },
];

export const stageDropDown = [
  {
    value: 'Wealth Ranking',
  },
  {
    value: 'PAT',
  },
  {
    value: 'VO Endorsement',
  },
  {
    value: 'BPM Approval'
  }
]

export const mapDataDropDown = [
  {
    value: "No. of Ultra-Poor",
    name: "Ultra-Poor identified in PAT"
  },
  // {
  //   value: "No. of CRPs",
  //   name: "No. of CRPs"
  // },
  // {
  //   value: "Sent For VO Endorsement",
  //   name: "Ultra-Poor endorsed by VO"
  // },
  {
    value: "BPM Approval Completed",
    name: "Ultra-Poor approved by BPM"
  },
  {
    value: "No. of PAT Mismatch",
    name: "Verification Error"
  },
];

export const getboxData = (boxValues, imgObj, role) => [
  {
    imgName: imgObj?.layer,
    name: "No. of Districts",
    values:
      role && ["National", "State"].includes(role)
        ? boxValues?.numberOfDistricts
        : "-",
  },
  {
    imgName: imgObj?.layer,
    name: "No. of Blocks",
    values:
      role && ["National", "State", "District"].includes(role)
        ? boxValues?.numberOfBlocks
        : "-",
  },
  {
    imgName: imgObj?.mapLayer,
    name: "No. of Villages",
    values:
      role && ["National", "State", "Block", "District"].includes(role)
        ? boxValues?.numberofVillages
        : "-",
  },
  {
    imgName: imgObj?.headphone,
    name: "CRP Groups",
    values: boxValues?.crpGroups,
  },
  {
    imgName: imgObj?.users,
    name: "No. of didis mobilised",
    values: boxValues?.peopleMobilized,
  },
  {
    imgName: imgObj?.usericon,
    name: "No. of didis endorsed by VO",
    values: boxValues?.voEndorsedCount,
  },
  {
    imgName: imgObj?.usericon,
    name: " No. of didis approved by BPM",
    values: boxValues?.ultraPoorApproved,
  },
  {
    imgName: imgObj?.layer,
    name: "Drop-Out",
    values: boxValues?.didiDropOutCount
  },
  {
    imgName: imgObj?.layer,
    name: "No. of Didis covered (BPM approved didis - dropped)",
    values: boxValues?.ultraPoorApproved - boxValues?.didiDropOutCount
  },
];

export const getRoles = {
  National: "",
  State: "stateId",
  District: "districtId",
  Block: "blockId",
  Village: "villageId",
};

export const getResponse = {
  National: "states",
  State: "districts",
  District: "blocks",
  Block: "villages",
};

export const getHeading = (type) => {
  let obj = {
    National: "States",
    State: "Districts",
    District: "Blocks",
    Block: "Villages",
  };

  return obj[type];
};

export const colorConfig = {
  POOR: "green",
  MEDIUM: "yellow",
  RICH: "red",
};



export const getQueryParams = (role, Id, stageType, typeofView) => {
  let mapType = null;
  if(typeofView === 'map'){
    switch(stageType){
      case "No. of PAT Mismatch": //"Verification Error"
        mapType = '&code=BPC_SURVEY';
      break;
      case "BPM Approval Completed": //"Ultra-Poor approved by BPM"
        mapType = '&code=BPM_SURVEY';
        break;
      case "Sent For VO Endorsement": //"Ultra-Poor endorsed by VO"
        mapType = '';
        break;
      case "No. of CRPs": //"No. of CRPs"
        mapType = '';
        break;
      case "No. of Ultra-Poor": //"Ultra-Poor identified in PAT"
        mapType = '&code=PAT_SURVEY';
        break;
      default:
        mapType = null;
    }
  }
  const queryParam = role === "National" ? `${mapType ? `?${mapType}` : ''}` : `?${getRoles[role]}=${Id}${mapType ? mapType : ''}`;
  return queryParam;
};

export const getMapDataTypeValue = (value, type) => {
  let typeOfData = {};
  if (type === "No. of Ultra-Poor") {
    typeOfData.numericalValue = value?.ultraPoorCount;
    typeOfData.percentValue = value?.ultraPoorPercentage;
  } else if (type === "No. of CRPs") {
    typeOfData.numericalValue = value?.crpCount;
    typeOfData.percentValue = value?.crpCountPercentage;
  } else if (type === "Sent For VO Endorsement") {
    typeOfData.numericalValue = value?.voEndrosmentCount;
    typeOfData.percentValue = value?.voEndrosmentPercentage;
  } else if (type === "BPM Approval Completed") {
    typeOfData.numericalValue = value?.bpmApprovalCount;
    typeOfData.percentValue = value?.bpmApprovalPercentage;
  } else if (type === "No. of PAT Mismatch") {
    typeOfData.numericalValue = value?.bpcPatCount;
    typeOfData.percentValue = value?.patMismatchPercentage;
  }

  return typeOfData;
};

export const mapTransFormer = (data, type) => {
  let mapData = [];
  if (data) {
    mapData = data.map((mapValue) => ({
      name: mapValue?.name || "",
      id: mapValue?.id || "",
      numericalValue: getMapDataTypeValue(mapValue, type)?.numericalValue,
      percentValue: getMapDataTypeValue(mapValue, type)?.percentValue,
    }));
  }

  return mapData;
};

function checkIfValueisZero(num) {
  return num <= 0;
}

export const graphTransformer = (data, type, showData, stageType, toggleType = true, question, summary) => {
  let graphData = [];
  let graphDropDown = [];
  // console.log("data",data)
  if (data) {
    if (type === "funnel") {
      graphData = [
        {
          name: "Didis Mobilised",
          uv: data.added, // uv is the part of the graph we want to show
          pv: 0, // pv is the floating part (transparent)
          pct: data.addedPercentage

        },

        {
          name: "Selected in PWR",
          uv: data.selectedInPWR,
          pv: 0, // to get this pv, we use 01/2020's uv + pv
          pct: data.selectedInPWRPercentage

        },
        {
          name: "Selected in PAT",
          uv: data.selectedInPAT,
          pv: 0, // use 02/2020's uv + pv, and so forth
          pct: data.selectedInPATPercentage,

        },
        {
          name: "VO Not Started",
          uv: - (data.selectedInPAT - (data.rejectedByVO + data.acceptedByVO) <= 0 ? 0 : data.selectedInPAT - (data.rejectedByVO + data.acceptedByVO)),
          pv: checkIfValueisZero(data.selectedInPAT - (data.rejectedByVO + data.acceptedByVO)) ? 0 : data.selectedInPAT, // use 02/2020's uv + pv, and so forth
          pct: data.selectedInPATPercentage,

        },
        {
          name: "Rejected By VO",
          uv: (data.rejectedByVO * -1),
          pv: checkIfValueisZero(data.rejectedByVO) ? 0 : data.selectedInPAT - (data.selectedInPAT - (data.rejectedByVO + data.acceptedByVO)),
          pct: data.rejectedByVOPercentage

        },
        {
          name: "Selected By VO",
          uv: data.acceptedByVO,
          pv: 0,
          pct: data.acceptedByVOPercentage

        },
        {
          name: "BPM Approval Pending",
          uv: -(data?.acceptedByVO - (data?.rejectedByBPM + data?.keptAsReserve + data?.acceptedByBPM) <= 0 ? 0 : data?.acceptedByVO - (data?.rejectedByBPM + data?.keptAsReserve + data?.acceptedByBPM)),
          pv: checkIfValueisZero(data?.acceptedByVO - (data?.rejectedByBPM + data?.keptAsReserve + data?.acceptedByBPM)) ? 0 : data?.acceptedByVO,
          pct: data.rejectedByBPMPercentage

        },
        {
          name: "Rejected By BPM",
          uv: (data.rejectedByBPM * -1),
          pv: checkIfValueisZero(data.rejectedByBPM) ? 0 : data.acceptedByVO,
          pct: data.rejectedByBPMPercentage

        },
        {
          name: "Kept As Waitlist",
          uv: (data.keptAsReserve * -1),
          pv: checkIfValueisZero(data.keptAsReserve) ? 0 : (data.acceptedByVO - data.rejectedByBPM),
          pct: data.keptAsReservePercentage

        },
        {
          name: "Approved By BPM",
          uv: data.acceptedByBPM,
          pv: 0,
          pct: data.acceptedByBPMPercentage,

        },
      ];
    } else if (type === "wealthRanking") {
      graphData = [
        {
          name: "Rich",
          uv: toggleType ? data.richDidi : data.richDidiPercentage, // uv is the part of the graph we want to show
          pv: 0, // pv is the floating part (transparent)
          pct: data.richDidiPercentage,
        },
        {
          name: "Medium",
          uv: toggleType ? data.mediumDidi : data.mediumDidiPercentage,
          pv: 0, // to get this pv, we use 01/2020's uv + pv
          pct: data.mediumDidiPercentage,
        },
        {
          name: "Poor",
          uv: toggleType ? data.poorDidi : data.poorDidiPercentage,
          pv: 0, // use 02/2020's uv + pv, and so forth
          pct: data.poorDidiPercentage
        },
        ...(showData
          ? [
            {
              name: "Total",
              uv: toggleType ? data.totalDidi : data.totalpercentage,
              pv: 0,
              pct: data.totalpercentage,
            },
          ]
          : []),
      ];
    } else if (type === "pat") {
      graphData = [
        {
          name: "Total Poor Per PWR",
          uv: toggleType ? data?.poorInPWR : data?.poorInPwrPercentage,
          pv: 0,
          pct: data?.poorInPwrPercentage,
        },
        {
          name: "PAT Not Started",
          uv: -(data?.poorInPWR - (data?.notAvailable + data?.patCompleted) <= 0 ? 0 : data?.poorInPWR - (data?.notAvailable + data?.patCompleted)),
          pv: checkIfValueisZero(data?.poorInPWR - (data?.notAvailable + data?.patCompleted)) ? 0 : data?.poorInPWR,
          pct: data?.poorInPwrPercentage,
        },
        {
          name: "Not Available",
          uv: toggleType ? (data?.notAvailable * -1) : (data?.notAvailablePercentage * -1),
          pv: checkIfValueisZero(data.notAvailable) ? 0 : toggleType ? data?.poorInPWR - (data?.poorInPWR - (data?.notAvailable + data?.patCompleted)) : data?.poorInPwrPercentage,
          pct: data?.notAvailablePercentage
        },
        {
          name: "PAT Complete",
          uv: toggleType ? data.patCompleted : data.patCompletedPercentage,
          pv: 0,
          pct: data?.patCompletedPercentage,
        },
        {
          name: "Rejected By PAT Exclusion",
          uv: toggleType ? (data?.rejectedOnExclusion * -1) : (data?.rejectedOnExclusionPercentage * -1),
          pv: checkIfValueisZero(data.rejectedOnExclusion) ? 0 : toggleType ? data.patCompleted : data.patCompletedPercentage,
          pct: data?.rejectedOnExclusionPercentage,
        },
        {
          name: "Rejected By PAT",
          uv: toggleType ? (data?.rejectedByPat * -1) : (data?.rejectedByPatPercentage * -1),
          pv: checkIfValueisZero(data.rejectedByPat) ? 0 : toggleType ? (data.patCompleted - data.rejectedOnExclusion) : (data.patCompletedPercentage - data.rejectedOnExclusionPercentage),
          pct: data?.rejectedByPatPercentage,
        },
        {
          name: "Selected As Ultra-Poor",
          uv: toggleType ? data.selectedAsUltraPoor : data.ultraPoorPercentage,
          pv: 0,
          pct: data?.ultraPoorPercentage
        },
      ];
    } else if (type === "vo") {
      graphData = [
        {
          name: "Ultra-Poor Sent To VO",
          uv: toggleType ? data?.poorSentToVo : data?.poorSentToVoPercentage,
          pv: 0,
          pct: data?.poorSentToVoPercentage,
        },
        {
          name: "VO Not Started",
          uv: - (data?.poorSentToVo - (data?.rejectedByVo + data?.endorsedByVo) <= 0 ? 0 : data?.poorSentToVo - (data?.rejectedByVo + data?.endorsedByVo)),
          pv: checkIfValueisZero(data?.poorSentToVo - (data?.rejectedByVo + data?.endorsedByVo)) ? 0 : data?.poorSentToVo,
          pct: data?.poorSentToVoPercentage,
        },
        {
          name: "Rejected By VO",
          uv: toggleType ? (data?.rejectedByVo * -1) : (data?.rejectedByVoPercentage * -1),
          pv: checkIfValueisZero(data.rejectedByVo) ? 0 : toggleType ? data?.poorSentToVo - (data?.poorSentToVo - (data?.rejectedByVo + data?.endorsedByVo)) : data?.poorSentToVoPercentage,
          pct: data?.rejectedByVoPercentage
        },
        {
          name: "Endorsed By VO",
          uv: toggleType ? data?.endorsedByVo : data?.endorsedByVoPercentage,
          pv: 0,
          pct: data.endorsedByVoPercentage,
        },
      ];
    } else if (type === "bpm") {
      graphData = [
        {
          name: "Endorsed By Vo",
          uv: toggleType ? data.voEndorsed : data.voEndorsedPercentage,
          pv: 0,
          pct: data.voEndorsedPercentage,
        },
        {
          name: "Rejected By BPM",
          uv: toggleType ? (data?.bpmRejected * -1) : (data?.bpmRejectedPercentage * -1),
          pv: checkIfValueisZero(data.bpmRejected) ? 0 : toggleType ? (data.voEndorsed) : (data.voEndorsedPercentage),
          pct: data?.bpmRejectedPercentage
        },
        {
          name: "Marked as Waitlisted By BPM",
          uv: toggleType ? (data.bpmReserve * -1) : (data?.bpmReservePercentage * -1),
          pv: checkIfValueisZero(data.bpmReserve) ? 0 : toggleType ? (data.voEndorsed - data.bpmRejected) : (data.voEndorsedPercentage - data.bpmRejectedPercentage),
          pct: data.bpmReservePercentage,
        },
        {
          name: "Approved By BPM",
          uv: toggleType ? data.bpmApproved : data.bpmApprovedPercentage,
          pv: 0,
          pct: data.bpmApprovedPercentage
        },
      ]
    } else if (type === 'overall') {
      graphData = [
        {
          name: "PAT Completed",
          uv: toggleType ? data?.patCompleted : data?.patCompletedPercentage,
          pv: 0,
          amt: data?.totalVillages,
          pct: data?.patCompletedPercentage,
        },
        {
          name: "VO Endorsement Completed",
          uv: toggleType ? data?.voEndorsementCompleted : data?.voEndorsementCompletedPercentage,
          pv: 0,
          amt: data?.totalVillages,
          pct: data?.voEndorsementCompletedPercentage
        },
        {
          name: "BPC Verification Completed",
          uv: toggleType ? data?.bpcVerificationAccepted : data?.bpcVerificationAcceptedPercentage,
          pv: 0,
          amt: data?.totalVillages,
          pct: data?.bpcVerificationAcceptedPercentage
        },
        {
          name: "BPM Approval Completed",
          uv: toggleType ? data?.bpmApprovalCompleted : data?.bpmApprovalCompletedPercentage,
          pv: 0,
          amt: data?.totalVillages,
          pct: data?.bpmApprovalCompletedPercentage,
        },
      ];
    } else if (type === 'castePwr') {
      const castePwrFilter = (type) => data?.casteWiseWealth?.find(item => item.pwrType === type);
      const stackFilter = (stackData, type) => stackData?.find(item => item.casteName === type);
      graphData = [
        {
          name: castePwrFilter('RICH')?.pwrType,
          casteName1: 'GEN- General',
          casteName2: 'OBC- Other Backward Class',
          casteName3: 'SC- Scheduled Caste',
          casteName4: 'ST- Scheduled Tribes',
          GEN: stackFilter(castePwrFilter('RICH')?.casteWealthList, 'GEN- General')?.[toggleType ? 'count' : 'percentage'],
          OBC: stackFilter(castePwrFilter('RICH')?.casteWealthList, 'OBC- Other Backward Class')?.[toggleType ? 'count' : 'percentage'],
          SC: stackFilter(castePwrFilter('RICH')?.casteWealthList, 'SC- Scheduled Caste')?.[toggleType ? 'count' : 'percentage'],
          ST: stackFilter(castePwrFilter('RICH')?.casteWealthList, 'ST- Scheduled Tribes')?.[toggleType ? 'count' : 'percentage'],
          total: toggleType ? data?.totalRich : data?.totalRichPercentage,

        },
        {
          name: castePwrFilter('MEDIUM')?.pwrType,
          casteName1: 'GEN- General',
          casteName2: 'OBC- Other Backward Class',
          casteName3: 'SC- Scheduled Caste',
          casteName4: 'ST- Scheduled Tribes',
          GEN: stackFilter(castePwrFilter('MEDIUM')?.casteWealthList, 'GEN- General')?.[toggleType ? 'count' : 'percentage'],
          OBC: stackFilter(castePwrFilter('MEDIUM')?.casteWealthList, 'OBC- Other Backward Class')?.[toggleType ? 'count' : 'percentage'],
          SC: stackFilter(castePwrFilter('MEDIUM')?.casteWealthList, 'SC- Scheduled Caste')?.[toggleType ? 'count' : 'percentage'],
          ST: stackFilter(castePwrFilter('MEDIUM')?.casteWealthList, 'ST- Scheduled Tribes')?.[toggleType ? 'count' : 'percentage'],
          total: toggleType ? data?.totalMedium : data?.totalMediumPercentage,

        },
        {
          name: castePwrFilter('POOR')?.pwrType,
          casteName1: 'GEN- General',
          casteName2: 'OBC- Other Backward Class',
          casteName3: 'SC- Scheduled Caste',
          casteName4: 'ST- Scheduled Tribes',
          GEN: stackFilter(castePwrFilter('POOR')?.casteWealthList, 'GEN- General')?.[toggleType ? 'count' : 'percentage'],
          OBC: stackFilter(castePwrFilter('POOR')?.casteWealthList, 'OBC- Other Backward Class')?.[toggleType ? 'count' : 'percentage'],
          SC: stackFilter(castePwrFilter('POOR')?.casteWealthList, 'SC- Scheduled Caste')?.[toggleType ? 'count' : 'percentage'],
          ST: stackFilter(castePwrFilter('POOR')?.casteWealthList, 'ST- Scheduled Tribes')?.[toggleType ? 'count' : 'percentage'],
          total: toggleType ? data?.totalPoor : data?.totalPoorPercentage,
        },
      ]
    } else if (type === 'casteStage') {
      // console.log(getStageByData('PAT'), "stageType");

      const getStageByData = (stype) => data?.casteDistributionDetails?.[stype]?.casteBeneficiaryMap;
      graphData = [
        {
          name: 'SC',
          uv: stageType && getStageByData(stageType)?.['SC- Scheduled Caste']?.[toggleType ? 'didiCount' : 'didiPercentage'],
          pct: stageType && getStageByData(stageType)?.['SC- Scheduled Caste']?.['didiPercentage'],
        },
        {
          name: 'OBC',
          uv: stageType && getStageByData(stageType)?.['OBC- Other Backward Class']?.[toggleType ? 'didiCount' : 'didiPercentage'],
          pct: stageType && getStageByData(stageType)?.['OBC- Other Backward Class']?.['didiPercentage']
        },
        {
          name: 'GEN',
          uv: stageType && getStageByData(stageType)?.['GEN- General']?.[toggleType ? 'didiCount' : 'didiPercentage'],
          pct: stageType && getStageByData(stageType)?.['GEN- General']?.['didiPercentage']
        },
        {
          name: 'ST',
          uv: stageType && getStageByData(stageType)?.['ST- Scheduled Tribes']?.[toggleType ? 'didiCount' : 'didiPercentage'],
          pct: stageType && getStageByData(stageType)?.['ST- Scheduled Tribes']?.['didiPercentage'],
        }
      ]


    } else if (type === 'patScore') {
      const totalData = {
        name: "Total",
        uv: data.totalDidi,
        pv: data.didiPercentage,
      }
      graphData = data?.patScoreDetails?.map(item => (
        {
          name: item?.score,
          uv: toggleType ? item?.count : item?.percentage,
          pv: item?.percentage,
        }
      )) || []
      showData && graphData.push(totalData)
    } else if (type === 'patReject') {
      const totalData = {
        name: "Total",
        uv: data.totalDidi,
        pv: data.didiPercentage,
      }
      graphData = data?.patScoreDetails?.map(item => (
        {
          name: item?.score,
          uv: toggleType ? item?.count : item?.percentage,
          pv: item?.percentage,
        }
      )) || []
      showData && graphData.push(totalData)
    } else if (type === 'casteVo') {
      graphData = [
        {
          name: "VO Endorsed",
          casteName1: 'GEN- General',
          casteName2: 'OBC- Other Backward Class',
          casteName3: 'SC- Scheduled Caste',
          casteName4: 'ST- Scheduled Tribes',
          GEN: data?.casteVoBeneficiaryMap?.VoEndorsed?.casteSummaryMap?.['GEN- General']?.[toggleType ? 'count' : 'percentage'],
          OBC: data?.casteVoBeneficiaryMap?.VoEndorsed?.casteSummaryMap?.['OBC- Other Backward Class']?.[toggleType ? 'count' : 'percentage'],
          SC: data?.casteVoBeneficiaryMap?.VoEndorsed?.casteSummaryMap?.['SC- Scheduled Caste']?.[toggleType ? 'count' : 'percentage'],
          ST: data?.casteVoBeneficiaryMap?.VoEndorsed?.casteSummaryMap?.['ST- Scheduled Tribes']?.[toggleType ? 'count' : 'percentage'],
          total: data?.casteVoBeneficiaryMap?.VoEndorsed?.[toggleType ? 'totalDidi' : 'totalDidiPercentage'],
        },
        {
          name: "VO Rejected",
          casteName1: 'GEN- General',
          casteName2: 'OBC- Other Backward Class',
          casteName3: 'SC- Scheduled Caste',
          casteName4: 'ST- Scheduled Tribes',
          GEN: data?.casteVoBeneficiaryMap?.VoRejected?.casteSummaryMap?.['GEN- General']?.[toggleType ? 'count' : 'percentage'],
          OBC: data?.casteVoBeneficiaryMap?.VoRejected?.casteSummaryMap?.['OBC- Other Backward Class']?.[toggleType ? 'count' : 'percentage'],
          SC: data?.casteVoBeneficiaryMap?.VoRejected?.casteSummaryMap?.['SC- Scheduled Caste']?.[toggleType ? 'count' : 'percentage'],
          ST: data?.casteVoBeneficiaryMap?.VoRejected?.casteSummaryMap?.['ST- Scheduled Tribes']?.[toggleType ? 'count' : 'percentage'],
          total: data?.casteVoBeneficiaryMap?.VoRejected?.[toggleType ? 'totalDidi' : 'totalDidiPercentage'],
        },
      ]
    } else if (type === 'patExclusion') {
      const totalData = {
        name: "Total",
        uv: data.didiCount,
        pct: data.percentageExclusion,
      }
      graphData = data?.patExclusionReasons?.map(item => (
        {
          name: item?.value,
          uv: toggleType ? item?.count : item?.percentage,
          pv: item?.percentage,
          pct: item?.percentage,
        }
      )) || []
      showData && graphData.push(totalData)
    } else if (['patReasonsSelect', 'patReasonsReject'].includes(type)) {
      // const myData = data.patResponseDTOList;


      graphDropDown = data?.patResponseDTOList?.map(i => ({
        value: i.name
      }));

      const filteredDropDown = question !== '' ? data?.patResponseDTOList?.find(i => i.name === question) : data?.patResponseDTOList?.[0];

      const checkOptionValue = filteredDropDown?.optionDetailsList?.every(i => i.count === 0);

      const totalData = {
        name: "Total",
        uv: data.didiCount,
        pct: data.percentagePatInclusion,
      }

      graphData = (data?.patResponseDTOList && !checkOptionValue) ? filteredDropDown?.optionDetailsList?.map(data => ({
        name: data?.optionName,
        uv: toggleType ? data.count : data.percentage,
        pv: 0,
        pct: data.percentage,
      })) : [];
      showData && graphData.push(totalData)
      //  graphData = showData ? data?.patResponseDTOList?.flatMap(i => i.optionDetailsList) : myData?.map(item => {
      //     const temp = {};
      //     temp.name = item.name;
      //     item.optionDetailsList.map((ob,index) =>
      //        {
      //         temp[`count${index + 1}`] = toggleType ? ob.count : ob.percentage;
      //         temp[`optionName${index + 1}`] = ob.optionName;
      //         temp[`percentage${index + 1}`] = ob.percentage;
      //        }
      //     )

      //    return temp
      // });
    }
  }

  return { graphData, graphDropDown };
};

export const getDiagramViewApiPath = (type) => {
  let apiPaths = {
    map: `${GlobalConfig.API_ROOT}/read-api/web/map/view`,
    funnel: `${GlobalConfig.API_ROOT}/read-api/web/funnelGraph/view`,
    wealthRanking: `${GlobalConfig.API_ROOT}/read-api/web/wealthGraph/view`,
    pat: `${GlobalConfig.API_ROOT}/read-api/web/patGraph/view`,
    vo: `${GlobalConfig.API_ROOT}/read-api/web/voSummary/view`,
    bpm: `${GlobalConfig.API_ROOT}/read-api/web/bpmSummary/view`,
    overall: `${GlobalConfig.API_ROOT}/read-api/web/overallProgress/view`,
    castePwr: `${GlobalConfig.API_ROOT}/read-api/web/casteWealthSummary/view`,
    casteStage: `${GlobalConfig.API_ROOT}/read-api/web/caste-Beneficiary-Summary/view`,
    patScore: `${GlobalConfig.API_ROOT}/read-api/web/pat-score-above-six-summary/view`,
    patReject: `${GlobalConfig.API_ROOT}/read-api/web/pat-score-below-six-summary/view`,
    casteVo: `${GlobalConfig.API_ROOT}/read-api/web/caste-vo-summary/view`,
    patExclusion: `${GlobalConfig.API_ROOT}/read-api/web/pat-exclusion-reason/view`,
    patReasonsSelect: `${GlobalConfig.API_ROOT}/read-api/web/pat-selected-reason/view`,
    patReasonsReject: `${GlobalConfig.API_ROOT}/read-api/web/pat-rejected-reason/view`,
  };

  return apiPaths[type];
};

export const getSourceDataApiPath = (type) => {
  let apiPaths = {
    map: `${GlobalConfig.API_ROOT}/read-api/web/source/view`,
    funnel: `${GlobalConfig.API_ROOT}/read-api/web/funnelConsole/view`,
    wealthRanking: `${GlobalConfig.API_ROOT}/read-api/web/wealthConsole/view`,
    pat: `${GlobalConfig.API_ROOT}/read-api/web/patConsole/view`,
    vo: `${GlobalConfig.API_ROOT}/read-api/web/VoSummaryConsole/view`,
    bpm: `${GlobalConfig.API_ROOT}/read-api/web/BpmSummaryConsole/view`,
    overall: `${GlobalConfig.API_ROOT}/read-api/web/overallSourceProgress/view`,
    castePwr: `${GlobalConfig.API_ROOT}/read-api/web/casteSourceSummary/view`,
    casteStage: `${GlobalConfig.API_ROOT}/read-api/web/beneficiary-Caste-summary/view`,
    patScore: `${GlobalConfig.API_ROOT}/read-api/web/pat-high-Score-summary/view`,
    patReject: `${GlobalConfig.API_ROOT}/read-api/web/pat-low-Score-summary/view`,
    casteVo: `${GlobalConfig.API_ROOT}/read-api/web/vo-caste-summary/view`,
    patExclusion: `${GlobalConfig.API_ROOT}/read-api/web/pat-exclusion-source-reason/view`,
    patReasonsSelect: `${GlobalConfig.API_ROOT}/read-api/web/pat-selected-source`,
    patReasonsReject: `${GlobalConfig.API_ROOT}/read-api/web/pat-rejected-source`,
  };

  return apiPaths[type];
};

export const getTopoJson = (type) => {
  let jsonName = {
    ASSAM: "assam",
    RAJASTHAN: "rajasthan",
    TRIPURA: "tripura",
    "ANDHRA PRADESH": "andhrapradesh",
    "ARUNACHAL PRADESH": "arunachalpradesh",
    BIHAR: "bihar",
    CHHATTISGARH: "chhattisgarh",
    DELHI: "delhi",
    GOA: "goa",
    GUJARAT: "gujarat",
    HARYANA: "haryana",
    "HIMACHAL PRADESH": "himachalpradesh",
    "JAMMU AND KASHMIR": "jammukashmir",
    JHARKHAND: "jharkhand",
    KARNATAKA: "karnataka",
    KERALA: "kerala",
    LAKSHADWEEP: "lakshadweep",
    "MADHYA PRADESH": "madhyapradesh",
    MAHARASHTRA: "maharashtra",
    MANIPUR: "manipur",
    MEGHALAYA: "meghalaya",
    MIZORAM: "mizoram",
    NAGALAND: "nagaland",
    ODISHA: "odisha",
    PUNJAB: "punjab",
    SIKKIM: "sikkim",
    "TAMIL NADU": "tamilnadu",
    "UTTAR PRADESH": "uttarpradesh",
    TELANGANA: "telangana",
    UTTARAKHAND: "uttarakhand",
    "WEST BENGAL": "westbengal",
  };

  return jsonName[type] || "india";
};

export const getPercentageHeading = (type) => {
  let obj = {
    "No. of Ultra-Poor": "% of Ultra-Poor",
    "No. of CRPs": "% of CRPs",
    "Sent For VO Endorsement": "% of VO Endorsement",
    "BPM Approval Completed": "% of BPM Approval",
    "No. of PAT Mismatch": "Verification Error",
  };

  return obj[type];
};


export const summaryTransformer = (type, data, mapType, role, stageType, toggleType, question, totalSummaryData, summaryMapValue, setSummary, setSummaryMapValue, setSummaryRole, setBlockName, setIsError, setLoaderAction, handleBlockClick) => {
  let heading = [];
  let summaryValues = [];
  const checkEmptyValues = graphTransformer(data, type)?.graphData?.every(item => item.uv === 0);
  if (type === "map") {
    if (mapType === "No. of PAT Mismatch")
      heading = [getHeading(role), getPercentageHeading(mapType)];
    else 
      heading = [getHeading(role), mapType === 'PAT Mismatch %' ? 'BPC PAT Verified Didis' : mapType, getPercentageHeading(mapType)];
      summaryValues =
      Array.isArray(data) &&
      data?.map((summaryvalue, ind) => (
        <tr key={`${summaryvalue?.name}${ind}`}>
          <td style={{ "cursor": role !== 'Block' ? "pointer": "default"}} onClick={() => role !== 'Block' && handleBlockClick(summaryvalue?.name, summaryvalue?.id, setSummary, setSummaryMapValue, setSummaryRole, setBlockName, setIsError, setLoaderAction)}>{summaryvalue?.name}</td>
          {mapType !== "No. of PAT Mismatch" ? <td>{getMapDataTypeValue(summaryvalue, mapType)?.numericalValue}</td> : null}
          <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue === null ? '-' : `${getMapDataTypeValue(summaryvalue, mapType)?.percentValue}%` }</td>
        </tr>
      ));

    if (mapType === "No. of Ultra-Poor") {
      summaryValues.push(<tr>
        <td>Total</td>
        <td>{summaryMapValue?.ultraPoorCount}</td>
        <td>{summaryMapValue?.totalUltraPoorPercentage}</td>
      </tr>)

    } else if (mapType === "No. of CRPs") {
      summaryValues.push(<tr>
        <td>Total</td>
        <td>{summaryMapValue?.crpCount}</td>
        <td>{summaryMapValue?.totalCrpPercentage}</td>
      </tr>)
    } else if (mapType === "Sent For VO Endorsement") {
      summaryValues.push(<tr>
        <td>Total</td>
        <td>{summaryMapValue?.voEndrosmentCount}</td>
        <td>{summaryMapValue?.totalVoPercentage}</td>
      </tr>)
    } else if (mapType === "BPM Approval Completed") {
      summaryValues.push(<tr>
        <td>Total</td>
        <td>{summaryMapValue?.bpmApprovalCount}</td>
        <td>{summaryMapValue?.totalBpmApprovedPercentage}</td>
      </tr>)
    }

  } else if (type === "funnel") {
    heading = ["Level", "Number of Didis"];
    // console.log(checkEmptyValues, "valuesss");
    // console.log(graphTransformer(data, "funnel", false, '', true)?.graphData, "sumamry");
    summaryValues = Object.keys(data).length > 0 && !checkEmptyValues ? graphTransformer(data, "funnel", false, '', true)?.graphData?.map((summaryvalue) => (
      <tr>
        <td>{summaryvalue.name}</td>
        <td>{summaryvalue.uv}</td>
        {/* <td>{summaryvalue.pct}</td> */}

        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    ))
      : [];
  } else if (type === "wealthRanking") {
    heading = ["PWR", "Number of Didis", "% of Didis"];
    summaryValues =
      Object.keys(data).length > 0 && !checkEmptyValues
        ? graphTransformer(data, "wealthRanking", true, '', true)?.graphData?.map((summaryvalue) => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.uv}</td>
            <td>{summaryvalue.pct}</td>

            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        ))
        : [];
  } else if (type === 'pat') {
    heading = ['Level', 'Number of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'pat', false, '', true)?.graphData?.map((summaryvalue) => (
      <tr>
        <td>{summaryvalue.name}</td>
        <td>{summaryvalue.uv}</td>
        {/* <td>{summaryvalue.pct}</td> */}

        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    )) : []
  } else if (type === 'vo') {
    heading = ['Stage', 'Number of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'vo', false, '', true)?.graphData?.map((summaryvalue) => (
      <tr>
        <td>{summaryvalue.name}</td>
        <td>{summaryvalue.uv}</td>
        {/* <td>{summaryvalue.pct}</td> */}

        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    )) : []
  } else if (type === 'bpm') {
    heading = ['Stage', 'Number of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'bpm', false, '', true)?.graphData?.map(summaryvalue => (
      <tr>
        <td>{summaryvalue.name}</td>
        <td>{summaryvalue.uv}</td>
        {/* <td>{summaryvalue.pct}</td> */}

        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    )) : []
  } else if (type === 'overall') {
    heading = ['Progress', 'Number of Villages', '% of Villages', 'Total Villages'];
    summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'overall', false, '', true)?.graphData?.map(summaryvalue => (
      <tr>
        <td>{summaryvalue.name}</td>
        <td>{summaryvalue.uv}</td>
        <td>{summaryvalue.pct}</td>

        <td>{summaryvalue.amt}</td>

        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    )) : []
  } else if (type === 'castePwr') {
    heading = ['Caste/PWR', 'GEN', 'OBC', 'SC', 'ST', 'TOTAL'];
    summaryValues = Object.keys(data)?.length > 0 && data?.casteWiseWealth && !checkEmptyValues ? graphTransformer(data, 'castePwr', false, '', toggleType)?.graphData?.map(summaryvalue => (
      <tr>
        <td>{summaryvalue?.name}</td>
        <td>{summaryvalue?.GEN}</td>
        <td>{summaryvalue?.OBC}</td>
        <td>{summaryvalue?.SC}</td>
        <td>{summaryvalue?.ST}</td>
        <td>{summaryvalue?.total}</td>


        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    )) : []
  } else if (type === 'casteStage') {
    heading = ['Caste/PWR', 'Number of Didis', '% of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && data?.casteDistributionDetails && !checkEmptyValues ? graphTransformer(data, 'casteStage', false, stageType, true)?.graphData?.map(summaryvalue => {

      return <tr>
        <td>{summaryvalue?.name}</td>
        <td>{summaryvalue?.uv}</td>
        <td>{summaryvalue?.pct}</td>




        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    }) : []
  } else if (['patScore', 'patReject'].includes(type)) {
    heading = ['Score', 'Number of Didis', '% of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && data?.patScoreDetails && !checkEmptyValues ? graphTransformer(data, type, true, '', true)?.graphData?.map(summaryvalue => {

      return <tr>
        <td>{summaryvalue?.name}</td>
        <td>{summaryvalue?.uv}</td>
        <td>{summaryvalue?.pv}</td>




        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    }) : []
  } else if (type === 'casteVo') {
    heading = ['Caste/VO', 'GEN', 'OBC', 'SC', 'ST', 'TOTAL'];
    summaryValues = Object.keys(data)?.length > 0 && data?.casteVoBeneficiaryMap && !checkEmptyValues ? graphTransformer(data, 'casteVo', false, '', toggleType)?.graphData?.map(summaryvalue => (
      <tr>
        <td>{summaryvalue?.name}</td>
        <td>{summaryvalue?.GEN}</td>
        <td>{summaryvalue?.OBC}</td>
        <td>{summaryvalue?.SC}</td>
        <td>{summaryvalue?.ST}</td>
        <td>{summaryvalue?.total}</td>


        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    )) : []
  } else if (type === 'patExclusion') {
    heading = ['Reason', 'Number of Didis', '% of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && data?.patExclusionReasons && !checkEmptyValues ? graphTransformer(data, 'patExclusion', true, '', true)?.graphData?.map(summaryvalue => {

      return <tr>
        <td>{summaryvalue?.name}</td>
        <td>{summaryvalue?.uv}</td>
        <td>{summaryvalue?.pct}</td>



        {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
      </tr>
    }) : []
  } else if (['patReasonsSelect', 'patReasonsReject'].includes(type)) {
    heading = ['Reason', 'Number of Didis', '% of Didis'];
    summaryValues = Object.keys(data)?.length > 0 && data?.patResponseDTOList && !checkEmptyValues ? graphTransformer(data, type, true, '', true, question)?.graphData?.map((summaryvalue) => {
      return <tr>
        <td>{summaryvalue?.name?.toString()?.replace('<b>', '').replace('</b>', '')}</td>
        <td>{summaryvalue?.uv}</td>
        <td>{summaryvalue?.pct}</td>

      </tr>
    }) : []
  }

  return {
    heading,
    summaryValues,
  };
};

export const getSourceDataMapping = (type, data, mapType = '') => {
  let heading = [];
  let sourceValues = [];

  if (type === "map") {
    let newData = data || [];
    // switch (mapType) {
    //   case 'No. of Ultra-Poor':
    //     newData = data?.ultraPoorPATList || []
    //     break;
    //   case 'No. of CRPs':
    //     newData = data?.CRPNoList || []
    //     break;
    //   case 'Sent For VO Endorsement':
    //     newData = data?.voEndrosmentList || []
    //     break;
    //   case 'BPM Approval Completed':
    //     newData = data?.bpmApprovalList || []
    //     break;
    //   case 'No. of PAT Mismatch':
    //     newData = data?.patMismatchList || []
    //     break;
    //   default:
    //     newData = []
    //     break;
    // }

    sourceValues = Array.isArray(newData) && (newData?.length > 10 ? newData?.slice(0, 10) : newData)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
        <td>
          {sources?.status && <span className={`dot ${colorConfig[sources.status]}`}></span>}
          {sources?.status?.replace('_', ' ') || '-'}
        </td>
      </tr>
    )) || [];
  } else if (type === "funnel") {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.didiName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.pwrStatus?.replace('_', ' ') || "-"}</td>
        <td>{sources?.pwrRejectionReason?.replace('_', ' ') || "-"}</td>
        <td>{sources?.patStatus?.replace('_', ' ') || "-"}</td>
        <td>{sources?.patRejectionReason?.replace('_', ' ') || "-"}</td>
        <td>{sources?.voStatus?.replace('_', ' ') || "-"}</td>
        <td>{sources?.bpmStatus?.replace('_', ' ') || "-"}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || [];
  } else if (type === "wealthRanking") {

    sourceValues = Array.isArray(data) &&(data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>
          {sources?.status && <span className={`dot ${colorConfig[sources.status]}`}></span>}
          {sources?.status?.replace('_', ' ') || '-'}
        </td>

        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || [];
  } else if (type === 'pat') {

    sourceValues = Array.isArray(data) &&(data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.totalScore || '-'}</td>
        <td>
          {/* <span className={`dot ${colorConfig[sources.status]}`}></span> */}
          {sources?.status?.replace('_', ' ') || '-'}
        </td>
        <td>{sources?.rejectionReason?.replace('_', ' ') || '-'}</td>

        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'vo') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        {/* <td> */}
        {/* <span className={`dot ${colorConfig[sources.status]}`}></span> */}
        {/* {sources.addedBy || '-'} */}
        {/* </td> */}
        <td>{sources?.status?.replace('_', ' ') || '-'}</td>

        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'bpm') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>
          {/* <span className={`dot ${colorConfig[sources.status]}`}></span> */}
          {sources.status?.replace('_', ' ') || '-'}
        </td>

        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'overall') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
        {/* <td>{sources?.progress?.replace('_', ' ') || '-'}</td> */}
        <td>{sources?.patStatus?.replace('_', ' ') || '-'}</td>
        <td>{sources?.voStatus?.replace('_', ' ') || '-'}</td>
        <td>{sources?.bpcStatus?.replace('_', ' ') || '-'}</td>
        <td>{sources?.bpmStatus?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'castePwr') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.status?.replace('_', ' ') || '-'}</td>
        <td>{sources?.caste?.replace('_', ' ') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'casteStage') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.beneficiaryName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.caste?.replace('_', ' ') || '-'}</td>
        <td>{sources?.status?.replace('_', ' ') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (['patScore', 'patReject'].includes(type)) {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.didiName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.score?.toString().replace('_', ' ') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'casteVo') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.didiName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.status?.replace('_', ' ') || '-'}</td>
        <td>{sources?.caste?.replace('_', ' ') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'patExclusion') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.didiName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.reason?.replace('_', ' ') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'patReasonsSelect') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.didiName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.reason?.replace('_', ' ').replaceAll('<b>', '').replaceAll('</b>', '') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  } else if (type === 'patReasonsReject') {

    sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
      <tr>
        <td>{ind + 1}</td>
        <td>{sources?.didiName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.dadaName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.houseNo?.replace('_', ' ') || '-'}</td>
        <td>{sources?.uid || '-'}</td>
        <td>{sources?.reason?.replace('_', ' ').replaceAll('<b>', '').replaceAll('</b>', '') || '-'}</td>
        <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
        <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
      </tr>
    )) || []
  }

  heading = getHeaders(type);

  return {
    heading,
    sourceValues,
  };
};

export const getHeaders = (type) => {

  let heading = [];

  if (type === 'map') {
    heading = [
      "S.No.",
      "Name of Didi",
      "UID",
      "VO & Village",
      "District",
      "Block",
      "State",
      "Status",
    ];
  } else if (type === 'funnel') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Selected / Rejected in PWR",
      " Reason For Rejection",
      "Selected / Rejected in PAT",
      "Reason For Rejection",
      "Selected / Rejected by VO",
      " Selected / Rejected by BPM",
      "VO & Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'wealthRanking') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Status",
      "VO & Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'pat') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Total PAT Score",
      "Selected/ Rejected",
      "Reason for rejection",
      "VO & Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'vo') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      // "Original/ added later",
      "Accepted/ Rejected",
      "VO & Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'bpm') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Approved/ Rejected",
      "VO & Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'overall') {
    heading = [
      "S.No.",
      "Name of village & VO",
      "District",
      "Block",
      "State",
      // "Progress"
      "PAT Status",
      "VO Status",
      "BPC Status",
      "BPM Status",
    ];
  } else if (type === 'castePwr') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Status",
      "Caste",
      "VO/Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'casteStage') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Caste",
      "Current Status",
      "VO/Village",
      "District",
      "Block",
      "State",
    ];
  } else if (['patScore', 'patReject'].includes(type)) {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "PAT Score",
      "VO/Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'casteVo') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Selected/ Rejected",
      "Caste",
      "VO/Village",
      "District",
      "Block",
      "State",
    ];
  } else if (type === 'patExclusion') {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House No.",
      "UID",
      "Reason for rejection",
      "VO/Village",
      "District",
      "Block",
      "State",
    ];
  } else if (['patReasonsSelect', 'patReasonsReject'].includes(type)) {
    heading = [
      "S.No.",
      "Name of Didi",
      "Husband Name",
      "House Name",
      "UID",
      "Category",
      "VO/Village",
      "District",
      "Block",
      "State",
    ];
  }

  return heading;

}


export const getCsvDetails = (type, sourceData, mapType = '') => {
  // Headers for each column
  let headers = [getHeaders(type).toString()];

  // // Convert users data to a csv
  let newData = sourceData || [];
  // if (type === 'map') {

  //   switch (mapType) {
  //     case 'No. of Ultra-Poor':
  //       newData = sourceData?.ultraPoorPATList || []
  //       break;
  //     case 'No. of CRPs':
  //       newData = sourceData?.CRPNoList || []
  //       break;
  //     case 'Sent For VO Endorsement':
  //       newData = sourceData?.voEndrosmentList || []
  //       break;
  //     case 'BPM Approval Completed':
  //       newData = sourceData?.bpmApprovalList || []
  //       break;
  //     case 'No. of PAT Mismatch':
  //       newData = sourceData?.patMismatchList || []
  //       break;
  //     default:
  //       newData = []
  //       break;
  //   }
  // }else{
  //   newData = sourceData
  // }

  let usersCsv = newData?.reduce((acc, user) => {

    let userArr = []

    if (type === 'map') {
      // const { beneficiaryName, uid, status, villageName, districtName, blockName, stateName } = user
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

        status: user?.status?.replace('_', ' ') || '-',

      }

      userArr = [userObj?.didiName, userObj?.uid, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName, userObj?.status];

    } else if (type === 'funnel') {
      const userObj = {
        didiName: user?.didiName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        pwrStatus: user?.pwrStatus?.replace('_', ' ') || "-",
        pwrRejectionReason: user?.pwrRejectionReason?.replace('_', ' ') || "-",
        patStatus: user?.patStatus?.replace('_', ' ') || "-",
        patRejectionReason: user?.patRejectionReason?.replace('_', ' ') || "-",
        voStatus: user?.voStatus?.replace('_', ' ') || "-",
        bpmStatus: user?.bpmStatus?.replace('_', ' ') || "-",
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

      }

      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.pwrStatus, userObj?.pwrRejectionReason, userObj?.patStatus, userObj?.patRejectionReason, userObj?.voStatus, userObj?.bpmStatus, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];

    } else if (type === 'wealthRanking') {
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        status: user?.status?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',
      }

      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.status, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];

    } else if (type === 'pat') {
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        totalScore: user?.totalScore || '-',
        status: user?.status?.replace('_', ' ') || '-',
        rejectionReason: user?.rejectionReason?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',
      }

      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj.totalScore, userObj?.status, userObj?.rejectionReason, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];

    } else if (type === 'vo') {
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        // addedBy: user?.addedBy || '-',
        status: user?.status?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',
      }
      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.addedBy, userObj?.status, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];

    } else if (type === 'bpm') {
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        status: user?.status?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',
      }

      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.status, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];

    } else if (type === 'overall') {
      const userObj = {
        villageName: user?.villageName?.replace('_', ' ') || '-',
        // uid: user?.uid || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',
        // progress: user?.progress?.replace('_', ' ') || '-',
        patStatus: user?.patStatus?.replace('_', ' ') || '-',
        voStatus: user?.voStatus?.replace('_', ' ') || '-',
        bpcStatus: user?.bpcStatus?.replace('_', ' ') || '-',
        bpmStatus: user?.bpmStatus?.replace('_', ' ') || '-',

      }

      userArr = [userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName, /*userObj?.progress*/userObj?.patStatus, userObj?.voStatus, userObj?.bpcStatus, userObj?.bpmStatus];

    } else if (type === 'castePwr') {
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        status: user?.status?.replace('_', ' ') || '-',
        caste: user?.caste?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

      }

      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.status, userObj?.caste, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName, userObj?.progress];

    } else if (type === 'casteStage') {
      const userObj = {
        didiName: user?.beneficiaryName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        caste: user?.caste?.replace('_', ' ') || '-',
        CurrentStatus: user?.status?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

      }

      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.caste, userObj?.CurrentStatus, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName, userObj?.progress];

    } else if (['patScore', 'patReject'].includes(type)) {
      const userObj = {
        didiName: user?.didiName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        score: user?.score?.toString().replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

      }
      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.score, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName, userObj?.progress];

    } else if (type === 'casteVo') {
      const userObj = {
        didiName: user?.didiName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        status: user?.status?.replace('_', ' ') || '-',
        caste: user?.caste || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

      }
      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.status, userObj?.caste, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName, userObj?.progress];

    } else if (type === 'patExclusion') {
      const userObj = {
        didiName: user?.didiName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        reason: user?.reason?.replace('_', ' ') || '-',
        villageName: user?.villageName?.replace('_', ' ') || '-',
        districtName: user?.districtName?.replace('_', ' ') || '-',
        blockName: user?.blockName?.replace('_', ' ') || '-',
        stateName: user?.stateName?.replace('_', ' ') || '-',

      }
      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.reason, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];

    } else if (['patReasonsSelect', 'patReasonsReject'].includes(type)) {
      const userObj = {
        didiName: user.didiName?.replace('_', ' ') || '-',
        dadaName: user?.dadaName?.replace('_', ' ') || '-',
        houseNo: user?.houseNo?.replace('_', ' ') || '-',
        uid: user?.uid || '-',
        Category: user.reason?.replace('_', ' ') || '-',
        villageName: user.villageName?.replace('_', ' ') || '-',
        districtName: user.districtName?.replace('_', ' ') || '-',
        blockName: user.blockName?.replace('_', ' ') || '-',
        stateName: user.stateName?.replace('_', ' ') || '-',

      }
      userArr = [userObj?.didiName, userObj.dadaName, userObj.houseNo, userObj?.uid, userObj?.Category, userObj?.villageName, userObj?.districtName, userObj?.blockName, userObj?.stateName];
    }

    acc.push(userArr.join(','))

    return acc
  }, [])

  return { headers, usersCsv };



  // console.log(headers, "headerssss");

  //keep headers array



}


export const myArr = () => {
  var my_array = [];

  for (var i = 10; i <= 500; i++) {
    my_array.push(i);
  }

  return my_array;
}

