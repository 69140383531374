import React from 'react';
import approve from '../../../../assets/images/green-tic.svg'
import reject from '../../../../assets/images/red-cross.svg'


const TableColumns = (props) => {
    const modifyYesNoSymbol = str =>{
        switch (str) {
            case 'SUCCESS':
                return <img src={approve} width={14} alt='icon' />
            case 'FAIL':
                return <img src={reject} width={12} alt='icon' />
            // case 'NOT_MARKED':
            //         return '-'
            default:
                return '-'
        }
    }
    
    return [
        {
            key: "fileName",
            text: `File name`,
            isSort: false
        },
        {
            key: "eventCount",
            text: `Event count`,
            isSort: false
        },
        {
            key: "message",
            text: `Message`,
            isSort: false
        },
        {
            key: "status",
            text: `Status`,
            formatter: (cell, row, rowIndex) => {
                return modifyYesNoSymbol(cell)
            },
            isSort: false
        },

    ]
}
export default TableColumns;
