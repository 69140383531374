import React, { useContext, useState } from 'react'
import cross from '../../../../assets/images/cross.svg';
import { ModalFooter } from 'reactstrap';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import './style.scss'
import { setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { useDispatch } from 'react-redux';
import { ProgramContext } from '..';

const AssetIncomeModal = ({onClose , row, programId ,setIsLoading ,handleAssetChange}) => {

    const { handleSetToastMessage } = useContext(ProgramContext);

  const [assetValues, setAssetValues] = useState(
    row.assets.reduce((acc, asset) => {
        acc[asset.id] = asset.value;
        return acc;
    }, {})
);
const dispatch = useDispatch();

const handleAddAssetIncome = async(e) =>{
    const transformedData = Object.entries(assetValues).map(([key, value]) => {
        return {
            id: key,
            value: parseInt(value, 10)
        };
    });
    const payload = {
        programId : programId,
        livelihoodId: row?.id,
        assetRequestDTOList: transformedData,
    };

    setIsLoading(true)
    try {
        const response = await commonService.programLivelihoodAssetInfo(payload);
        const res = response?.data;
        if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
            handleSetToastMessage(res?.data);
            handleAssetChange();
        }
        else {
            dispatch(setApiError())
            handleSetToastMessage('')
        }
        setIsLoading(false)
    } catch (error) {
        handleSetToastMessage('')
        console.error(error)
        catchErrorHandler(error, dispatch(setApiError()));
        setIsLoading(false)
    }finally{
        onClose()
    }
}

const handleChange = (id, newValue) => {
    let sanitizedValue = newValue.replace(/[^0-9]/g, '');
    if (sanitizedValue.length > 8) {
        sanitizedValue = sanitizedValue.slice(0, 8);
    }
    const numericValue = parseInt(sanitizedValue, 10);
    if (isNaN(numericValue) || numericValue < 0) {
        sanitizedValue = '';
    }

    setAssetValues(prevValues => ({
        ...prevValues,
        [id]: sanitizedValue
    }));
};

const preventInvalidInput = (e) => {
    const invalidChars = ['-', '+', 'e', 'E', '.'];
    if (invalidChars.includes(e.key)) {
        e.preventDefault();
    }
};

const assetRows = row.assets.map(asset => (
    <tr key={asset.id}>
        <td>{asset.name}</td>
        <td>
            <input
                type="number"
                value={assetValues[asset.id]}
                onChange={e => handleChange(asset.id, e.target.value)}
                onKeyDown={preventInvalidInput}
                className="input-field"
                min='0'
            />
        </td>
    </tr>
));
  return (
    <div className='asset-info'>
    <div className='modal-content'>
        <div className="asset-popup">
            <div className='asset-header'>
                <h5 class="asset-title">Add Asset Value</h5>
                <div>Livelihood: <span>{`${row?.name}`}</span></div>
            </div>
            <span class="close-modal-cross">
                <img src={cross} alt="close modal icon" onClick={onClose}/>
            </span>
        </div>
        <div >
            <table className='asset-table'>
                <thead>
                    <tr>
                        <th>Asset Type</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {assetRows}
                </tbody>
            </table>
        </div>
        <ModalFooter>
            <button className="secondary-btn ml asset-footer" onClick={onClose}>
                Cancel
            </button>
            <button className="primary-btn asset-footer" onClick={handleAddAssetIncome}>
                Add
            </button>
        </ModalFooter>
    </div>
</div>


  )
}

export default AssetIncomeModal