import React from 'react';

const TableColumns = () => {
    
    return [
        {
            key: "didiName",
            text: `Didi name`,
            isSort: true
        },
        {
            key: "husbandName",
            text: `Dada / Husband name`,
            isSort: true
        },
        {
            key: "villageName",
            text: `Village name`,
            isSort: true
        },{
            key: "villageId",
            text: `Village ID`,
            isSort: true
        },
        {
            key: "blockName",
            text: `Block name`,
            isSort: true
        },
        {
            key: "blockId",
            text: `Block ID`,
            isSort: true
        },
        {
            key: "districtName",
            text: `District name`,
            isSort: true
        },
        {
            key: "districtId",
            text: `District ID`,
            isSort: true
        },
        {
            key: "stateName",
            text: `State name`,
            isSort: true
        },
        {
            key: "stateId",
            text: `State ID`,
            isSort: true
        },
        {
            key: "programName",
            text: `Program name`,
            isSort: true
        },
        {
            key: "programId",
            text: `Program ID`,
            isSort: true
        },
        {
            key: "didiId",
            text: `Didi ID`,
            isSort: true
        },
    ]
}
export default TableColumns;