import GlobalConfig from '../../config/globalconfig';

export const postApi = {
  method: 'post',
  headers: GlobalConfig.getHeaders(['JSON']),
  params: {},
};

export const getApi = {
  method: 'get',
  headers: GlobalConfig.getHeaders(['JSON']),
  params: {},
};

export const postMultipartApi = {
  method: 'post',
  headers: GlobalConfig.getHeaders(['MULTIPART_FORM_DATA']),
  params: {},
};

export const deleteApi = {
  method: 'delete',
  headers: GlobalConfig.getHeaders(['JSON']),
  params: {},
};