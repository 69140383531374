import React, { useEffect, useState } from 'react'
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import { useDispatch } from 'react-redux';
import { commonService } from '../../../../services/common';
import { setApiError } from '../../../../storeManager/common/actions';
import PreLoader from '../../../../components/Common/Preloader';
import NoDidiIcon from '../../../../assets/images/no-didi-icon.svg'
import InfoBar from '../../../../components/Common/InfoBar';
import Table from '../../../../components/Common/Table';
import TableColumns from '../TableColumns';
import { catchErrorHandler } from '../../../../services/commonUtils';
import GlobalConfig from '../../../../config/globalconfig';

const DidisTab = ({ detail, activeWidgetId, activeWidgetName }) => {
    const [didiList, setDidiList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [infoBoxData, setInfoBoxData] = useState([]);
    const dispatch = useDispatch();

    useEffect(()=>{
        fetchDidiList(detail?.programId, activeWidgetId, activeWidgetName, detail?.programName.replaceAll(" ", ""))
    },[])

    
    const fetchDidiList = async (id, status = 1, type, programName) => {
        setIsLoading(true)
        try {
            const response = await commonService.fetchProgramDidiList(id, status, type, programName);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setDidiList(res?.data?.details)
                setInfoBoxData(res?.data?.counts)
            }
            else {
                dispatch(setApiError())
                setDidiList([])
                setInfoBoxData([])
            }
            setIsLoading(false)
        } catch (error) {
            setDidiList([])
            setInfoBoxData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const orderOfInfoBox = ["Didis","BPM Approved", "BPM Waitlisted", "BPM Rejected", "VO Endorsed"];

    const sortedInfoData = infoBoxData.sort((a, b) => {
        return orderOfInfoBox.indexOf(a.name) - orderOfInfoBox.indexOf(b.name);
    });

    const handleFileUploadSuperAdmin = () => {
        dispatch(openModal(MODALS.SUPER_ADMIN_FILE_UPLOAD_MODAL,
            {
                parentClass: "super-admin-Program-file-upload center-modal upload-modal-wrap",
                modalTitle: `Upload`,
                bodyMsg: `Upload file`,
                programId: detail?.programId,
            }))
    }
    const handleDocDownload = async () => {
        setIsLoading(true)
        try {
            const res = await commonService.downloadSuperAdminProgram(detail?.programId, detail?.programName.replaceAll(" ", ""));
            const url = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            const contentDisposition = res.headers['content-disposition'];
            const parts = contentDisposition.split(';');
            const filenamePart = parts[1];
            const sheetName = filenamePart.split('=')[1].trim();
            const filename = sheetName ? sheetName : `${detail?.programName}sheet.xlsx`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            // Clean up the URL object
            window.URL.revokeObjectURL(url);
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            // catchErrorHandler(error, dispatch(setApiError()));
            dispatch(setApiError())
            console.error(error)
        }
    }

    if (isLoading) {
        return <PreLoader />;
    }

  return (
    didiList.length > 0 ?
        <>
            <div className='program-details-right-btn-sec'>
                {didiList.length > 0 && <div className='program-details-right-title'>
                    <span>Showing 10 sample Didis</span>
                </div>}
                <div className='program-details-right-btn-outer'>
                    <button className='primary-btn program-details-right-btn-upload' onClick={() => handleFileUploadSuperAdmin()}>
                        + Upload Didi list
                    </button>
                    <button className='programs-details-filter-btn' onClick={() => handleDocDownload()}>
                        Download
                    </button>
                </div>
            </div>
            <div className='program-about-info-box'>
                {infoBoxData.length > 0 && <InfoBar>
                    <ul className='info-list'>
                        {sortedInfoData.map((item, index) => (
                            <div key={index}>
                                <li>{item.name}: <b>{item.count}</b></li>
                            </div>
                        ))}
                    </ul>
                </InfoBar>}
            </div>
            {didiList.length > 0 ? <div className='programs-details-lower-section'>
                <Table className='programs-details-table pending-table'
                    columns={TableColumns()}
                    data={didiList}
                    checkbox={false}
                    selectAll={false} />
            </div> : <div className='program-details-noData-outer'> <div className='program-details-noData-sec'>
                <img src={NoDidiIcon} />
                <span>No programs created</span>
            </div></div>}
        </>
     : 
        <div className='program-details-noData-outer'>
            <div className='program-details-noData-sec'>
                <img src={NoDidiIcon} alt="No Didis" />
                <span>No Didis found</span>
            </div>
        </div>
  )
}

export default DidisTab