import { getDiagramViewApiPath, getQueryParams, getRoles, getSourceDataApiPath, getTopoJson } from './conifg';
import { getApi, postApi } from '../../services/common/repository';
import { AxiosService } from '../../services/apiService';
import GlobalConfig from '../../config/globalconfig';
import { getBaselineDiagramViewApiPath, getBaselineSourceDataApiPath, matrixDrowdown, getSourceDataExportToCsvPath, getGrantSourceDataExportToCsvPath } from '../BaselineDashboard/helper';
import { getGrantDiagramViewApiPath, getGrantSourceDataApiPath } from '../GrantDashboard/helper';

class DashBoardService {

  getBoxData(role, Id){
    const requestObj = { ...getApi };
    const queryParam = getQueryParams(role, Id);
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/web/dashboard/view${queryParam}`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getBreadCrumbApi(role, Id) {
    const requestObj = { ...getApi };
    const queryParam = getQueryParams(role, Id);
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/web/locale/view${queryParam}`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getSourceDataApi(role, Id, typeofView, stageType) {
    const requestObj = { ...getApi };
    const queryParam = getQueryParams(role, Id, stageType, typeofView);
    requestObj.url = getSourceDataApiPath(typeofView) + queryParam;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getDiagramViewApi(role, Id, typeofView) {
    const requestObj = { ...getApi };
    const queryParam = getQueryParams(role, Id);
    requestObj.url = getDiagramViewApiPath(typeofView) + queryParam;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  fetchTopoJson(type) {
    getApi.url = `/topojsons/${getTopoJson(type)}.json`;
    const api = new AxiosService(getApi);
    return api.doAjax();
  }

  getBaselineSourceDataApi(payload, typeofView,) {
    const requestObj = { ...postApi };
    requestObj.url = getBaselineSourceDataApiPath(typeofView);
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getBaselineDiagramViewApi(payload, typeofView) {
    const requestObj = { ...postApi };
    requestObj.url = getBaselineDiagramViewApiPath(typeofView);
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  matrixDrowdownList = (type) => {
    const reqObj = { ...getApi };
    reqObj.url = matrixDrowdown(type);
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getSourceDataExportToCsv(typeofView, referenceId, referenceName, tagId, tagName) {
    const requestObj = { ...getApi };
    requestObj.url = getSourceDataExportToCsvPath(typeofView, referenceId, referenceName, tagId, tagName);
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getBaselineBoxData(payload){
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/mission/web/baselineSurvey/card/count`;
    requestObj.params = payload
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getGrantDiagramViewApi(payload, typeofView) {
    const requestObj = { ...postApi };
    requestObj.url = getGrantDiagramViewApiPath(typeofView);
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getGrantSourceDataApi(payload, typeofView,) {
    const requestObj = { ...postApi };
    requestObj.url = getGrantSourceDataApiPath(typeofView);
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
    getGrantSourceDataExportToCsv(typeofView, referenceId, referenceName) {
    const requestObj = { ...getApi };
    requestObj.url = getGrantSourceDataExportToCsvPath(typeofView, referenceId, referenceName);
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getProgramListData(payload){
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/program/list`;
    requestObj.params = payload
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  fetchActivityList = (missionId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/mission-service/activity/get/activity-list?missionId=${missionId}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  
  getChartViewData = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/dashboard/chart-view`;
    requestObj.params = payload
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getSourceData = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/dashboard/source-data`;
    requestObj.params = payload
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  downloadSourceData = (params) =>{
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/dashboard/source-data/download?referenceId=${params.referenceId}&referenceName=${params.referenceName}&activityId=${params.activityId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getGenericBoxData(payload){
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/dashboard/card-data`;
    requestObj.params = payload
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getUcmListApi = (payload) =>{
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/upcm/dropdown`;
    requestObj.params = payload
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
}

export const dashBoardService = new DashBoardService();
