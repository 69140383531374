import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import { useNavigate } from "react-router-dom";

const RedirectModal = ({ modalParameters: { message, path, state, btnName }, closePopup }) => {
    const navigate = useNavigate();
    return (
        <div>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                <button className="secondary-btn ml" onClick={closePopup}>
                    Cancel
                </button>
                <button className="primary-btn" onClick={() => {closePopup();navigate(path,{state})}}>
                    {btnName}
                </button>
            </ModalFooter>
        </div>
    )
}
export default RedirectModal