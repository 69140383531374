

import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';

const LivelihoodDeleteInfoModal = ({ closePopup, modalParameters: { msg } }) => {
    return <div className='super-admin delete-livelihood'>
        <ModalBody>
                {msg}
        </ModalBody>
        <ModalFooter>
            <button className={"white-btn livelihood-cancel-popup"} onClick={closePopup}>
                OK
            </button>
        </ModalFooter>
    </div>
}
export default LivelihoodDeleteInfoModal   