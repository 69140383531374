import React, { useContext, useEffect, useState } from 'react'
import BlueLeftArrow from '../../../../../assets/images/BlueLeftArrow.svg';
import "./style.scss"
import SearchBar from '../../../../../components/SearchBar';
import TableColumns from './TableColumns';
import Table from '../../../../../components/Common/Table';
import PreLoader from '../../../../../components/Common/Preloader';
import { setApiError } from '../../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../../services/commonUtils';
import { useDispatch } from 'react-redux';
import { commonService } from '../../../../../services/common';
import GlobalConfig from '../../../../../config/globalconfig';
import { ProgramContext } from '../..';
import ViewModal from '../../Livelihood/MapLivelihood/ViewModal';
import CustomModal from '../../../../../components/Common/Modal/VoDidiMappingFileUpload/customModal';

const MapMission = ({ setIsMapMissionCheck, detail }) => {

    const { activeVillageId, fetchProgramWidgetList, handleSetToastMessage } = useContext(ProgramContext);

    const [missionList, setMissionList] = useState([]);
    const [missionSearch, setMissionSearch] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchedDataList, setSearchedDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const dispatch = useDispatch();

    const fetchMissionList = async (id, status) => {
        setIsLoading(true)
        try {
            const response = await commonService.fetchProgramMissionList(id, status);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setMissionList(res?.data)
            }
            else {
                dispatch(setApiError())
                setMissionList([])
            }
            setIsLoading(false)
        } catch (error) {
            setMissionList([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const programMissionMapUnmap = async ( status ) => {
        const payload = {
            programId : detail?.programId,
            missionIdsList: selectedItems,
            map: status,
        }
        setIsLoading(true)
        try {
            const response = await commonService.programMissionMapUnmap(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                fetchProgramWidgetList(activeVillageId)
                setIsMapMissionCheck(false)
                handleSetToastMessage(res?.data)
            }
            else {
                dispatch(setApiError())
                handleSetToastMessage('')
            }
            setIsLoading(false)
        } catch (error) {
            handleSetToastMessage('')
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const handleTableSelect = (e, value, isAllSelected = false) => {
        let UpdatedSelectedMission = [...selectedItems];
        if (isAllSelected) {
            const newMissionList = [];
            value.forEach(el => {
                newMissionList.push(el.missionId)
            })
            setSelectedItems(newMissionList)
        }
        else {
            if (e.target.checked)
                UpdatedSelectedMission.push(value.missionId);
            else
                UpdatedSelectedMission = UpdatedSelectedMission.filter(o => o !== value.missionId);
            setSelectedItems(UpdatedSelectedMission)
        }

    }

    const handleSearchFilter = (value) => {
        let filteredList
        setMissionSearch(value)
        filteredList = missionList.filter(x => x.missionName.toLowerCase().includes(value.toLowerCase()))
        setSearchedDataList(filteredList)
        // loadDidi(activeVillage?.id, 0, value, listCount === 'all' ? totalBeneficiary : listCount)
    }

    useEffect(() => {
        fetchMissionList(detail?.programId, 0)
    }, [])


    if (isLoading) {
        return <PreLoader />;
    }

    return (
        <div className='map-mission'>
            <div className='map-mission-header'>
                <img
                    className='map-mission-back'
                    src={BlueLeftArrow}
                    onClick={() => { setIsMapMissionCheck(false); handleSetToastMessage('')}}
                    height={21}
                />
                <span>
                    Map Mission
                </span>
            </div>
            <SearchBar className="map-mission-search-bar"
                handleOnChange={(e) => handleSearchFilter(e.target.value)}
                value={missionSearch}
                placeholder='Search missions' />
            {missionList.length > 0 && <div className='map-mission-table-outer'>
                 <Table className='mission-details-table pending-table'
                    columns={TableColumns({setIsModalOpen, setSelectedRow})}
                    data={missionSearch ? searchedDataList : missionList}
                    checkbox={true}
                    selectedValues={selectedItems}
                    valueKey="missionId"
                    selectAll={false}
                    handleChange={handleTableSelect} />
            </div>}
            {missionList.length > 0 && <div className='map-mission-btn-outer'>
                <button className='secondary-btn map-mission-cancel-btn' onClick={() => {setSelectedItems([]); handleSetToastMessage('');}} >
                    Cancel
                </button>
                <button disabled={selectedItems.length <= 0} className='primary-btn map-mission-conf-btn' onClick={() => programMissionMapUnmap(1)} >
                    Confirm
                </button>
            </div>}
            {isModalOpen && (
                <div className='mission-map-activity-list-modal'>
                <CustomModal onClose={() => setIsModalOpen(false)}>
                    <ViewModal onClose={() => setIsModalOpen(false)} row={selectedRow} title="List of Activities" isMission={true} />
                </CustomModal>
                </div>
            )}
        </div>
    )
}

export default MapMission