import React, { useState, useEffect } from 'react';
// import { ModalHeader, ModalFooter } from 'reactstrap';
import { ModalBody } from 'reactstrap';
import './style.scss';
import NoDataFound from '../../NoDataFound';
import SomethingWentWrong from '../../SomethingWentWrong';
import Image from '../../Image';


const ViewModal = ({ modalParameters: { dataURLs }, closePopup, }) => {
    const [activeInd, setActiveInd] = useState(0)
    const handleClick = ind => {
        setActiveInd(ind)
    }
    return (
        <div className='upload-modal'>
            <ModalBody>
                {dataURLs.length > 0 ?
                    <div className='list-img-wrap clearfix'>
                        <div className='img-list'>
                            {dataURLs.map((item, ind) => <span
                                className={`img-thumb ${activeInd === ind ? 'active' : ''}`}
                                onClick={() => handleClick(ind)}>
                                <Image imgUrl={item.imageUrl} key={`${item.flowType}-${ind}`} />
                            </span>)}
                        </div>
                        <div className='img-preview'>
                            <Image
                                key={`preview-${activeInd}`}
                                imgUrl={dataURLs[activeInd]?.imageUrl} alt='doc' />
                        </div>
                    </div>
                    :
                    <NoDataFound />
                }

            </ModalBody>
            {/* <ModalFooter>
                <button className="secondary-btn ml" onClick={closePopup}>
                    Close
                </button>
                {/*<button className="primary-btn" onClick={onSubmit} disabled={!fileName}>
                    Submit
                </button> * /}
            </ModalFooter> */}
        </div>
    )
}
export default ViewModal