import axios from 'axios';
// import jsonp from 'jsonp';
import { serialize, replaceStringWithParams } from '../services/commonUtils';
// import GlobalConfig from '../globalConfig/globalConfig';


export function AxiosService(obj) {
  const _that = this;

  (function __construct(obj) {
    _that.headers = obj.headers;
    _that.params = obj.params || {};
    _that.method = obj.method.toLowerCase();
    _that.url = obj.url;
    _that.isSerialize = obj.isSerialize || null;
    _that.responseType = obj.responseType || ''
  }(obj));

  this.setHeaders = setHeaders;
  this.setParams = setParams;
  this.get = handleGet;
  this.post = handlePost;
  this.all = handleAll;
  this.doAjax = doAjax;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  if(!window.axios_source){
    window.axios_source = [];
  }
  window.axios_source.push(source);

  function doAjax() {
    try {
      delete _that.params.catalog_tag_filter;
    } catch (e) {
    }


    const data = _that.params;
    const convertParamsToUpdateUrl = {};
    Object.keys(data).forEach(i => {
      convertParamsToUpdateUrl[`{${i}}`] = data[i];
    });
    /*for (let i in data) {
      if(data.hasOwnProperty(i)){
        convertParamsToUpdateUrl[`{${i}}`] = data[i];
      }
    }*/
    let url = replaceStringWithParams(_that.url, Array.isArray(_that.params) ? _that.params : convertParamsToUpdateUrl);
    /*
         * No Serialization required here, I have handled it in interceptor, please look src/app.js
         * Serialization will be handled based on request headers if request Header is
         * Content-Type == GlobalConfig.getHeaders(['URL_ENCODED'])['Content-Type'];
         * then only request will be serialized otherwise json will be send to server.
         * */
    // if (_that.isSerialize) {    // suppose to be happened only for post call
    //   // serialize query string.
    //   data = $.param(_that.params);
    // }

    if (_that.method === 'get') {
      if (_that.params && Object.keys(_that.params).length > 0) {
        url += `?${serialize(_that.params)}`;
      }
    } 
    const axiosObj = {
      method: _that.method,
      url,
      headers: Object.assign({}, _that.headers), // weired cannot directly pass _that.headers as it doesn't reflect, need to figure it out later.
      data,
      cancelToken: source.token,
    }
    if (_that.responseType){
      axiosObj.responseType = _that.responseType
    }
    return axios(axiosObj);
  }

  function setParams(obj) {
    if (obj) {
      Object.keys(obj).forEach(i => {
        this.params[i] = obj[i];
      });
    }
  }

  function setHeaders(key, val) {
    this.headers[key] = val;
  }

  function handleGet() {
    this.doAjax('get');
  }

  function handlePost() {
    this.doAjax('post');
  }

  function handleAll(promises) { // promises will be promise objects array e.g. [axios.get('/user'), axios.get('/comments')]
    return axios.all(promises);
  }
}


