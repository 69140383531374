import React from 'react';
import './style.scss';
import arrow from '../../assets/images/village-arrow.svg'

const VillageFilterBox = ({ isActive = false, didiCount, villageName, handleFilter, villageId, subTitle ='No. of Didis endorsed by VO:' }) => {
    return (
        <div className={`village-filter-box ${isActive ? 'active' : ''}`} onClick={() => handleFilter(villageId)}>
            <div className='village-filter-inner'>
                <div className='content-wrap'>
                    <h3 className='box-title'>{villageName}</h3>
                    <span className='box-sub-title'>{subTitle} <b>{didiCount}</b></span>
                </div>
                <div className='icon-wrap'>
                    <img src={arrow} alt="arrow" />
                </div>
            </div>
        </div>
    )
}

export default VillageFilterBox;