import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import CustomDropdown from '../../CustomDropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { dashBoardService } from '../../../../pages/Dashboard/dashoardService';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { showLoaderAction } from '../../../../storeManager/common/actions';
import { commonService } from '../../../../services/common';

const CreateProgramModal = ({ modalParameters: { bodyMsg }, closePopup }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: '',
    startDate: '',
    endDate: '',
    referenceType: '',
    referenceId: ''
  });
  const [states, setStates] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [allBlocks, setAllBlocks] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');

  const role = "National";

  useEffect(() => {
    const fetchStates = async () => {
      const res = await dashBoardService.getBreadCrumbApi(role);
      const response = res?.data?.data?.localeResponse?.states || [];
      setAllStates(response);
      const stateName = response.map(i => ({ name: i?.name, value: i.name }));
      setStates(stateName);
    };
    fetchStates();
  }, [role]);

  useEffect(() => {
    if (selectedState) {
      const fetchDistricts = async () => {
        let selectedStatetateObj = allStates.find(o => o.name === selectedState);
        const res = await dashBoardService.getBreadCrumbApi("State", selectedStatetateObj?.id);
        const response = res?.data?.data?.localeResponse?.districts || [];
        setAllDistricts(response);
        const districtName = response.map(i => ({ name: i?.name, value: i.name }));
        setDistricts(districtName);
      };
      setSelectedDistrict('');
      setBlocks([]);
      fetchDistricts();
    } else {
      setDistricts([]);
      setBlocks([]);
    }
  }, [selectedState, role, allStates]);

  useEffect(() => {
    if (selectedDistrict) {
      const fetchBlocks = async () => {
        let selectedDistrictsObj = allDistricts.find(o => o.name === selectedDistrict);
        const res = await dashBoardService.getBreadCrumbApi("District", selectedDistrictsObj?.id);
        const response = res?.data?.data?.localeResponse?.blocks || [];
        setAllBlocks(response)
        const blocksName = response.map(i => ({ name: i?.name, value: i.name }));
        setBlocks(blocksName);
      };

      fetchBlocks();
    } else {
      setBlocks([]);
    }
  }, [selectedDistrict, selectedState, role, allDistricts]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleStartDateChange = (date) => {
    setForm({
      ...form,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setForm({
      ...form,
      endDate: date
    });
  };

  const handleGeoSelect = (value) => {
    setForm({
      ...form,
      referenceType: value,
      
    });
  };

  const handleStateSelect = (value) => {
    setSelectedState(value);
    const selectedStateObj = allStates.find(o => o.name === value);
    setForm({
      ...form,
      referenceId: selectedStateObj?.id
    });
  };

  const handleDistrictSelect = (value) => {
    setSelectedDistrict(value);
    const selectedDistrictObj = allDistricts.find(o => o.name === value);
    setForm({
      ...form,
      referenceId: selectedDistrictObj?.id
    });
  };

  const handleBlockSelect = (value) => {
    setSelectedBlock(value);
    const selectedBlockObj = allBlocks.find(o => o.name === value);
    setForm({
      ...form,
      referenceId: selectedBlockObj?.id
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);
    // Add form submission logic here
  };

  const handleCreate = async() => {
      // setIsLoading(true)
      // const payload = {}
      // try {
      //     const response = await commonService.CreateProgramSuperAdmin(payload);
      //     const res = response?.data;
      //     console.log("res",res)
      //     if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
      //         setDidiList(res?.data)
      //     }
      //     else dispatch(setApiError())
      //     setIsLoading(false)
      // } catch (error) {
      //     console.error(error)
      //     catchErrorHandler(error, dispatch(setApiError()));
      //     setIsLoading(false)
      // }
    console.log(form, "Data");
    closePopup();
  };
  const isFormValid = () => {
    return Object.values(form).every(value => value !== '');
  };

  return (
    <div className='program-list-modal'>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className='program-list-modal-form-sec'>
            <label className='program-list-modal-lebel'>Program name</label>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
          <div className='program-list-modal-form-sec program-list-dropdown-sec'>
            <div className='program-list-start'>
              <label className='program-list-modal-lebel'>Start Date</label>
              <DatePicker
                selectsStart
                selected={form.startDate}
                onChange={handleStartDateChange}
                startDate={form.startDate}
              />
            </div>
            <div className='program-list-end'>
              <label className='program-list-modal-lebel'>End Date</label>
              <DatePicker
                selectsEnd
                selected={form.endDate}
                onChange={handleEndDateChange}
                endDate={form.endDate}
                startDate={form.startDate}
                minDate={form.startDate}
              />
            </div>
          </div>
          <div className='program-list-modal-form-sec'>
            <label className='program-list-modal-lebel'>Geographical Unit</label>
            <div className='program-list-modal-dropdown'>
              <CustomDropdown
                placeHolder="Select"
                showValue={true}
                options={[
                  { name: 'State', value: 'State' },
                  { name: 'District', value: 'District' },
                  { name: 'Block', value: 'Block' }
                ]}
                onChange={handleGeoSelect}
                value={form.referenceType}
              />
            </div>
          </div>
          <div className='program-list-modal-form-sec'>
            <label className='program-list-modal-lebel'>State</label>
            <div className='program-list-modal-dropdown'>
              <CustomDropdown
                placeHolder="Select"
                showValue={true}
                options={states}
                onChange={(value) => handleStateSelect(value)}
                value={selectedState}
              />
            </div>
          </div>
          {['Block', 'District'].includes(form.referenceType) && <div className='program-list-modal-form-sec'>
            <label className='program-list-modal-lebel'>District</label>
            <div className='program-list-modal-dropdown'>
              <CustomDropdown
                placeHolder="Select"
                showValue={true}
                options={districts}
                onChange={(value) => handleDistrictSelect(value)}
                value={selectedDistrict}
              />
            </div>
          </div>}
          {form.referenceType === "Block" && <div className='program-list-modal-form-sec'>
            <label className='program-list-modal-lebel'>Block</label>
            <div className='program-list-modal-dropdown'>
              <CustomDropdown
                placeHolder="Select"
                showValue={true}
                options={blocks}
                onChange={(value) => handleBlockSelect(value)}
                value={selectedBlock}
              />
            </div>
          </div>}
        </form>
      </ModalBody>
      <ModalFooter>
        <button className="secondary-btn ml" onClick={closePopup}>
          Cancel
        </button>
        <button className="primary-btn" disabled={!isFormValid()} onClick={handleCreate}>
          Create
        </button>
      </ModalFooter>
    </div>
  );
}

export default CreateProgramModal;