import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Common/Header";
import Profile from "../../assets/images/didi-profile-picture.png"
import EditButton from "../../assets/images/edit-icon-grey.svg"
import "./style.scss"
import Image from "../../components/Common/Image";
import dummy from "../../assets/images/female.svg"
import { commonService } from "../../services/common";
import GlobalConfig from "../../config/globalconfig"
import { setApiError } from "../../storeManager/common/actions";
import { catchErrorHandler } from "../../services/commonUtils";
import { useDispatch } from "react-redux";
import PreLoader from "../../components/Common/Preloader";
import { openModal } from "../../storeManager/modal/actions";
import { MODALS } from "../../storeManager/modal/constants";
import CustomModal from "../../components/Common/Modal";


const BeneficiaryDetailPage = () => {
    const dispatch = useDispatch();
    const { id: didiId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [personalDetail, setPersonalDetail] = useState({})
    const [tagDetails, setTagDetails] = useState([])
    useEffect(() => {
        if (didiId)
            getProfileDetails(didiId)
    }, [])
    const getProfileDetails = id => {
        Promise.all([
            commonService.getBeneficiaryPersonalDetail(id),
            commonService.getBeneficiaryTagDetail(id)
        ]).then((res) => {
            const { data: didiPersonalDetails } = res[0];
            const { data: didiTagDetails } = res[1];
            if (didiPersonalDetails.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS
                && didiTagDetails.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setPersonalDetail(didiPersonalDetails.data[0])
                setTagDetails(didiTagDetails.data?.profiles)
            } else {
                dispatch(setApiError())
            }
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                catchErrorHandler(error, dispatch(setApiError()));
            });
    }
    const handleMoreAnswer = (name, question) => {
        dispatch(openModal(MODALS.MISSION_ANSWER_LIST,
            {
                parentClass: "center-modal answer-list-modal",
                ansList: question?.values.slice(1),
                modalTitle: `${name} - ${question?.name}`,
                
            }))
    }
    console.log("personalDetail?.crpImage", personalDetail?.crpImage)
    if(isLoading)
    return <PreLoader />
    return (
        <>
            <Header />
            <div className="didi-profile-page">
                <section className="didi-basic-info">
                    <div className="didi-profile">
                        {/* <img src={Profile} alt="didi-profile" className="didi-profile-image" /> */}
                        <Image className="didi-profile-image" imgUrl={personalDetail?.crpImage} placeholder={dummy} isPreview={false} alt='Didi img' />
                    </div>
                    <div className="info">
                        <div>Didi Name: <span>{personalDetail?.name}</span></div>
                        <div>Dada Name: <span>{personalDetail?.husbandName}</span></div>
                        <div>House Number: <span>{personalDetail?.houseNumber}</span></div>
                        <div>Tola: <span>{personalDetail?.tola}</span></div>
                        <div>Village: <span>{personalDetail?.villageName}</span></div>
                        <div>Block: <span>{personalDetail?.blockName}</span></div>
                        <div>District: <span>{personalDetail?.districtName}</span></div>
                    </div>
                    <div className="upcm-info">
                        <div>Caste: <span>{personalDetail?.castName}</span></div>
                        <div>SHG: <span>{personalDetail?.shg}</span></div>
                        <div>Able Bodied Woman: <span>{personalDetail?.ableBodied}</span></div>
                        <div>UPCM: <span>{personalDetail?.upcmName}</span></div>
                        <div>UPCM Contact: <span>{personalDetail?.upcmContactNumber}</span></div>
                    </div>
                    <div className="edit-button">
                        <img src={EditButton} alt="edit-button" />
                    </div>
                </section>
                <hr />
                {tagDetails.some(x => x.details?.length > 0 ) ? <section className="didi-missions-info">
                    <h2>Didi's basic details- captured from various missions</h2>
                    {tagDetails?.map(tag => {
                        if(tag?.details?.length> 0 ) 
                        return (<section className="didi-missions" key={tag?.name}>
                            <h3>{tag?.name}</h3>
                            <div className="missions-info">
                                {tag?.details?.map(ques => {
                                    return <div key={ques?.name} className="mission-details"><div>{ques?.name}:</div> <span>{ques?.values[0]?.date} - {ques?.values[0]?.value} {ques?.count > 1 && <span className="primary-link" onClick={() => handleMoreAnswer(tag?.name, ques)}>&nbsp;[+{ques?.count - 1} more]</span>}</span></div>
                                })}
                            </div>
                        </section>)
                    })}
                </section>
                :
                null}
            </div>
            <CustomModal />
        </>
    )
}

export default BeneficiaryDetailPage;