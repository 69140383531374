export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const MODALS = {
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  REDIRECT_MODAL: 'REDIRECT_MODAL',
  UPLOAD_MODAL:'UPLOAD_MODAL',
  VIEW_MODAL:'VIEW_MODAL',
  REVERIFICATION_CONFIRMATION_MODAL: 'REVERIFICATION_CONFIRMATION_MODAL',
  INFO_MODAL: 'INFO_MODAL',
  USER_UPLOAD_MODAL:'USER_UPLOAD_MODAL',
  LANGUAGE_UPLOAD_MODAL:'LANGUAGE_UPLOAD_MODAL',
  ERROR_LIST_MODAL:'ERROR_LIST_MODAL',
  UPCM_SELECTION_MODAL:'UPCM_SELECTION_MODAL',
  DIDI_SELECTION_MODAL:'DIDI_SELECTION_MODAL',
  BACKUPFILE_UPLOAD_MODAL:'BACKUPFILE_UPLOAD_MODAL',
  VO_DIDI_MAPPING_FILE_UPLOAD:'VO_DIDI_MAPPING_FILE_UPLOAD',
  BACKUPIMAGE_UPLOAD_MODAL:'BACKUPIMAGE_UPLOAD_MODAL',
  MISSION_ANSWER_LIST:"MISSION_ANSWER_LIST",
  BASELINE_BACKUPFILE_UPLOAD_MODAL:'BASELINE_BACKUPFILE_UPLOAD_MODAL',
  ADD_EDIT_SMALL_GROUP: 'ADD_EDIT_SMALL_GROUP',
  DIDI_SG_SELECTION_MODAL:'DIDI_SG_SELECTION_MODAL',
  SUCCESS_MODAL: 'SUCCESS_MODAL',
  REASSIGN_SMALL_GROUP_MODAL: 'REASSIGN_SMALL_GROUP_MODAL',
  BASELINE_IMAGE_UPLOAD_MODAL:'BASELINE_IMAGE_UPLOAD_MODAL',
  CREATE_PROGRAM_MODAL: 'CREATE_PROGRAM_MODAL',
  SUPER_ADMIN_FILE_UPLOAD_MODAL: "SUPER_ADMIN_FILE_UPLOAD_MODAL",
  SUPER_ADMIN_DELETE_INFO_MODAL: "SUPER_ADMIN_DELETE_INFO_MODAL",
  CREATE_LIVELIHOOD_ICON_UPLOAD_MODAL: "CREATE_LIVELIHOOD_ICON_UPLOAD_MODAL",
  DIDI_LIVELIHOOD_DATA_EDIT_HISTORY_MODAL: "DIDI_LIVELIHOOD_DATA_EDIT_HISTORY_MODAL",
  DIDI_LIVELIHOOD_DATA_OPEN_COUNT_MODAL: "DIDI_LIVELIHOOD_DATA_OPEN_COUNT_MODAL",
  LIVELIHOOD_DELETE_MODAL:"LIVELIHOOD_DELETE_MODAL"
};
