import React, { useContext, useEffect, useState } from 'react'
import { commonService } from '../../../../services/common';
import { useDispatch } from 'react-redux';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { setApiError } from '../../../../storeManager/common/actions';
import GlobalConfig from '../../../../config/globalconfig';
import SearchBar from '../../../../components/SearchBar';
import NoDataIcon from '../../../../assets/images/blank-page.png'
import './style.scss'
import Table from '../../../../components/Common/Table';
import TableColumns from './TableColumns';
import PreLoader from '../../../../components/Common/Preloader';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import MapMission from './MapMission';
import ToastMsg from '../../../../components/Common/ToastMsg';
import CustomModal from '../../../../components/Common/Modal/VoDidiMappingFileUpload/customModal';
import ViewModal from '../Livelihood/MapLivelihood/ViewModal';
import { ProgramContext } from '..';


const MissionTab = ({detail}) => {

    const { toastMessage, handleSetToastMessage } = useContext(ProgramContext);

    const [missionList, setMissionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMapMissionCheck, setIsMapMissionCheck] = useState(false);
    // const [toastMessage, setToastMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);  
    const [searchQuery, setSearchQuery] = useState('');

    const dispatch = useDispatch();

    const fetchMissionList = async (id, status) => {
        setIsLoading(true)
        try {
            const response = await commonService.fetchProgramMissionList(id, status);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setMissionList(res?.data)
            }
            else {
                dispatch(setApiError())
                setMissionList([])
            }
            setIsLoading(false)
        } catch (error) {
            setMissionList([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const programMissionMapUnmap = async ( missionId, status ) => {
        setIsLoading(true)
        const payload = {
            programId : detail?.programId,
            missionIdsList: missionId,
            map: status,
        }
        try {
            const response = await commonService.programMissionMapUnmap(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                handleSetToastMessage(res?.data)
            }
            else {
                dispatch(setApiError())
                handleSetToastMessage('')
            }
            setIsLoading(false)
        } catch (error) {
            handleSetToastMessage('')
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };
    const filteredData = missionList.filter(item => {
        const term = searchQuery.toLowerCase();
        return item.missionName.toLowerCase().includes(term)
    });

    useEffect(()=>{
        fetchMissionList(detail?.programId, 1)
    },[toastMessage])

    const handleDelete = (missionId) => {
        dispatch(openModal(MODALS.SUPER_ADMIN_DELETE_INFO_MODAL,
            {
                parentClass: "super-admin-delete-info center-modal confirmation-modal",
                btnText: "Delete",
                confirmAction: programMissionMapUnmap,
                btnClass: "livelihood-delete",
                id: [missionId],
                status: 0,
                modalTitle: 'Are you sure to unmap mission?',
                message: `Un-mapping Mission ID: ${missionId}, which consists of all the activities linked to the program will be removed.`
            }))
    }

    if (isLoading) {
        return <PreLoader />;
    }

  return (
    isMapMissionCheck ? <MapMission setIsMapMissionCheck={setIsMapMissionCheck} detail={detail} /> :
        <>
            <div className={missionList.length > 0 ? 'mission-details-right-sec ' : 'mission-details-right-sec mission-details-optional'}>
                {missionList.length > 0 && <div className='mission-details-right-title'>
                    <span>Showing {filteredData?.length} missions</span>
                </div>}
                <div className='mission-header-outer'>
                    <SearchBar className="mission-search-bar" placeholder='Search missions' value={searchQuery} onChange={handleSearch} />
                    <button className='primary-btn mission-map-btn' onClick={()=> setIsMapMissionCheck(true)} >
                    + Map Mission
                    </button>
                </div>
            </div>
            {filteredData.length > 0 ? <div className='mission-details-lower-section'>
                <Table className='mission-details-table pending-table'
                    columns={TableColumns({handleDelete, setIsModalOpen, setSelectedRow})}
                    data={filteredData}
                    checkbox={false}
                    selectAll={false} />
            </div> : (
                    <div className='mission-details-noData-outer'>
                        <div className='mission-details-noData-sec'>
                            <img src={NoDataIcon} alt="No Data" />
                            <span>No missions have been mapped to 
                            this program yet</span>
                        </div>
                    </div>
                )}
            {toastMessage ? <ToastMsg otpMsg={toastMessage} success={true} /> : ''}
            {isModalOpen && (
                <div className='mission-map-activity-list-modal'>
                <CustomModal onClose={() => setIsModalOpen(false)}>
                    <ViewModal onClose={() => setIsModalOpen(false)} row={selectedRow} title="List of Activities" isMission={true} />
                </CustomModal>
                </div>
            )}
        </>
  )
}

export default MissionTab