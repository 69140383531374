import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import './style.scss';

const ErrorListModal = ({ modalParameters: { errorList, showActionBtn=true }, closePopup }) => {
    return (
        <div>
            <ModalBody>
                {errorList?.length > 0 && errorList?.map((errKey, ind) => {
                    return (
                        <>
                            <span key={`err-${ind}`} className='error-msg-title'><img src={errorIcon} width='12' alt="error" />{errKey?.key || "File Format"}</span>
                            <ul className='error-list'>
                            {
                                    errKey?.errors?.length > 0 && errKey?.errors?.map((err, i) => <li key={`error-msg-${i}`}>
                                        {err}
                                    </li>)
                            }
                            </ul>
                        </>
                    )
                })}


            </ModalBody>
            <ModalFooter>
                {showActionBtn && <button className="primary-btn ml" onClick={closePopup}>
                    Ok
                </button>}
            </ModalFooter>
        </div>
    )
}
export default ErrorListModal