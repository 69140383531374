import GlobalConfig from '../../../config/globalconfig'


export const getDownloadAPIPath = (type) => {
    let apiPaths = {
      didiIncome: `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/getIncome-expense`,
      average: `${GlobalConfig.API_ROOT}/read-api/web/funnelGraph/view`,
      noEvent: `${GlobalConfig.API_ROOT}/didi-service/graph/no-event-entry/view`
    }
  
    return apiPaths[type];
  };
  
  export const getDownloadAPIStatusPath = (type, id) => {
    let apiPaths = {
        didiIncome: `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/checkDownloadStatus?requestId=${id}`,
        average: `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/checkDownloadStatus?requestId=${id}`,
        noEvent: `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/checkDownloadStatus?requestId=${id}`
    };
    return apiPaths[type];
  };