const InfoBox = ({ boxData }) => {
  return (
    <ul className="box-section">
      {boxData?.map((boxItem, i) => (
        <li key={i} className="box-data">
          <div className="box-left">
            <h2>{boxItem.values}</h2>
            <div className="box-icon">
              <img src={boxItem.imgName} alt={boxItem.name} />
            </div>
          </div>
          <p>{boxItem.name}</p>

        </li>
      ))}
    </ul>
  );
};

export default InfoBox;
