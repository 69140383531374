import React from 'react';
import './style.scss';

const TabsComponent = ({ isActive, count, title }) => {
    return (
        <div className={`tab-component-head-outer ${isActive ? 'active': ''}`}>
            <div className='tab-component-head-inner'>
                <div className='tab-component-title'>
                    {title} ({count})
                </div>
            </div>

        </div>
    )
}


export default TabsComponent;
