import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import Table from '../../Table';
import TableColumns from './columns';

const MissionAnswerListModal = ({ modalParameters: { ansList }, closePopup }) => {
    return (
        <div>
            <ModalBody>
                <Table
                    className='pending-table answer-list-table'
                    columns={TableColumns()}
                    data={ansList}
                    checkbox={false}
                />
            </ModalBody>
            <ModalFooter>
                
            </ModalFooter>
        </div>
    )
}
export default MissionAnswerListModal