import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';

const ReverificationConfirmationModal = ({ modalParameters: { confirmAction, type, payload, crpList }, closePopup }) => {
    const printCrpList = () => {
        const crpString = crpList?.map((item) => item.crpName)
        return crpString.join(',')
    }
    return (
        <div>
            <ModalBody>
                {type === 'accept' ? `Are you sure, you want to approve ${printCrpList()} villages?` : type === 'reject' ? `Are you sure, you want to reject ${printCrpList()} villages?` : '' }
            </ModalBody>
            <ModalFooter>
                <button className="secondary-btn ml" onClick={closePopup}>
                    Cancel
                </button>
                <button className="primary-btn" onClick={() => confirmAction(payload)}>
                    Confirm
                </button>
            </ModalFooter>
        </div>
    )
}
export default ReverificationConfirmationModal