import React, { useContext, useEffect, useState } from 'react'
import BlueLeftArrow from '../../../../../assets/images/BlueLeftArrow.svg';
import "./style.scss"
import SearchBar from '../../../../../components/SearchBar';
import TableColumns from './TableColumns';
import Table from '../../../../../components/Common/Table';
import PreLoader from '../../../../../components/Common/Preloader';
import { setApiError } from '../../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../../services/commonUtils';
import { useDispatch } from 'react-redux';
import { commonService } from '../../../../../services/common';
import GlobalConfig from '../../../../../config/globalconfig';
import CustomModal from '../../../../../components/Common/Modal/VoDidiMappingFileUpload/customModal'
import ViewModal from './ViewModal';
import { ProgramContext } from '../..';

const MapLivelihood = ({ setIsMapLivelihoodCheck, detail }) => {

    const { activeVillageId, fetchProgramWidgetList, handleSetToastMessage } = useContext(ProgramContext);

    const [livelihoodList, setLivelihoodList] = useState([]);
    const [livelihoodSearch, setLivelihoodSearch] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchedDataList, setSearchedDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);    
    const dispatch = useDispatch();

    const fetchLivelihoodList = async (id) => {
        setIsLoading(true)
        try {
            const response = await commonService.fetchLivelihoodMappedList(id,0);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setLivelihoodList(res?.data)
            }
            else {
                dispatch(setApiError())
                setLivelihoodList([])
            }
            setIsLoading(false)
        } catch (error) {
            setLivelihoodList([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const programLivelihoodMapUnmap = async ( status ) => {
        const payload = {
            programId : detail?.programId,
            livelihoodIdsList: selectedItems,
            map: status,
        }
        setIsLoading(true)
        try {
            const response = await commonService.programLivelihoodMapUnmap(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                fetchProgramWidgetList(activeVillageId)
                setIsMapLivelihoodCheck(false)
                handleSetToastMessage(res?.data);
            }
            else {
                dispatch(setApiError());
                setIsMapLivelihoodCheck(false)
                handleSetToastMessage('')
            }
            setIsLoading(false)
        } catch (error) {
            handleSetToastMessage('')
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const handleTableSelect = (e, value, isAllSelected = false) => {
        let UpdatedSelectedLivelihood = [...selectedItems];
        if (isAllSelected) {
            const newLivelihoodList = [];
            value.forEach(el => {
                newLivelihoodList.push(el.id)
            })
            setSelectedItems(newLivelihoodList)
        }
        else {
            if (e.target.checked)
                UpdatedSelectedLivelihood.push(value.id);
            else
                UpdatedSelectedLivelihood = UpdatedSelectedLivelihood.filter(o => o !== value.id);
            setSelectedItems(UpdatedSelectedLivelihood)
        }

    }

    const handleSearchFilter = (value) => {
        let filteredList
        setLivelihoodSearch(value)
        filteredList = livelihoodList.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
        setSearchedDataList(filteredList)
        // loadDidi(activeVillage?.id, 0, value, listCount === 'all' ? totalBeneficiary : listCount)
    }

    useEffect(() => {
        fetchLivelihoodList(detail?.programId)
    }, [])


    if (isLoading) {
        return <PreLoader />;
    }

    return (
        <div className='map-livelihood'>
            <div className='map-livelihood-header'>
                <img
                    className='map-livelihood-back'
                    src={BlueLeftArrow}
                    onClick={() => {
                        setIsMapLivelihoodCheck(false);
                        handleSetToastMessage('');
                        }
                }
                    height={21}
                />
                <span>
                    Map Livelihood
                </span>
            </div>
            <SearchBar className="map-livelihood-search-bar"
                handleOnChange={(e) => handleSearchFilter(e.target.value)}
                value={livelihoodSearch}
                placeholder='Search livelihoods' />
            {livelihoodList.length > 0 && <div className='map-livelihood-table-outer'>
                 <Table className='mission-details-table pending-table'
                    columns={TableColumns(setIsModalOpen, setSelectedRow)}
                    data={livelihoodSearch ? searchedDataList : livelihoodList}
                    checkbox={true}
                    selectedValues={selectedItems}
                    valueKey="id"
                    selectAll={false}
                    handleChange={handleTableSelect} />
            </div>}
            {livelihoodList.length > 0 && <div className='map-livelihood-btn-outer'>
                <button className='secondary-btn map-livelihood-cancel-btn' onClick={() => {
                    setIsMapLivelihoodCheck(false);
                    handleSetToastMessage('');
                    setSelectedItems([])
                }}>
                    Cancel
                </button>
                <button disabled={selectedItems.length <= 0} className='primary-btn map-livelihood-conf-btn' onClick={() => programLivelihoodMapUnmap(1)} >
                    Confirm
                </button>
            </div>}
            {isModalOpen && (
                <CustomModal onClose={() => setIsModalOpen(false)}>
                    <ViewModal onClose={() => setIsModalOpen(false)} row={selectedRow} title="Livelihood Details" />
                </CustomModal>
            )}
        </div>
    )
}

export default MapLivelihood;