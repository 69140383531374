import React from 'react'
import { graphTransformer } from '../conifg';
import ChartView from '../ChartView';

const HorizontalChartView = ({ slide, setSummary, summary, toggleType, activity }) => {

    const transformedData = graphTransformer({data: summary,toggleType, activity})?.graphData;
  return (
   <>
  {transformedData &&  <ChartView toggleType={toggleType}
 slide={slide} apiData={transformedData}  activity={activity}/>}
   </>
  )
}

export default HorizontalChartView