import React, { useEffect, useState } from 'react'
import { commonService } from '../../../../services/common'
import { useDispatch } from 'react-redux';
import { setApiError } from '../../../../storeManager/common/actions';
import "../style.scss"

const MultiDownload = (props)=> {
    const dispatch = useDispatch();
    const [downloadCompletedStatus, setDownloadCompletedStatus] = useState("")

    useEffect(()=>{
        // downloadCompletedStatus === 200 && clearInterval(checkApiStatus)
        let multiDownloadData;
        setTimeout(()=>{
             multiDownloadData = JSON.parse(localStorage.getItem("multiDownloadData")) || "";
        },500)
        const checkApiStatus = setInterval(()=>{
            if(downloadCompletedStatus === 200 )
                clearInterval(checkApiStatus)
             else
            handleBaselineDocDownload(multiDownloadData?.data)
        }, 4000)
        return () => clearInterval(checkApiStatus);
    },[downloadCompletedStatus])


    const handleBaselineDocDownload = async (multiDownloadData) => {
        try {
            const res = await commonService.CheckDownloadStatusMultipleDataBaselineHamletSurve(multiDownloadData);
            setDownloadCompletedStatus(res?.status)
            if(res?.status === 200) {
                const url = URL.createObjectURL(res?.data);
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = res.headers['content-disposition'];
                const parts = contentDisposition?.split(';');
                const filenamePart = parts[1];
                const sheetName = filenamePart?.split('=')[1].trim();
                const filename = sheetName ? sheetName : `a.sheet.xlsx`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                // Clean up the URL object
                window.URL.revokeObjectURL(url);
                // clearInterval(checkApiStatus)
            }
  
            // setIsLoading(false)
      
        } catch (error) {
            // setIsLoading(false)
            // catchErrorHandler(error, dispatch(setApiError()));
            dispatch(setApiError())
            console.error(error)
        }
      }
  return (
    <h1 className='multiDownload-sec'>{downloadCompletedStatus === 200 ? "The files have been downloaded" : "Please wait until the download is complete"}</h1>
  )
}

export default MultiDownload