import React from 'react'
import { ModalBody } from 'reactstrap'
import cross from '../../../../../assets/images/cross.svg';
import './../style.scss'
const ViewModal = ({onClose , row, title, isMission = false }) => {
  return (
    <div className='livelihood-info'>
    <div className='modal-content'>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <span class="title">{title}</span>
                        <span class="close-modal-cross">
                            <img src={cross} alt="close modal icon" onClick={onClose}/>
                        </span>
                    </h5>
                </div>
             <div className='upload-modal'>
                        <ModalBody>
                        {isMission ?
                        <div>
                                <div className='item-name'>
                                    {row.activityName.map((item,i)=>{
                                        return(
                                            <div className='livelihood-details' key={item.id}>{item}</div>
                                        )
                                    })}
                                    </div>
                        </div> :
                        <>
                            <div>
                                <div className='main-head'>Asset Type <span className='activity-list-count'>{row.assets.length}</span></div>
                                <div className='item-name'>
                                    {row.assets.map((item,i)=>{
                                        return(
                                            <div className='livelihood-details' key={item.id}>{item.name}</div>
                                        )
                                    })}
                                    </div>
                            </div>
                            <div>
                                <div className='main-head'>Product Type <span className='activity-list-count'>{row.products.length}</span></div>
                                <div className='item-name'>
                                    {row.products.map((item,i)=>{
                                        return(
                                            <div className='livelihood-details' key={item.id}>{item.name}</div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='main-head'>Event <span className='activity-list-count'>{row.events.length}</span></div>
                                <div className='item-name'>
                                    {row.events.map((item,i)=>{
                                        return(
                                            <div className='livelihood-details' key={item.id}>{item.name}</div>
                                        )
                                    })}
                                </div>
                            </div>
                            </>}
                        </ModalBody>
                </div>
            </div>
            </div>
  )
}

export default ViewModal