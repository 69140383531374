import React from 'react'
import { ModalBody } from 'reactstrap'
import Table from '../../Table'
import './style.scss'
import TableColumns from './TableColumn'

const DidiLivelihoodViewAssetModal = ({ modalParameters: { tableData }, closePopup }) => {
  return (
    <div className='upload-modal didi-livelihood-count-modal'>
        <ModalBody>
            <div className='livelihood-list-outer'>
            <Table
                    className='pending-table mapping-list'
                    columns={TableColumns()}
                    data={tableData}
                    checkbox={false}
                    selectAll={false}
                />
            </div>
        
        </ModalBody>
    {/* {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>} */}
</div>
  )
}

export default DidiLivelihoodViewAssetModal