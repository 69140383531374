import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';

const ConfirmationModal = ({ modalParameters: { message, confirmAction, btnText = "Confirm", btnClass ="blue-btn" }, closePopup }) => {
    return (
        <div>
            <ModalBody dangerouslySetInnerHTML={{ __html: message }}>
                {/* {message} */}
            </ModalBody>
            <ModalFooter>
                <button className="white-btn ml" onClick={closePopup}>
                    Cancel
                </button>
                <button className={btnClass} onClick={confirmAction}>
                    {btnText}
                </button>
            </ModalFooter>
        </div>
    )
}
export default ConfirmationModal