import React, { useState } from 'react';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import deleteIcon from '../../../../assets/images/delete-icon-red.svg';
import cross from '../../../../assets/images/cross.svg';
import './style.scss'

const UploadImageIcon = ({ id, value, accept, handleChange, imagePath, isError, errorMsg, handleDelete, showPreview = false }) => {
    const [isPreview, setIsPreview] = useState(false);

    return (
        <>
        <div className='livelihood-upload-doc-outer'>
                <label className={`upload-doc ${!imagePath ? "livelihood-upload-doc" : ""}`} htmlFor={id}>
                   {imagePath ? <div className={`livelihood-icon-upload-doc upload-view ${isError ? 'error' : ''}`}>
                    {/* <img className='delete-icon' src={deleteIcon} alt='delete' width='15' onClick={() => handleDelete(id)} /> */}
                    <span className='img-name' onClick={() => showPreview && setIsPreview(true)}>
                        <img height={116} width={116} src={imagePath} alt="uploaded" />
                    </span>
                </div> : "Browse"}
                </label>
            <input
                id={id}
                type="file"
                accept={accept}
                onClick={e => { e.currentTarget.value = null }}
                onChange={e => e.currentTarget.files.length > 0 ? handleChange(e) : null}
            />
            {!imagePath && 
            <ul class="upload-note"><li>Accepted format: .jpg .jpeg .png</li></ul>}
            {isError && <span className='error-msg'><img src={errorIcon} width='12' alt="error" />{errorMsg}</span>}
            </div>
        </>
    );
};

export default UploadImageIcon;
