

import React, { useState, useEffect } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import { commonService } from '../../../../services/common';
import { useDispatch } from 'react-redux';
import { setApiError, showLoaderAction } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import didiIcon from '../../../../assets/images/female.svg'




const AddEditSmallGroupModal = ({ modalParameters: { type, callBackFn, smallGroupId, totalDidi, name ='', referenceId, roleName }, closePopup }) => {

    const dispatch = useDispatch();
    const [errMsg, setErrMsg] = useState('')
    
    const [smallGroupName, setSmallGroupName] = useState("")

    useEffect(() => {
        setErrMsg('')
        setSmallGroupName(name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createSmallGroup = async () => {

        const payload = {
            id: null,
            name: smallGroupName,
            delete: 0,
            referenceId:  referenceId[0].id,
            referenceType: roleName.toUpperCase()
        }
        try {
            const res = await commonService.createSmallGroup(payload);
            const resObj = res.data;
            if (resObj?.message === "SUCCESS") {
                if (resObj?.data?.status) {

                    closePopup()
                    const newSgId = resObj?.data?.sgId
                    callBackFn('add',newSgId )
                } else {
                    setErrMsg(resObj?.data?.message || 'Data already exists')
                }

            } else {

                dispatch(showLoaderAction(false));

            }

        } catch (error) {
            dispatch(showLoaderAction(false));

            catchErrorHandler(error, dispatch(setApiError()));
        }
        

    }

    const editSmallGroup = async () => {
        
        const payload = {
            id: smallGroupId,
            name: smallGroupName,
            delete: 0
        }
        try {
            const res = await commonService.createSmallGroup(payload);
            const resObj = res.data;
            if (resObj?.message === "SUCCESS") {
                if (resObj?.data?.status) {
                
                    callBackFn('edit')
                    closePopup()
                    
                } else {
                    setErrMsg(resObj?.data?.message || 'Data already exists')
                }

            } else {

                dispatch(showLoaderAction(false));

            }

        } catch (error) {
            dispatch(showLoaderAction(false));

            catchErrorHandler(error, dispatch(setApiError()));
        }
    }

    const handleNameChange = (e) => {
        setErrMsg('')
        setSmallGroupName(e.target.value)
    }




    return <div>
        
        <ModalBody>

            <div className='small-group-container'>
                {type === 'edit' && <div className='small-group-didi'><img src={didiIcon} alt="didi" /> <span>Total Didi - {totalDidi}</span> </div>}

                <label className='small-group-heading-2'>Small Group
                    <input label="Small Group" type='text' value={smallGroupName} onChange={handleNameChange} placeholder='Enter'/>
                </label>
                {errMsg && <p className='error-msg'> {errMsg} </p>}
                <p className='warning-text'>
                    A consistent naming convention must be followed when creating or editing small group names to prevent duplicates in the system.
                </p>
                <p className='warning-text'>
                    For example, "SG Dhanbari" and "Dhanbari SG" would be registered as two separate small groups
                </p>
            </div>





        </ModalBody>
        <ModalFooter>
            <button className="secondary-btn ml" onClick={closePopup}>
                Cancel
            </button>
            <button className="primary-btn" onClick={type === 'add' ? createSmallGroup : editSmallGroup}>
                {type === 'add' ? "Create" :  "Save"}
            </button>
        </ModalFooter>
    </div>
}
export default AddEditSmallGroupModal   