import React, { useEffect, useState } from 'react'
import UploadFile from '../../UploadFile'
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { commonService } from "../../../../services/common";
import GlobalConfig from '../../../../config/globalconfig';
import PreLoader from '../../Preloader';
import { resetApiError, setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import './style.scss'
import InProcessing from '../../InProcessing';
import axios from 'axios';

const inputFields = () => ({
    // file1: {
    //     id: 'file1',
    //     value: '',
    //     accept: '.txt',
    //     fileName: '',
    //     fileType: "text",
    //     title: 'Upload text file',
    //     isError: false,
    //     errorMsg: ''
    // },
    file2: {
        id: 'file2',
        value: '',
        accept: '.zip',
        fileName: '',
        fileType: "zip",
        title: 'Upload Image',
        isError: false,
        errorMsg: ''
    }
})
const BackupImageUploadModal = ({ modalParameters: { userType, bodyMsg }, closePopup }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [successRes, setSuccessRes] = useState({})
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formInfo, setFormInfo] = useState(inputFields());
    const [dataUploadPercentage, setDataUploadPercentage] = useState();
    const [fileCount, setFileCount] = useState('');
    // const [data, setData] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetApiError())
    }, [])

    const handleChange = (e, id, fileType) => {
        const updatedForm = { ...formInfo };
        const updatedFormElement = { ...updatedForm[id] };
        // updatedFormElement.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        // updatedFormElement.value = e.currentTarget.files[0]
        // if ((e.currentTarget.files[0].size / 1024).toFixed(4) > 500) {
        //     updatedFormElement.errorMsg = `File size exceeds the max allowed size`
        //     updatedFormElement.isError = true
        // } else
        if(fileType === "text"){
            if ( e.currentTarget.files[0].type !== 'text/plain') {
            updatedFormElement.errorMsg = `File Format do not support`
            updatedFormElement.isError = true
        } else {
            updatedFormElement.errorMsg = ``
            updatedFormElement.isError = false
            updatedFormElement.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;    
            updatedFormElement.value = e.currentTarget.files[0]
        }
        }
        if(fileType === "zip"){
            if ( e.currentTarget.files[0].type === 'application/x-zip-compressed' 
            || e.currentTarget.files[0].type === 'application/zip') {
                updatedFormElement.errorMsg = ``
                updatedFormElement.isError = false
                updatedFormElement.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;    
                updatedFormElement.value = e.currentTarget.files[0]
            
        } else {
            updatedFormElement.errorMsg = `File Format do not support`
            updatedFormElement.isError = true
        }
        }
        updatedForm[id] = updatedFormElement;
        setFormInfo(updatedForm);
    }

    const onSubmit = () => {
        setIsLoading(true)
        const formElementsArray = [];
        const formKeys = Object.keys(formInfo);
        formKeys.forEach((key) => {
            if (formInfo[key].value)
            // console.log('key',formInfo[key])
                formElementsArray.push(formInfo[key].value);
        });
        // console.log('formElementsArray',formElementsArray)
        // try {
            const formData = new FormData();
            formKeys.forEach((key) => {
                if (formInfo[key].id === 'file1'){
                    formData.append('uploadFile', formInfo[key].value);
                } else if (formInfo[key].id === 'file2'){
                    formData.append('file', formInfo[key].value);
                } 
            })
            formData.append('driveType','selection');
            formData.append('languageCodes','en');
            axios.post(`${GlobalConfig.API_ROOT}/content-manager/content/saveFileContent`, formData, {
                onUploadProgress: (progressEvent) => {
                   setDataUploadPercentage((progressEvent.progress * 100).toFixed(1))
                    // console.log(
                    //     `Uploading... ${(progressEvent.progress * 100).toFixed(
                    //         1
                    //     )}% @ ${Math.round(progressEvent.rate / 1024)}KB/s (${Math.round(
                    //         progressEvent.estimated
                    //     )} seconds left)`
                    // );
                }}).then((response) => {
            // const response = await commonService.uploadBackupFile(formData);
            const res = response.data;
            // setData(res.data)
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                    // dispatch(openModal(MODALS.ERROR_LIST_MODAL,
                    //     {
                    //         parentClass: "center-modal error-list-modal-wrap",
                    //         modalTitle: `Error`,
                    //         errorList: res?.data,
                    //     }))
                    const result = res.data.match(/\d+/);
                    const number = parseInt(result[0], 10);
                    setFileCount(number)
                    setSuccessRes(res?.data)
                    setIsSuccess(true);
            }
            else
                setApiErrorMsg(res.message)
            setIsLoading(false)
        })
        // }
        .catch((error) => {
            setIsLoading(false)
            setApiErrorMsg(error?.message)
            catchErrorHandler(error, dispatch(setApiError()));
            console.error(error)
        })
}
    const handleDelete = (id) => {
        const updatedForm = { ...formInfo };
        const updatedFormElement = { ...updatedForm[id] };
        updatedFormElement.fileName = '';
        updatedFormElement.errorMsg = ``;
        updatedFormElement.isError = false;
        updatedFormElement.value = '';
        updatedForm[id] = updatedFormElement;
        setFormInfo(updatedForm);
    }
    const formElementsArray = [];
    const formKeys = Object.keys(formInfo);
    formKeys.forEach((key) => {
        formElementsArray.push(formInfo[key]);
    });

    if (isLoading) {
        return <InProcessing title="Image uploading is inprogress" uploadPercentage={Math.trunc(dataUploadPercentage)} maxPrct={100} />
    }

    return (
        <div className='upload-modal'>
            {isSuccess ?
                <ModalBody>
                     <h3>{fileCount} Images Uploaded Successfully</h3>
                    {/* <div className='inner-div'>
                    {data?.map((i)=>{
                        return (
                        <><h4>{i.topicName}:</h4>
                        {
                            i?.responseDTO?.data?.map((val)=>{
                             return   <>
                                <li> <span>Client Id: </span>{val.CLIENT_ID} </li>
                                <li><span> Transaction Id: </span>{val.transactionId} </li>
                                </>
                             })
                       } </>)
                    })}</div> */}
                </ModalBody>
                : <ModalBody>
                    {/* <p>{bodyMsg}</p> */}
                        {formElementsArray && formElementsArray.map((item, ind) => {
                            return (<div>
                                <h4>{item.title}</h4>
                                <br></br>
                                <UploadFile
                                    id={item.id}
                                    value={item.value}
                                    accept={item.accept}
                                    handleChange={e => handleChange(e, item.id, item.fileType)}
                                    fileName={item.fileName}
                                    isError={item.isError}
                                    errorMsg={item.errorMsg}
                                    key={item.id}
                                    handleDelete={handleDelete}
                                    showPreview={true}
                                />
                    <ul className='upload-note'>
                        <li>Accepted format: {item.accept}</li>
                    </ul>
                            </div>)
                        })
                        }
                    <ul className='upload-note'>
                        {/* <li>Max upload file size: 500 KB</li> */}
                        {/* <li>Max upload file size: 500 KB</li>
                        <li>Accepted format photo</li> */}
                    </ul>
                </ModalBody>}
            {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>}
            <ModalFooter>
                {isSuccess ? <>
                    <button className="primary-btn" onClick={closePopup}>
                        OK
                    </button>
                </>
                    :
                    <>
                        <button className="secondary-btn ml" onClick={closePopup}>
                            Cancel
                        </button>
                        <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                            Submit
                        </button>
                    </>
                }
            </ModalFooter>
        </div>
    )
}

export default BackupImageUploadModal