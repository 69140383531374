import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';

const InfoModal = ({ modalParameters: { info, showActionBtn }, closePopup }) => {
    return (
        <div>
            <ModalBody>
                {info}
            </ModalBody>
            <ModalFooter>
                {showActionBtn && <button className="secondary-btn ml" onClick={closePopup}>
                    Ok
                </button>}
            </ModalFooter>
        </div>
    )
}
export default InfoModal