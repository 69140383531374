import React, {useState} from 'react';
import errorIcon from '../../../assets/images/red-info-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon-red.svg'
import cross from '../../../assets/images/cross.svg'

const UploadFile = ({ id, value, accept, handleChange, fileName, isError, errorMsg, handleDelete, showPreview=false }) => {
    const [isPreview, setIsPreview] = useState(false)
    return (
        <>
            {fileName ?
                <div className={`upload-doc upload-view ${isError ? 'error' : ''}`}>
                    <span className='img-name' onClick={() => showPreview && setIsPreview(true)}>
                        {fileName}
                    </span>
                    <img className='delete-icon' src={deleteIcon} alt={'delete'} width='15' onClick={() => handleDelete(id)}/>
                </div>
                :
                <label className={`upload-doc`} htmlFor={id}>
                    Browse
                </label>}
            <input
                id={id}
                type="file"
                accept={accept}
                onClick={e => {e.currentTarget.value = null}}
                onChange={e => e.currentTarget.files.length > 0 ? handleChange(e) : null}
            />
            {isError && <span className='error-msg'><img src={errorIcon} width='12' alt="error" />{errorMsg}</span>}
            {isPreview && <div className='upload-img-preview'>
                <img className='cross-icon' src={cross} alt="close modal icon" onClick={() => setIsPreview(false)} />
                <img className='prev-img' src={URL.createObjectURL(value)} alt="img preview"/>
            </div>}
        </>
    )
}

export default UploadFile