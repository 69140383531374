import { dateTimeSplitter } from "../../../../services/commonUtils"

const TableColumns = (props) => {
    return [
        {
            key: "date",
            text: `Date`,
            formatter: (cell, row, rowIndex) => {
                const dateTime = dateTimeSplitter(cell)
                return `${dateTime?.day} ${dateTime?.monthAlpha}, ${dateTime?.year}`
            },
            isSort: false
        },
        {
            key: "date",
            text: `Time`,
            formatter: (cell, row, rowIndex) => {
                const dateTime = dateTimeSplitter(cell)
                return `${dateTime?.hour}:${dateTime?.minute} ${dateTime?.AmPm}`
            },
            isSort: false
        },
        {
            key: "value",
            text: `Value`,
            
            isSort: false
        },
        

    ]
}
export default TableColumns;
