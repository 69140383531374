import React, { useEffect, useState } from 'react'
import UploadFile from '../../UploadFile'
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { commonService } from "../../../../services/common";
import GlobalConfig from '../../../../config/globalconfig';
import PreLoader from '../../Preloader';
import { resetApiError, setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import InProcessing from '../../InProcessing';
import axios from 'axios';
import Table from '../../Table';
import TableColumns from './column';
import './style.scss'

const BaselineBackupFileUploadModal = ({ modalParameters: { userType, bodyMsg, surveyDataStatus, setSurveyDataStatus }, closePopup }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [zipFileName, setZipFileName] = useState('');
    // const [surveyDataStatus, setSurveyDataStatus] = useState();
    const [dataUploadPercentage, setDataUploadPercentage] = useState();
    // const [data, setData] = useState(false);
    const [formInfo, setFormInfo] = useState({
        id: 'users',
        value: '',
        accept: ['.txt', '.zip'],
        fileName: '',
        isError: false,
        errorMsg: ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetApiError())
        return () => {
            setSurveyDataStatus('')
        }
    }, [])

    const handleChange = (e) => {
        const updatedForm = { ...formInfo };
        // console.log("e.currentTarget.files[0].type",e.currentTarget.files[0].type)
        setApiErrorMsg('')
        updatedForm.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedForm.value = e.currentTarget.files[0]
        // if ((e.currentTarget.files[0].size / 1024).toFixed(4) > 500) {
        //     updatedForm.errorMsg = `File size exceeds the max allowed size`
        //     updatedForm.isError = true
        // } else 
        if (e.currentTarget.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            && e.currentTarget.files[0].type !== 'text/plain' && e.currentTarget.files[0].type !== 'application/x-zip-compressed'
            && e.currentTarget.files[0].type !== 'application/zip') {
            updatedForm.errorMsg = `File Format do not support`
            updatedForm.isError = true
        }
         else {
            updatedForm.errorMsg = ``
            updatedForm.isError = false
        }
        setFormInfo(updatedForm);
    }

    const onSubmit = () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('file', formInfo?.value);
         axios.post(`${GlobalConfig.API_ROOT}/sync-server/backup-file/upload`, formData, {
            onUploadProgress: (progressEvent) => {
               setDataUploadPercentage((progressEvent.progress * 100).toFixed(1))
                // console.log(
                //     `Uploading... ${(progressEvent.progress * 100).toFixed(
                //         1
                //     )}% @ ${Math.round(progressEvent.rate / 1024)}KB/s (${Math.round(
                //         progressEvent.estimated
                //     )} seconds left)`
                // );
            }
       }).then((response) => {
        const res = response.data;
        if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
               setIsSuccess(true);
           } else {
               dispatch(setApiError())
           }
           setIsLoading(false)
           setSurveyDataStatus(res?.data);
           setZipFileName(res?.data[0]?.zipFileName)
       })
           .catch((error) => {
               setIsLoading(false)
               console.error(error)
               catchErrorHandler(error, dispatch(setApiError()));
           });
    }
    const handleDelete = () => {
        const updatedForm = { ...formInfo };
        updatedForm.fileName = '';
        updatedForm.errorMsg = ``;
        updatedForm.isError = false;
        updatedForm.value = '';
        setApiErrorMsg('')
        setFormInfo(updatedForm);
    }

    if (isLoading) {
        return <InProcessing title="File uploading is inprogress" uploadPercentage={Math.trunc(dataUploadPercentage)} maxPrct={100} />
    }

    return (
        <div className='upload-modal'>
            {isSuccess ?
                <ModalBody>
                    {/* <h3>File Uploaded Successfully</h3> */}
                    <h4>Zip file name: <span className='baselinebackupupload-span-head'>{zipFileName}</span></h4><br/>
                    <Table
                                className='pending-table baseline-backup-file-upload'
                                columns={TableColumns()}
                                data={surveyDataStatus}
                                checkbox={false}
                                selectAll={false}
                            />
                </ModalBody>
                : <ModalBody> {
                    <><h4>{bodyMsg}</h4>
                    <br />
                    <UploadFile
                        id={formInfo.id}
                        value={formInfo.value}
                        accept={formInfo.accept}
                        handleChange={e => handleChange(e)}
                        fileName={formInfo.fileName}
                        isError={formInfo.isError}
                        errorMsg={formInfo.errorMsg}
                        key={formInfo.id}
                        handleDelete={handleDelete}
                    />
                    <ul className='upload-note'>
                        {/* <li>Max upload file size: 500 KB</li> */}
                        <li>Accepted format: .txt, .zip</li>
                    </ul> </>}
                </ModalBody>}
            {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>}
            <ModalFooter>
                {isSuccess || surveyDataStatus ? <>
                    <button className="primary-btn" onClick={closePopup}>
                        OK
                    </button>
                </>
                    :
                    <>
                        <button className="secondary-btn ml" onClick={closePopup}>
                            Cancel
                        </button>
                        <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                            Submit
                        </button>
                    </>
                }
            </ModalFooter>
        </div>
    )
}

export default BaselineBackupFileUploadModal