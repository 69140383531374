import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '../reducers'
import { createLogger } from 'redux-logger';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk'

const middleware = [thunk];

const loggerMiddleware = createLogger({
    predicate: true
});
middleware.push(loggerMiddleware);
const persistConfig = {
    key:'root',
    storage
}
const persistedReducer = persistReducer(persistConfig, reducer)
const store = () => createStore(persistedReducer, compose(
    applyMiddleware(...middleware),
));
export default store;