import { getDownloadAPIPath, getDownloadAPIStatusPath } from './helper';
import { getApi, postApi } from '../../../services/common/repository';
import { AxiosService } from '../../../services/apiService';



class DownloadService {

  getDownloadAPI(payload, type) {
    const requestObj = { ...postApi };
    requestObj.url = getDownloadAPIPath(type);
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  getDownloadAPIStatus(id, type) {
    const requestObj = { ...getApi };
    requestObj.url = getDownloadAPIStatusPath(type, id);
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
 
  
}

export const downloadService = new DownloadService();
