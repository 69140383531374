// import { isLocalHost } from '../services/commonUtils';
import { API_PATH } from './apipath';


const APP_VERSION = 1;
const API_ENV = (typeof window !== 'undefined' && ['localhost', '0.0.0.0'].includes(window.location.hostname)) ? 'development' : 'production';



const Settings = {
  APP_VERSION,
  API_ROOT: API_PATH[API_ENV].API_ROOT,
  applicationMode: null,
  API_ERROR: 0,
  API_SUCCESS: 1,
  MESSAGES_TYPES: {
    INPROGRESS: 'INPROGRESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    SUCCESS: 'SUCCESS',
    S3_SUCCESS: 'SUCCESS',
    INFO: 'INFO',
    FAIL: 'FAIL',
  },
 
  routeInfo: null, // this object will have information of current route object e.g. ptype
  isSuccess(status) {
    return (status === this.API_SUCCESS);
  },
  isError(status) {
    return (status === this.API_ERROR);
  },
  getApiUrlFromRoot(url) {
    return this.API_ROOT + url;
  },
  getHeaders(headers) {
    const HEADERS = {
      JSON: {
        'Content-Type': 'application/json',
      },
      URL_ENCODED: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      MULTIPART_FORM_DATA: {
        'Content-Type': 'multipart/form-data',
      },
      TEXT_HTML: {
        'Content-Type': 'text/html',
      },

    };
    function getHeaders() {
      let headerObj = [];
      for (const i in headers) {
        const header = HEADERS[headers[i]];
        headerObj = Object.assign(headerObj, header);
      }
      return headerObj;
    }
    return getHeaders();
  },
  isServer() {
    return (typeof window === 'undefined');
  },
};

export default Settings;
