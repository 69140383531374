import React from 'react'
import ProgressBar from "@ramonak/react-progress-bar";
import './style.scss'

const InProcessing = ({ title, uploadPercentage, maxPrct = 100 ,page}) => {
  return (
    <div className={ page === 'livelihood' ? 'inprogress-sec inprogress-livelihood' :  `inprogress-sec`}>
      <span className='inprogress-ttl'>{title}</span>
      <div className='progress-outer'>
        <div className='bar-container'>
          <div className='bar-completed' style={{ width: `${uploadPercentage}%` }}>
            <span className='percentage-text'>{uploadPercentage}%</span>
            </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default InProcessing