
const TableColumns = (props) => {
    return [
        {
            key: "upcmName",
            text: `UPCM Name`,
        },
        {
            key: "upcmNumber",
            text: `UPCM Number`,
        },
        {
            key: "tolaName",
            text: `Tola`,
        },


    ]
}
export default TableColumns;
