import React from 'react';
import './style.scss';

const SearchBar = ({ placeholder, handleOnChange, customDropdownStyle, ...rest}) => {
    return(
        <div className='search-bar-outer'>
            <input className={!customDropdownStyle ? 'input-inner-style' : 'unique-input-inner-style'} placeholder={placeholder} onChange={e => handleOnChange(e)} {...rest}/>
        </div>
    )
}
export default SearchBar;