import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setTableSortingFlag} from '../../../storeManager/common/actions.js'

const Table = (props) => {
    const { handleChange, data=[], columns, checkbox = false, selectedValues = [], valueKey, className, hasConfirmationRow = false, isConfirm = false, confirmationRowInd=null, children, disabled = false } = props;
    const dispatch = useDispatch();
    const {tableSortingFlag} = useSelector((state) => state.commonReducer)
    // const [colList, setColList] = useState([]);
    // const [isChecked, setIsChecked] = useState(false)
    const [sortingFlag, setSortingFlag] = useState(tableSortingFlag);
    // const [tableData, setTableData] = useState(data)
    useEffect(() => {
        // setTableData(data)
        if(tableSortingFlag?.key)
        handleSort(tableSortingFlag?.key)
    },[data])
    const showColumns = () => {
        const selectAll = (<th>
            {/* <div className='select-all-wrap'>Select All </div> */}
            <label >
                <input id='SelectAllCheckbox' type="checkbox" name="" onChange={e => handleSelectAll(e)} checked={data?.length === selectedValues?.length} disabled={disabled}/>
            </label>
        </th>)
        const columnsHead = columns.map((item, index) => (<th className={`column-${index + 1}`} key={`${item?.text}-${index}`}><div onClick={() => item.isSort ? handleSort(item?.key) : null} className={`table-head-cell ${item.isSort ? 'sort-wrap' : ''}`}><span>{item?.text}</span> {item.isSort && <button className='s-btn-top' ></button>}</div></th>))
        checkbox && selectAll ? columnsHead.splice(0, 0, selectAll) : columnsHead.splice(0, 0, '')
        return columnsHead;
    }

    const getRowData = (row, rowIndex) => {
        let rowData = []
        if (hasConfirmationRow && isConfirm && rowIndex === confirmationRowInd){
            rowData = <td className='confirmation-row' colSpan={columns.length}>{children}</td>
        }
        else{
            rowData = columns.map((item, index) => {
                if (item.formatter)
                    return (<td key={`${row[item.key]}-${index}`}> {item.formatter(row[item.key]?.toString() || '-', row, rowIndex)} </td>)
                else
                    return (
                        <td key={`${row[item.key]}-${index}`}>{row[item.key] || '-'}</td>
                    )
            })
        }
        checkbox &&
            rowData.splice(0, 0, (<td>
                <label >
                    <input
                        type="checkbox"
                        id={row.subId}
                        name='userList'
                        checked={selectedValues.includes(row[valueKey]) ? true : false}
                        disabled={disabled}
                        onChange={(e) => handleChange(e, row, false)} />
                    {/*<span className="che ckmark"></span>*/}
                </label>
            </td>))
        return rowData;
    }
    const fetchTableData = () => {
        const dataTable = Array.isArray(data) && data.map((item, ind) => {
            return (
                <tr key={`key-${ind}`}>
                    {
                        getRowData(item, ind)
                    }
                </tr>
            )

        })
        return dataTable;
    }
    const handleSelectAll = (e) => {
        if (data?.length !== selectedValues?.length)
            handleChange(e, data, true)
        else
            handleChange(e, [], true)
    }
    const handleSort = (key) => {
        const sortAsceAlphaNum = (a, b) => {
            if (a[key] ===null)
                a[key] =''
            if (b[key] === null)
                b[key] = ''
            var reA = /[^a-zA-Z]/g;
            // var reN = /[^0-9]/g;
            var AInt = parseInt(a[key], 10);
            var BInt = parseInt(b[key], 10);

            if (isNaN(AInt) && isNaN(BInt)) {
                var aA = a[key].replace(reA, "");
                var bA = b[key].replace(reA, "");
                // if (aA === bA) {
                //     var aN = parseInt(a[key].replace(reN, ""), 10);
                //     var bN = parseInt(b[key].replace(reN, ""), 10);
                //     return aN === bN ? 0 : aN > bN ? 1 : -1;
                // } else {
                    return aA.toLowerCase() > bA.toLowerCase() ? 1 : -1;
                // }
            } else if (isNaN(AInt)) {
                return 1;
            } else if (isNaN(BInt)) {
                return -1;
            } else {
                return AInt > BInt ? 1 : -1;
            }

        }
        const sortDescAlphaNum = (a, b) => {
            if (a[key] === null)
                a[key] = ''
            if (b[key] === null)
                b[key] = ''
            var reA = /[^a-zA-Z]/g;
            // var reN = /[^0-9]/g;
            var AInt = parseInt(a[key], 10);
            var BInt = parseInt(b[key], 10);

            if (isNaN(AInt) && isNaN(BInt)) {
                var aA = a[key].replace(reA, "");
                var bA = b[key].replace(reA, "");
                // if (aA === bA) {
                //     var aN = parseInt(a[key].replace(reN, ""), 10);
                //     var bN = parseInt(b[key].replace(reN, ""), 10);
                //     return aN === bN ? 0 : aN > bN ? -1 : 1;
                // } else {
                    return aA.toLowerCase() > bA.toLowerCase() ? -1 : 1;
                // }
            } else if (isNaN(AInt)) {
                return -1;
            } else if (isNaN(BInt)) {
                return 1;
            } else {
                return AInt > BInt ? -1 : 1;
            }
        }
        if (!columns?.some(x => x.key === sortingFlag.key) && sortingFlag.key && data?.length > 0)
            return
        // const sortedData = [...data]
         if (sortingFlag.key !== key) {
             data.sort(sortAsceAlphaNum)
            setSortingFlag({ key: key, isAscending: true })
            dispatch(setTableSortingFlag({ key: key, isAscending: false }))
        } else {
            if (sortingFlag?.isAscending) {
                data.sort(sortDescAlphaNum)
                setSortingFlag({ key: key, isAscending: false })
                dispatch(setTableSortingFlag({ key: key, isAscending: true }))
            }
            else {
                data.sort(sortAsceAlphaNum)
                setSortingFlag({ key: key, isAscending: true })
                dispatch(setTableSortingFlag({ key: key, isAscending: false }))
            }
        }
        // setTableData(sortedData)

    }
    return (<>
        <table className={`custom-table ${className}`}>
            <thead>
                <tr>
                    {showColumns()}
                </tr>
            </thead>
            <tbody>
                {fetchTableData()}
            </tbody>
        </table>
        {data.length <= 0 && <div className="no-data-found mt30">No data found</div>}
    </>)
}

export default Table;