import React, { useState } from 'react';
import axios from 'axios';
import GlobalConfig from '../../../../config/globalconfig';
import PreLoader from '../../Preloader';
import InProcessing from '../../InProcessing';
import { useDispatch } from 'react-redux';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { ModalBody, ModalFooter } from 'reactstrap';
import deleteIcon from '../../../../assets/images/delete-icon-red.svg'
import cross from '../../../../assets/images/cross.svg';

const VoDidiMappingFileUpload = ({ onClose }) => {
    const [formInfo, setFormInfo] = useState({
        id: 'users',
        value: '',
        accept: '.xlsx',
        fileName: '',
        isError: false,
        errorMsg: ''
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [dataUploadPercentage, setDataUploadPercentage] = useState();
    const [resData, setResData] = useState([]);
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const updatedForm = { ...formInfo };
        setApiErrorMsg('');
        updatedForm.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedForm.value = e.currentTarget.files[0];
        setFormInfo(updatedForm);
    };

    const onSubmit = () => {
        const { referenceId } =
            JSON.parse(localStorage.getItem("userAuth")) || "";
        // const stateId = referenceId[0]?.id;
        setIsLoading(true)
        // try {
        const formData = new FormData();
        formData.append('vosFile', formInfo?.value);

        const response = axios.post(`${GlobalConfig.API_ROOT}/user-management/vo/upload/didi`, formData, {
            onUploadProgress: (progressEvent) => {
                setDataUploadPercentage((progressEvent.progress * 100).toFixed(1))
                // console.log(
                //     `Uploading... ${(progressEvent.progress * 100).toFixed(
                //         1
                //     )}% @ ${Math.round(progressEvent.rate / 1024)}KB/s (${Math.round(
                //         progressEvent.estimated
                //     )} seconds left)`
                // );
            }
        }).then((response) => {
            const res = response.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                // if (res?.data?.length > 0) {
                //     dispatch(openModal(MODALS.ERROR_LIST_MODAL,
                //         {
                //             parentClass: "center-modal error-list-modal-wrap",
                //             modalTitle: `Error`,
                //             errorList: res?.data,
                //         }))
                // }
                // else
                setIsSuccess(true);
                setResData(res.data)
            }
            else
                setApiErrorMsg(res.message)
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
                setApiErrorMsg(error?.message)
                catchErrorHandler(error, dispatch(setApiErrorMsg()));
                console.error(error)
            })
    }
    const handleDelete = () => {
        const updatedForm = { ...formInfo };
        updatedForm.fileName = '';
        updatedForm.errorMsg = '';
        updatedForm.isError = false;
        updatedForm.value = '';
        setApiErrorMsg('');
        setFormInfo(updatedForm);
    };



    return (
        <div className='upload-modal-wrap.modal-dialog.modal-wrap popup-didi backup-file-upload'>
            <div className='modal-content'>
                {/* <button className="custom-modal-close" onClick={onClose}>&times;</button> */}
                <div className="modal-header">
                    <h5 className="modal-title">
                        <span class="title">Upload</span>
                        <span class="close-modal-cross">
                            <img src={cross} alt="close modal icon" onClick={onClose} />
                        </span>
                    </h5>
                </div>
                {isLoading && <InProcessing title="File uploading is in progress" uploadPercentage={Math.trunc(dataUploadPercentage)} maxPrct={100} />}
                {!isLoading && <div className='upload-modal'>
                    {isSuccess ?
                        <ModalBody>
                            <h3>File Uploaded Successfully</h3>
                            {resData?.length > 0 &&
                                resData.slice(0, 50).map((item, key) => {
                                    return (
                                    <>
                                      <div className='error-msg additional-msg' index={key}>
                                        <div className='error-number'>{`${key+1})`}</div>
                                        <div>{item}</div>
                                      </div>
                                    </>
                                    )
                                })
                            }
                            {resData?.length > 50 && <div className='additional-msg'>{`... ${resData.length - 50} more Didi's are already mapped`}</div>}

                        </ModalBody>
                        :
                        <ModalBody>
                            <h4>Upload file</h4>
                            <br />
                            {formInfo.fileName ?
                                <div className={`upload-doc upload-view ${formInfo.isError ? 'error' : ''}`}>
                                    <span className='img-name'>
                                        {formInfo.fileName}
                                    </span>
                                    <img className='delete-icon' src={deleteIcon} alt={'delete'} width='15' onClick={() => handleDelete(formInfo.id)} />
                                </div>
                                :
                                <label className={`upload-doc`} htmlFor={formInfo.id}>
                                    Browse
                                </label>}

                            <input
                                id={formInfo.id}
                                type="file"
                                accept={formInfo.accept}
                                // onClick={e => {formInfo.value = null}}
                                onChange={e => handleChange(e)}
                            />

                            <ul className='upload-note'>
                                {/* <li>Max upload file size: 500 KB</li> */}
                                <li>Accepted format: .xlsx</li>
                            </ul>
                        </ModalBody>
                    }
                    {apiErrorMsg && <span className='error-msg ph-20'><img width='12' alt="error" />{apiErrorMsg}</span>}
                    <ModalFooter>
                        {isSuccess ? <>
                            <button className="primary-btn" onClick={onClose}>
                                OK
                            </button>
                        </>
                            :
                            <>
                                <button className="secondary-btn ml" onClick={onClose}>
                                    Cancel
                                </button>
                                <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                                    Submit
                                </button>
                            </>
                        }
                    </ModalFooter>
                </div>
                }
            </div>
        </div>
    );
};

export default VoDidiMappingFileUpload;
