import './assets/ScssUtils/common.scss'
import './App.css';
import AppRoutes from './routes';
import './axiosInterceptor';

function App() {


  return (
    <div className="eupi">
        <AppRoutes/>
    </div>
  );
}

export default App;
