import React, { Component } from "react";
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: "Something went wrong"
  };

  componentDidCatch = (error, info) => {
    this.setState({hasError: true});
  };
  render() {
    const { hasError, errorMessage} = this.state;
    // const { isApiError } = this.props;
    if (hasError){
      return <h1>{errorMessage}</h1>;
    }else{
      return this.props.children;
    }

  }
}
const mapStateToProps = state => ({
  isApiError: state.commonReducer.isApiError,
  
});
export default connect(mapStateToProps)(ErrorBoundary);