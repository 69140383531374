import React from 'react';
// import './style.scss'

const TableColumns = (props) => {

    
    return [
        {
            key: "eventDate",
            text: `Event Date`,
            isSort: false
        },
        {
            key: "event",
            text: `Event`,
            isSort: false
        },
        {
            key: "assetType",
            text: `Type of Asset`,
            isSort: false,
        },
        {
            key: "number",
            text: "Increase in Number",
            isSort: false
        },
        {
            key: "amount",
            text: `Amount`,
            isSort: false
        },
        {
            key: "modifiedDate",
            text: `Modified Date`,
            isSort: false
        },
        {
            key: "modifiedTime",
            text: `Time`,
            isSort: false,
        },
        {
            key: "userName",
            text: `User`,
            isSort: false
        },


    ]
}
export default TableColumns;
