
const TableColumns = (props) => {
    const modifyStr = (str) => {
        switch (str) {
            case 'NOT_AVAILABLE':
                return 'NOT AVAILABLE.'
            case 'NOT_MARKED':
                return '-'
            case 'VERIFIED':
                return 'SELECTED'
            case 'NO TOLA':
                return '-'
            default:
                return str
        }
    }
    return [
        {
            key: "sn",
            text: `S.No`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1;
            },
        },
        {
            key: "didiName",
            text: `Didi Name`,
            isSort: true
        },
        {
            key: "didiId",
            text: `Didi ID`,
            isSort: true
        },
        {
            key: "dadaName",
            text: `Husband Name`,
            isSort: true
        },
        {
            key: "houseNo",
            text: `House #`,
            isSort: true
        },
        {
            key: "villageName",
            text: `Village`,
            isSort: true
        },
        {
            key: "voName",
            text: `VO Name`,
            isSort: true
        },
        {
            key: "cohortName",
            text: `Tola`,
            formatter: (cell, row, rowIndex) => {
                return modifyStr(cell)
            },
            isSort: true
        },

    ]
}
export default TableColumns;
