export const getMissionById = (missionId) => {
    const missionList = JSON.parse(sessionStorage.getItem("missions")) || []
    return missionList.filter(_x => _x.id == missionId)[0] 
  }

export const defaultWidgetChange =  {
    "id": 0,
    "name": '',
    "type":null,
    "description":  '',
    "pdfType":  null,
};

export const transformWidgetData = (response) => {

    const _widgetArray = response.map(_widget => {
        return {
            "id": _widget?.id,
            "name": _widget?.name,
            "type": _widget?.type || 'horizontalBarGraph',
            "description": _widget?.description || '',
            "pdfType": _widget?.pdfType || '',
            "doer": _widget?.doer || '',
            "subject": _widget?.subject || ''

        }
    })
    return _widgetArray;
}

export const horizontalTooltipElementcontent = (payload, label, type, toggleType, subject, doer) => {

    return (
        <><div className='tooltip-heading'>{label}</div>
        {
            
             <>
             <li style={{ listStyle: 'none', marginBottom: "7px" }}>
          <p className='tooltip-name' >Total {subject} count</p>
        <span className='tooltip-value'>{payload[0]?.payload?.count}</span>
          </li>
          <li style={{ listStyle: 'none', marginBottom: "7px" }}>
          <p className='tooltip-name' >{subject}'s Not Started </p>
        <span className='tooltip-value'>{payload[0]?.payload?.notStartedCount}</span>
          </li>
          <li style={{ listStyle: 'none', marginBottom: "7px" }}>
          <p className='tooltip-name' >{subject}'s In Progress </p>
        <span className='tooltip-value'>{payload[0]?.payload?.inProgressCount}</span>
          </li>
          <li style={{ listStyle: 'none', marginBottom: "7px" }}>
          <p className='tooltip-name' >{subject}'s Not Available </p>
        <span className='tooltip-value'>{payload[0]?.payload?.notAvailableCount}</span>
          </li>
          <li style={{ listStyle: 'none', marginBottom: "7px" }}>
          <p className='tooltip-name' >{subject}'s Completed </p>
        <span className='tooltip-value'>{payload[0]?.payload?.completedCount}</span>
          </li>
          <li style={{ listStyle: 'none' }}> 
             <p className='tooltip-name'>% of task completion</p>
             <span className='tooltip-value'>{ `${payload[0]?.payload?.pct}%`}</span>
          </li>
          </> 
         
         
        }
        </>
    )
}