import { Bar, LabelList } from "recharts";
import GlobalConfig from "../../../config/globalconfig"

export const graphTransformer = (data, type, showData, stageType, toggleType, question, summary) => {
  let graphData = [];
  if (data) {
    if (type === "CSGDisbursement") {
      data?.map((item)=>{
          let newData={}
          newData = {
              name: item?.upcmName,
              uv: toggleType ? item?.completed : Math.trunc(item?.taskCompletion),
              pv: 0,
              amt: item?.total,
              pct: Math.trunc(item?.taskCompletion),
              calculatedTotal: toggleType ? item?.notStarted : 100 - item?.taskCompletion - item?.taskInProgress - item?.taskNotAvailable,
              count: item?.completed,
              notStarted: item?.notStarted,
              inProgress: item?.inProgress,
              pctInprogress: item?.taskInProgress,
              notAvailable: item?.notAvailable,
              pctNotAvailable: item?.taskNotAvailable,
              // pctTotal: Math.trunc(item?.percentageUpCm),
          }
          graphData.push(newData)
      })

    } else if(type === "VOReceiptFund") {
  
          // const totalData={
          //     name: "Total",
          //     uv: data?.totalCompleteHamletCount,
          //     pv: 0,
          //     amt: data?.totalHamletCount,
          //     pct: data?.totalHamletPercentage,
          // }
          // const sortedData = data?.hamletSummaryDTO === null ? data?.hamletSummaryDTO : sortByKey(data?.hamletSummaryDTO, 'percentage', false )
          data?.map((item)=>{
              let newData={}
              newData = {
                  name: item.upcmName,
                  uv: toggleType ? item?.completed : Math.trunc(item?.taskCompletion),
                  pv: 0,
                  amt: item?.total,
                  pct: Math.trunc(item?.taskCompletion),
                  calculatedTotal: toggleType ? item?.notStarted : 100 - item?.taskCompletion - item?.taskInProgress,
                  count: item?.completed,
                  notStarted: item?.notStarted,
                  inProgress: item?.inProgress,
                  pctInprogress: item?.taskInProgress
              }
              graphData.push(newData)

          })
        //  showData && graphData.push(totalData)
    }
  }

  return { graphData };
};
  export const summaryTransformer = (type, data, mapType, role, stageType, toggleType, question, totalSummaryData, summaryMapValue, setSummary, setSummaryMapValue, setSummaryRole, setBlockName, setIsError, setLoaderAction, handleBlockClick) => {
    let heading = [];
    let summaryValues = [];
    // const checkEmptyValues = graphTransformer(data, type)?.graphData?.every(item => item.uv === 0);
    if (type === "VOReceiptFund") {
      heading = ['UPCM name', 'Total VOs', "VOs Not Started", 'VOs In progress', "VOs Completed", '% of task completion'];
      summaryValues = Object.keys(data)?.length > 0 ? graphTransformer(data, 'VOReceiptFund', true, '', true)?.graphData?.map(summaryvalue => (
        <tr>
          <td>{summaryvalue?.name}</td>
          <td>{summaryvalue?.amt}</td>
          <td>{summaryvalue?.notStarted}</td>
          <td>{summaryvalue?.inProgress}</td>
          <td>{summaryvalue?.uv}</td>
          <td>{summaryvalue?.pct}</td>
  
          {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
        </tr>
      )) : []
    } else if (type === 'CSGDisbursement') {
        heading = ['UPCM name', "Total Didis",'Disbursement Not Started','Disbursement In progress', 'Disbursement Not Available', 'Disbursement Completed', '% of task completion'];
        // console.log(data,"data>>>")
        summaryValues = Object.keys(data)?.length > 0  ? graphTransformer(data, 'CSGDisbursement', true, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue?.name}</td>
            <td>{summaryvalue?.amt}</td>
            <td>{summaryvalue?.notStarted}</td>
            <td>{summaryvalue?.inProgress}</td>
            <td>{summaryvalue?.notAvailable}</td>
            <td>{summaryvalue?.uv}</td>
            <td>{summaryvalue?.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } 
  
    return {
      heading,
      summaryValues,
    };
  };

  const TableColumns = (type, matrixType) => {
    let colums = []
    switch (type) {
        case 'CSGDisbursement':
            colums = [
                {
                    key: "",
                    text: `S. No.`,
                    formatter: (cell, row, rowIndex) => rowIndex + 1,
                },
                {
                    key: "upcmName",
                    text: `UPCM`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "didiName",
                    text: `Didi name`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "didiId",
                    text: `Didi ID`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "taskStatus",
                    text: `Task status`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                  key: "voName",
                  text: `VO`,
                  formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
              },
              {
                key: "village",
                text: `Village`,
                formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
            },
                {
                    key: "block",
                    text: `Block`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "district",
                    text: `District`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                  key: "state",
                  text: `State`,
                  formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
              },

            ]
              break;
                case 'VOReceiptFund':
                    colums = [
                        {
                            key: "",
                            text: `S. No.`,
                            formatter: (cell, row, rowIndex) => rowIndex + 1,
                        },{
                          key: "upcmName",
                          text: `UPCM`,
                          formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                      },
                        {
                            key: "voName",
                            text: `VO name`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                        },
                        {
                            key: "taskStatus",
                            text: `Task status`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                          key: "village",
                          text: `Village`,
                          formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                      },
                        {
                            key: "block",
                            text: `Block`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                            key: "district",
                            text: `District`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                          key: "state",
                          text: `State`,
                          formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                      },
        
                    ]
                    break;


        default:
            colums = []
    }
    return colums;
}
export default TableColumns;

  export const getGrantDiagramViewApiPath = (type) => {
    let apiPaths = {
      // csgProgress: `${GlobalConfig.API_ROOT}/survey-service/baseline-web/upcm/view`,
      VOReceiptFund: `${GlobalConfig.API_ROOT}/mission-service/csg-dashboard/fund-summary`,
      CSGDisbursement: `${GlobalConfig.API_ROOT}/mission-service/csg-dashboard/disbursement-summary`,
    };
    return apiPaths[type];
}
export const getGrantSourceDataApiPath = (type, payload) => {
  let apiPaths = {
    // csgProgress: `${GlobalConfig.API_ROOT}/survey-service/baseline-web/source-data`,
    VOReceiptFund: `${GlobalConfig.API_ROOT}/mission-service/csg-dashboard/fund-source`,
    CSGDisbursement: `${GlobalConfig.API_ROOT}/mission-service/csg-dashboard/disbursement-source`,
  };
  return apiPaths[type];
}

//   **************Horizontal Barchart**************

export const RenderCustomizedLabelHorizontal = (props) => {
  const { x, y, width, value, toggleType } = props;
  const radius = -15;
  // console.log(props, "props");

  return (
      <g>
          <text x={x + 4 + width} y={y - radius} fill="#000" textAnchor="right" >
              {toggleType ? value : `${value}%`}
          </text>
      </g>
  );
};

export const getHorizontalGraphType = (type, toggleType) => {
  switch (type) {
          case "VOReceiptFund":
            return (
              <>
                    <Bar barSize={111} dataKey="uv" stackId="a" fill="#8BAAFF" isAnimationActive={false}>
               {/* <LabelList dataKey={toggleType ? "uv" : "pctTotal"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
            </Bar>
            <Bar barSize={111} dataKey={toggleType ? "inProgress" : "pctInprogress"} stackId="a" fill="#EAEDFC" isAnimationActive={false}>
              {/* <LabelList dataKey="uv" content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
          </Bar>
                    <Bar barSize={111} dataKey="calculatedTotal" stackId="a" fill="#d3d3d3" isAnimationActive={false}>
                <LabelList dataKey={toggleType ? "amt" : "pct"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' />
            </Bar>
           
              </>
             
            )
            case "CSGDisbursement":
              return (
                <>
                      <Bar barSize={111} dataKey="uv" stackId="a" fill="#8BAAFF" isAnimationActive={false}>
                 {/* <LabelList dataKey={toggleType ? "uv" : "pctTotal"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
              </Bar>
              <Bar barSize={111} dataKey={toggleType ? "inProgress" : "pctInprogress"} stackId="a" fill="#EAEDFC" isAnimationActive={false}>
                {/* <LabelList dataKey="uv" content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
            </Bar>
            <Bar barSize={111} dataKey={toggleType ? "notAvailable" : "pctNotAvailable"} stackId="a" fill="#808080" isAnimationActive={false}>
                {/* <LabelList dataKey="uv" content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
            </Bar>
                      <Bar barSize={111} dataKey="calculatedTotal" stackId="a" fill="#d3d3d3" isAnimationActive={false}>
                  <LabelList dataKey={toggleType ? "amt" : "pct"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' />
              </Bar>
             
                </>
               
              )


      default:
          break;
  }
};
