import React, { useEffect, useState } from 'react'
import UploadFile from '../../UploadFile'
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { commonService } from "../../../../services/common";
import GlobalConfig from '../../../../config/globalconfig';
import PreLoader from '../../Preloader';
import { resetApiError, setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import InProcessing from '../../InProcessing';
import './style.scss'
import axios from 'axios';
import Table from '../../Table';
import TableColumns from './column';
const BackupFileUploadModal = ({ modalParameters: { userType, bodyMsg, surveyDataStatus, setSelectionStatus }, closePopup }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [zipFileName, setZipFileName] = useState('');

    // const [data, setData] = useState(false);
    const [formInfo, setFormInfo] = useState({
        id: 'users',
        value: '',
        accept: ['.txt', '.zip'],
        fileName: '',
        isError: false,
        errorMsg: ''
    });
    const [dataUploadPercentage, setDataUploadPercentage] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetApiError())
    }, [])

    const handleChange = (e) => {
        const updatedForm = { ...formInfo };
        // console.log("e.currentTarget.files[0].type",e.currentTarget.files[0].type)
        setApiErrorMsg('')
        updatedForm.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedForm.value = e.currentTarget.files[0]
        // if ((e.currentTarget.files[0].size / 1024).toFixed(4) > 500) {
        //     updatedForm.errorMsg = `File size exceeds the max allowed size`
        //     updatedForm.isError = true
        // } else 
        if (e.currentTarget.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        && e.currentTarget.files[0].type !== 'text/plain' && e.currentTarget.files[0].type !== 'application/x-zip-compressed'
        && e.currentTarget.files[0].type !== 'application/zip') {
            updatedForm.errorMsg = `File Format do not support`
            updatedForm.isError = true
        } else {
            updatedForm.errorMsg = ``
            updatedForm.isError = false
        }
        setFormInfo(updatedForm);
    }

    const onSubmit = () => {
        const { referenceId } =
            JSON.parse(localStorage.getItem("userAuth")) || "";
        // const stateId = referenceId[0]?.id;
        setIsLoading(true)
        // try {
            const formData = new FormData();
            formData.append('file', formInfo?.value);
            const response = axios.post( `${GlobalConfig.API_ROOT}/sync-server/backup-file/upload`, formData, {
                onUploadProgress: (progressEvent) => {
                   setDataUploadPercentage((progressEvent.progress * 100).toFixed(1))
                    // console.log(
                    //     `Uploading... ${(progressEvent.progress * 100).toFixed(
                    //         1
                    //     )}% @ ${Math.round(progressEvent.rate / 1024)}KB/s (${Math.round(
                    //         progressEvent.estimated
                    //     )} seconds left)`
                    // );
                }}).then((response) => {
                    const res = response.data;
                    if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                        // if (res?.data?.length > 0) {
                        //     dispatch(openModal(MODALS.ERROR_LIST_MODAL,
                        //         {
                        //             parentClass: "center-modal error-list-modal-wrap",
                        //             modalTitle: `Error`,
                        //             errorList: res?.data,
                        //         }))
                        // }
                        // else
                            setIsSuccess(true);
                            setZipFileName(res?.data[0]?.zipFileName);
                            setSelectionStatus(res?.data);
                    }
                    else
                        setApiErrorMsg(res.message)
                    setIsLoading(false)
                  })
                  .catch((error) => {
                    setIsLoading(false)
                    setApiErrorMsg(error?.message)
                    catchErrorHandler(error, dispatch(setApiError()));
                    console.error(error)
                })
              
            // const response = await commonService.uploadBackupImage(formData);
            // const res = response.data;
            // setData(res.data)
            // if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
            //     // if (res?.data?.length > 0) {
            //     //     dispatch(openModal(MODALS.ERROR_LIST_MODAL,
            //     //         {
            //     //             parentClass: "center-modal error-list-modal-wrap",
            //     //             modalTitle: `Error`,
            //     //             errorList: res?.data,
            //     //         }))
            //     // }
            //     // else
            //         setIsSuccess(true);
            // }
            // else
            //     setApiErrorMsg(res.message)
            // setIsLoading(false)

        // } catch (error) {
        //     setIsLoading(false)
        //     setApiErrorMsg(error?.message)
        //     catchErrorHandler(error, dispatch(setApiError()));
        //     console.error(error)
        // }
    }

    useEffect(() => {
        dispatch(resetApiError())
        return () => {
            setSelectionStatus('')
        }
    }, [])

    const handleDelete = () => {
        const updatedForm = { ...formInfo };
        updatedForm.fileName = '';
        updatedForm.errorMsg = ``;
        updatedForm.isError = false;
        updatedForm.value = '';
        setApiErrorMsg('')
        setFormInfo(updatedForm);
    }

    if (isLoading) {
        return <InProcessing title="File uploading is inprogress" uploadPercentage={Math.trunc(dataUploadPercentage)} maxPrct={100} />
    }

    return (
        <div className='upload-modal'>
            {isSuccess ?
                <ModalBody>
                    <h4>Zip file name: <span className='baselinebackupupload-span-head'>{zipFileName}</span></h4><br/>
                    <Table
                                className='pending-table baseline-backup-file-upload'
                                columns={TableColumns()}
                                data={surveyDataStatus}
                                checkbox={false}
                                selectAll={false}
                            />
                    {/* <div className='inner-div'>
                    {data?.map((i)=>{
                        return (
                        <><h4>{i.topicName}:</h4>
                        {
                            i?.responseDTO?.data?.map((val)=>{
                             return   <>
                                <li> <span>Client Id: </span>{val.CLIENT_ID} </li>
                                <li><span> Transaction Id: </span>{val.transactionId} </li>
                                </>
                             })
                       } </>)
                    })}</div> */}
                </ModalBody>
                : <ModalBody>
                    <h4>{bodyMsg}</h4>
                    <br />
                    <UploadFile
                        id={formInfo.id}
                        value={formInfo.value}
                        accept={formInfo.accept}
                        handleChange={e => handleChange(e)}
                        fileName={formInfo.fileName}
                        isError={formInfo.isError}
                        errorMsg={formInfo.errorMsg}
                        key={formInfo.id}
                        handleDelete={handleDelete}
                    />
                    <ul className='upload-note'>
                        {/* <li>Max upload file size: 500 KB</li> */}
                        <li>Accepted format: .txt, .zip</li>
                    </ul>
                </ModalBody>}
            {apiErrorMsg && <span className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</span>}
            <ModalFooter>
                {isSuccess ? <>
                    <button className="primary-btn" onClick={closePopup}>
                        OK
                    </button>
                </>
                    :
                    <>
                        <button className="secondary-btn ml" onClick={closePopup}>
                            Cancel
                        </button>
                        <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                            Submit
                        </button>
                    </>
                }
            </ModalFooter>
        </div>
    )
}

export default BackupFileUploadModal