import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    LabelList,
    YAxis,
    Tooltip,
} from "recharts";
import NoDataFound from "../../../../components/Common/NoDataFound";
import { horizontalTooltipElementcontent } from "../../utils";

const CustomTooltipElement = ({ active, payload, label, type, toggleType, subject, doer }) => {
    if (active && payload && payload.length) {

        return (
            <div id="tooltip" className="right">
                <div className="tooltip-arrow" />
                <div className="tooltip-label">
                    <ul>
                        {horizontalTooltipElementcontent(payload, label, type, toggleType, subject, doer)}
                    </ul>
                </div>
            </div>
        );
    }

    return null;
};

export const RenderCustomizedLabel = (props) => {
    const { x, y, width, value, toggleType } = props;
    const radius = -15;


    return (
        <g>
            <text x={x + 4 + width} y={y - radius} fill="#000" textAnchor="right" >
                {toggleType ? value : `${value}%`}
            </text>
        </g>
    );
};

const ChartView = ({ slide, apiData, type, toggleType, activity }) => {
    const { doer, subject } = activity

    const getGraphType = (type) => {
        switch (type) {
            case "overall":
            default:
                return (
                    <>
                        <Bar barSize={111} dataKey="uv" stackId="a" fill="#8BAAFF" isAnimationActive={false} background={{ fill: '#fff' }}>
                        </Bar>
                        <Bar barSize={111} dataKey={toggleType ? "inProgressCount" : "pctInprogress"} stackId="a" fill="#EAEDFC" isAnimationActive={false}>
                        </Bar>
                        <Bar barSize={111} dataKey={toggleType ? "notAvailableCount" : "pctNotAvailable"} stackId="a" fill="#808080" isAnimationActive={false}>
                        </Bar>
                        <Bar barSize={111} dataKey={toggleType ? "notStartedCount" : "pctNotStarted"} stackId="a" fill="#d3d3d3" isAnimationActive={false}>
                        </Bar>
                        <Bar barSize={111} dataKey="calculatedTotal" stackId="a" fill="#f0f0f0" isAnimationActive={false}>
                            <LabelList dataKey={toggleType ? "amt" : "pct"} content={<RenderCustomizedLabel toggleType={toggleType} />} position='right' />
                        </Bar>

                    </>
                )
        }
    };

    // let checkEmpty = apiData.every(item => item.uv === 0 || [undefined, null, NaN].includes(item.uv));

    return (
        <div className="line-chart">
            {apiData.length ? <BarChart
                layout="vertical"
                width={slide ? window.innerWidth - 860 : window.innerWidth - 400}
                height={apiData?.length > 10 ? apiData?.length * 30 : 288}
                data={apiData}
                margin={{
                    top: 5,
                    right: 55,
                    left: 10,
                    bottom: 25,
                }}
            >
                <XAxis type="number"
                    allowDecimals={false}
                    domain={toggleType ? [0, apiData[0]?.amt] : [0, 100]}
                    tickFormatter={(tick) => {
                        return toggleType ? tick : `${tick}%`;
                    }}
                    tick={{

                        fontSize: "12px",
                        // width: '60',

                    }}
                    label={{ value: toggleType ? `No. of ${subject}` : `% of ${subject}`, position: 'bottom', fontSize: '14px', fill: '#222E50', }}


                />
                <YAxis
                    // label="height"
                    // tickSize={6}
                    tickLine={false}
                    width={slide ? 150 : 210}
                    tick={{
                        textAlign: "left",
                        fontSize: "14px",
                        // width: '60',
                        letterSpacing: "0px",
                        fill: "#000",
                        color: "#000",
                        opacity: "0.7",
                    }}
                    type="category"
                    dataKey="name"
                />
                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltipElement toggleType={toggleType} subject={subject} doer={doer} />} />

                {getGraphType(type)}

            </BarChart> : <div style={{ width: '900px' }}><NoDataFound /></div>}
        </div>
    );
};

export default ChartView;
