import { useState, useEffect, useRef } from "react";
import CustomDropdown from "../Common/CustomDropdown";
import PreLoader from "../Common/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { setApiError, showLoaderAction , handleBreadCrumbData } from '../../storeManager/common/actions';
import { catchErrorHandler, getLocalStorage } from '../../services/commonUtils';
import { dashBoardService } from "../../pages/Dashboard/dashoardService";
import { dropDownTransformer, handleDropDownShow } from "../utils/utils";
import { ROLE_TYPE } from "../utils/constant";
import './style.scss'

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}



const GenericBreadcrumb = ({
  activityId,
  referenceValue,
  sourceType,
  summaryRole,
  setSummaryRole,
  setSummary,
  setShowLoader,
  percentNumberToggle,
  setMapState,
  setSourceData,
  sectionToggle,
  roleValue,
  setRoleValue,
  // getApiErrorFlag,
  setIsError,
  // setShowLoader,
  // showLoader
  dashComponent,
  getSummaryData,
  getSourceData
}) => {
  const dispatch = useDispatch();
  const { showLoader, breadCrumbData } = useSelector((state) => state.commonReducer);
  // const [showLoader, setShowLoader] = useState(true);
  // const [breadCrumbData, setBreadCrumbData] = useState([]);
  const [isFetch, setIsFetch] = useState(true);
  const [stateName, setStateName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [blockName, setBlockName] = useState("");
  const [villageName, setVillageName] = useState("");
  const [showDropdDown, setShowDropDown] = useState({
    showState: false,
    showDistrict: false,
    showBlock: false,
    showVillage: false,
  });
  const [activeFilterList, setActiveFilterList] = useState({
    state: {},
    district: {},
    block: {},
    village: {}
  })
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [villageData, setVillageData] = useState([]);
  // const [isError, setIsError] = useState(false);

  // console.log(sourceType, "sourceType");
  
  const previousRefValue = usePrevious(referenceValue);

  // console.log(previousRefValue, "previousRefValue=>>");
  // console.log(referenceValue, "referenceValue=>>");


  const { roleName, referenceName, referenceId, referenceId: id } = JSON.parse(localStorage.getItem("userAuth")) || "";
useEffect(() => {
  const userData = JSON.parse(localStorage.getItem("userAuth")) || "";
  const refObj = getLocalStorage('activeReff')
  const currentRole = { [userData?.roleName?.toLowerCase()]: { roleName: userData?.roleName, roleId: refObj?.id }}
  setActiveFilterList({ ...activeFilterList, ...currentRole })
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[])
  

  // const apiErrorFlag =(isError)=>{
  //   getApiErrorFlag(isError)
  // }
  const handleDropDown = (dropDownData, role = roleName) => {
    const newShowState = handleDropDownShow(role)
    setShowDropDown({
      ...showDropdDown,
      ...newShowState
    })
    if (role === ROLE_TYPE.NATIONAL) {
      setStateData(dropDownData);
    } else if (role === ROLE_TYPE.STATE) {
      setDistrictData(dropDownData);
    } else if (role === ROLE_TYPE.DISTRICT) {
      setBlockData(dropDownData);
    } else if (role === ROLE_TYPE.BLOCK) {
      setVillageData(dropDownData);
    }
  }

  const fetchDropDownData = (breadCrumbData, role) => {
    
    const dropDownData = dropDownTransformer(breadCrumbData, role);

  //  handleDropDown (dropDownData, role)
    setIsFetch(false);
    return dropDownData;
  };

  const getBreadCrumbData = async (role, refId) => {
    dispatch(showLoaderAction(true));
    let dropDownData = breadCrumbData[role+'_'+refId]
    try {
      if(!dropDownData) {
      const res = await dashBoardService.getBreadCrumbApi(role, refId);
      const resObj = res.data;
      if (resObj?.status === "SUCCESS") {
         dropDownData = dropDownTransformer(
          resObj?.data?.localeResponse,
          role
        );
        dispatch(handleBreadCrumbData({role: role, refId, data : dropDownData}))
      } else {
        dispatch(showLoaderAction(false));
      }
    }
    handleDropDown(dropDownData, role)
    } catch (error) {
      dispatch(showLoaderAction(false));

      catchErrorHandler(error, dispatch(setApiError()));
    }
  };

  const getBreadCrumbDataApi = async (roleName, refId, runFetch) => {
    dispatch(showLoaderAction(true));
    let dropDownData = breadCrumbData[roleName+'_'+refId];
    try {
      if(!dropDownData) {
      const res = await dashBoardService.getBreadCrumbApi(roleName, refId);
      const resObj = res.data;
      if (resObj?.status === "SUCCESS") {
        if(isFetch || runFetch) {
          dropDownData = fetchDropDownData(resObj?.data?.localeResponse, roleName);

          dispatch(handleBreadCrumbData({role: roleName, refId, data : dropDownData}))
        }        

        // console.log(resObj.data, "resobj");
    } else {
    dispatch(showLoaderAction(false));
    }
  }
    handleDropDown(dropDownData, roleName)
    
    sectionToggle.includes("Chart")
    ? await getSummaryData(roleName, refId)
    : await getSourceData(roleName, refId);

    dispatch(showLoaderAction(false));

    } catch (error) {
      // console.error(error);
      dispatch(showLoaderAction(false));

      catchErrorHandler(error, dispatch(setApiError()));
    }
  };


  useEffect(() => {
    // const refId = referenceId?.find((item) => item.name === referenceValue);
    if (roleName === 'National' && summaryRole !== 'National') {
      setMapState('')
      setSummaryRole('')
    }
    let role, id;
    role = activeFilterList.village?.roleName || activeFilterList.block?.roleName || activeFilterList.district?.roleName || roleValue.roleName;
    id = activeFilterList.village?.roleId || activeFilterList.block?.roleId || activeFilterList.district?.roleId || roleValue.roleId;
    getBreadCrumbDataApi(role, id || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionToggle, sourceType, activityId]);



  useEffect(() => {
    if ((previousRefValue && previousRefValue !== referenceValue) && roleName !== 'National') {
      getBreadCrumbDataApi(roleValue.roleName, roleValue.roleId || "", true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceValue]);



  const handleVillage = (value) => {
    if (value === 'clear') {
      setVillageName('');
      setActiveFilterList({ ...activeFilterList, village: {} })
      dispatch(setRoleValue({ roleName: activeFilterList?.block?.roleName, roleId: activeFilterList?.block?.roleId }));
      sectionToggle.includes("Chart")
        ? getSummaryData(activeFilterList?.block?.roleName, activeFilterList?.block?.roleId)
        : getSourceData(activeFilterList?.block?.roleName, activeFilterList?.block?.roleId);
    } else {
      const dataId = villageData?.find((item) => item?.value === value);
      setVillageName(value);
      dispatch(setRoleValue({ roleName: 'Village', roleId: dataId?.id }));
      setActiveFilterList({ ...activeFilterList, village: { roleName: 'Village', roleId: dataId?.id } })
      sectionToggle.includes("Chart")
        ? getSummaryData("Village", dataId?.id)
        : getSourceData("Village", dataId?.id);
    }
  };

  const handleBlock = (value) => {
    if (value === 'clear') {
      // setActiveFilterList({ ...activeFilterList, village: { roleName: 'Village', roleId: dataId?.id } })
      setBlockName('');
      setActiveFilterList({ ...activeFilterList, block: {}, village: {} })
      dispatch(setRoleValue({ roleName: activeFilterList?.district?.roleName, roleId: activeFilterList?.district?.roleId }));
      sectionToggle.includes("Chart")
        ? getSummaryData(activeFilterList?.district?.roleName, activeFilterList?.district?.roleId)
        : getSourceData(activeFilterList?.district?.roleName, activeFilterList?.district?.roleId);
      setShowDropDown({
          ...showDropdDown,
        showVillage: false,
        });
    }
    else {
      const dataId = blockData.find((item) => item.value === value);
      setBlockName(value);
      dispatch(setRoleValue({ roleName: 'Block', roleId: dataId.id }));
      setActiveFilterList({ ...activeFilterList, block: { roleName: 'Block', roleId: dataId.id }, village:{} })
      // getSourceData("Block", dataId.id);
      sectionToggle.includes("Chart")
        ? getSummaryData("Block", dataId.id)
        : getSourceData("Block", dataId.id);
      getBreadCrumbData('Block', dataId.id);
      setShowDropDown({
        ...showDropdDown,
      showVillage: true,
      });
    }
    setVillageName("");

  };

  const handleDistrict = (value) => {
    if (value === 'clear') {
      // setActiveFilterList({ ...activeFilterList, village: { roleName: 'Village', roleId: dataId?.id } })
      setActiveFilterList({ ...activeFilterList, district: {}, block: {},village: {} })
      setDistrictName('');
      dispatch(setRoleValue({ roleName: activeFilterList?.state?.roleName, roleId: activeFilterList?.state?.roleId }));
      sectionToggle.includes("Chart")
        ? getSummaryData(activeFilterList?.state?.roleName, activeFilterList?.state?.roleId)
        : getSourceData(activeFilterList?.state?.roleName, activeFilterList?.state?.roleId);
        setShowDropDown({
          ...showDropdDown,
          showBlock: false,
          showVillage: false,
        });
      } else {
      const dataId = districtData.find((item) => item.value === value);
      setDistrictName(value);
      dispatch(setRoleValue({ roleName: 'District', roleId: dataId.id }));
      setActiveFilterList({ ...activeFilterList, district: { roleName: 'District', roleId: dataId.id }, block:{},village:{} })
      sectionToggle.includes("Chart")
        ? getSummaryData("District", dataId.id)
        : getSourceData("District", dataId.id);
      getBreadCrumbData("District", dataId.id);
      setShowDropDown({
        ...showDropdDown,
        showBlock: true,
        showVillage: false,
      });
    }
    

    setBlockName("");
    setVillageName("");
  };

  const handleState = (value) => {
    if (value === 'clear') {
      // setActiveFilterList({ ...activeFilterList, village: { roleName: 'Village', roleId: dataId?.id } })
      dispatch(setRoleValue({ roleName: 'National', roleId: '' }));
      setStateName('');
      sectionToggle.includes("Chart")
        ? getSummaryData('National', '')
        : getSourceData('National', '');
    } else {
      const dataId = stateData.find((item) => item.value === value);
      setStateName(value);
      dispatch(setRoleValue({ roleName: 'State', roleId: dataId.id }));
      setActiveFilterList({ ...activeFilterList, state: { roleName: 'State', roleId: dataId.id } })
      sectionToggle.includes("Chart")
        ? getSummaryData("State", dataId.id)
        : getSourceData("State", dataId.id);
      getBreadCrumbData("State", dataId.id);
    }
    setShowDropDown({
      ...showDropdDown,
      showDistrict: false,
      showBlock: false,
      showVillage: false,
    });
    setVillageName("");
    setBlockName("");
    setDistrictName("");
  };
 
  if (showLoader) {
    return <PreLoader />;
  }


  return (
    <div style={!['Map View', 'Summary View'].includes(sectionToggle) ? {} : { display: 'none' }} className="breadcrumb-wrapper">
      {roleName === "National" && (
        <>
          <div>India</div> {">"}
        </>
      )}
      {showDropdDown?.showState &&
        (roleName === "State" ? (
          referenceValue
        ) : (
          <div className='dropdown-label-wrap'>
          <span className='dropdown-label'>State</span>
          <CustomDropdown
            placeHolder="Select State"
            value={stateName}
            showValue={true}
            options={stateData}
            onChange={handleState}
            propWidth="190px"
            showSearchBar={true}
            showClear={true}
            handleClear={handleState}
          />
          </div>
        ))}
      {showDropdDown?.showDistrict &&
        (roleName === "District" ? (
          referenceValue
        ) : (
          <div className='dropdown-label-wrap'>
          <span className='dropdown-label'>District</span>
          <CustomDropdown
            placeHolder="Select District Name"
            value={districtName}
            showValue={true}
            showSearchBar={true}

            options={districtData}
            onChange={handleDistrict}
            propWidth="190px"
            showClear={true}
            handleClear={handleDistrict}
          />
          </div>
        ))}
      {showDropdDown?.showBlock &&
        (roleName === "Block" ? (
          referenceValue
        ) : (
          <div className='dropdown-label-wrap'>
          <span className='dropdown-label'>Block</span>
          <CustomDropdown
            options={blockData}
            showSearchBar={true}
            propWidth="190px"

            showValue={true}

            placeHolder="Select Block Name"
            value={blockName}
            onChange={handleBlock}
            showClear={true}
            handleClear={handleBlock}
          />
          </div>
        ))}
      {showDropdDown?.showVillage && (
        <div className='dropdown-label-wrap'>
        <span className='dropdown-label'>Village</span>
          <CustomDropdown
            options={villageData}
            showValue={true}
            showSearchBar={true}

            placeHolder='Select Village'
            value={villageName}
            propWidth="max-content"
            onChange={handleVillage}
            showClear={true}
            handleClear={handleVillage}
          />
          </div>
      )}
      
    </div>
  );
};

export default GenericBreadcrumb;
