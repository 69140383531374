import React from 'react';

const TableColumns = (props) => {

    const modifyAssets = (row) => {
        return <span className='assetcount-field'>{row?.assetType}:{row?.count}</span>;
    };
    const modifyAmount = (cell) => {
        return <span className='assetcount-field'>₹ {cell}</span>;
    };
    
    return [
        {
            key: "Assets",
            text: `Assets`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return modifyAssets(row)
            },
        },
        {
            key: "assetAmount",
            text: `Amount`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return modifyAmount(cell)
            },
        },

    ]
}
export default TableColumns;
