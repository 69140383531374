import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCurrentUser } from './services/commonUtils';

const PrivateRoute = ({ children, userRoles = [] }) => {
  const userAuth = localStorage.getItem('token') || ''
  const currentUserRole = getCurrentUser();
  // return userAuth ? children : <Navigate to="/" replace />;
  if (userAuth && currentUserRole) {
    if (userRoles.length > 0) {
      if (userRoles.includes(currentUserRole)) {
        return children
      } else {
        return <Navigate to="/" />
      }
    } else {
      return children
    }
  } else {
    return <Navigate to="/" replace />
  }

};
export default PrivateRoute;
