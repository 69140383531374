import React from 'react';
import './style.css';


const SourceData = ({ sourceData, setSourceData, sourceType , showLoader}) => {
 

  return (
    <div className="source-data">
      {sourceData?.sourceValues?.length > 0 && <p className="sample-line">Showing 10 sample Data Source</p>}
    <div className="source-table">
        {sourceData?.sourceValues?.length > 0 ? <table className="generic-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            {sourceData?.heading?.map((heading) => (
              <th scope="col">{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sourceData?.sourceValues}
        </tbody>
      </table> : !showLoader && <h2 style={{ textAlign: 'center'}}>No Data Available at the moment</h2>}
    
    </div>
     
    </div>
  );
};

export default SourceData;
