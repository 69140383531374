import React from 'react';

const TableColumns = ({setIsModalOpen, setSelectedRow}) => {

    const handleActivityList = (row) => {
        let result;
        if (row?.activityName?.length === 1) {
            result = row?.activityName[0];
        } else {
            let remainingCount = row?.activityName?.length - 1;
            result = (
                <span className='activity-list-count-outer'>
            {row?.activityName[0]}.... <span onClick={() => handleActivityClick(row)} class="activity-list-count">{remainingCount} </span>
            </span>
            )
        }

        return result
    }

    const handleActivityClick = (row) =>{
        setIsModalOpen(true)
        setSelectedRow(row)
    }

    return [
        {
            key: "missionId",
            text: `Mission ID`,
            isSort: true
        },
        {
            key: "missionName",
            text: `Mission Name`,
            isSort: true
        },
        {
            key: "missionDisplayName",
            text: `Display Name`,
            isSort: true
        },
        {
            key: "activityCount",
            text: `Activity Count`,
            isSort: true
        },
        {
            key: "activityName",
            text: `List of Activities`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return handleActivityList(row, cell)
            },
        },
    ]
}
export default TableColumns;