import React, { useContext } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import { ProgramContext } from '../../../../pages/SuperAdmin/ProgramDetailsPage';

const SuperAdminDeleteInfoModal = ({ 
  modalParameters: { 
    message, 
    confirmAction, 
    btnText = "Confirm", 
    btnClass = "blue-btn", 
    id, 
    status, 
    setToastMessage, 
    isLivelihood = false 
  }, 
  closePopup 
}) => {
  // Always call useContext
  const context = useContext(ProgramContext);
  const { activeVillageId = null, fetchProgramWidgetList = () => {}, handleSetToastMessage = () => {} } = context || {};

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction(id, status);
    }
    closePopup();
    if (!isLivelihood) {
      if (activeVillageId) {
        fetchProgramWidgetList(activeVillageId);
      }
      if (handleSetToastMessage) {
        handleSetToastMessage('');
      }
    } else if (setToastMessage) {
      setToastMessage('');
    }
  };

  return (
    <div className='super-admin'>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <button className="white-btn livelihood-cancel-popup" onClick={closePopup}>
          Cancel
        </button>
        <button className={btnClass} onClick={handleConfirm}>
          {btnText}
        </button>
      </ModalFooter>
    </div>
  );
};

export default SuperAdminDeleteInfoModal;
