import React from 'react';
import unmap from '../../../../assets/images/git-pull-request.svg';
import plusSquare from "../../../../assets/images/plus-square.png";

const TableColumns = ({ handleDelete, setIsModalOpen, setSelectedRow }) => {

    const handleAssetCount = (row) => {
        return <span className="activity-list-count asset-count">{row.assets?.length || 0}</span>;
    };

    const handleProductCount = (row) => {
        return <span className="activity-list-count">{row.products?.length || 0}</span>;
    };

    const handleEventCount = (row) => {
        return <span className="activity-list-count">{row.eventsCounts || 0}</span>;
    };

    const handleAssetIncome = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    return [
        {
            key: "id",
            text: `Livelihood ID`,
            isSort: true
        },
        {
            key: "name",
            text: `Livelihood Name`,
            isSort: true
        },
        {
            key: "assetCounts",
            text: `Asset Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='livelihood-icon-wrap asset-details' onClick={() => handleAssetIncome(row)}>
                        {handleAssetCount(row)}
                        <img src={plusSquare} alt='plus' />
                    </div>
                );
            }
        },
        {
            key: "productCounts",
            text: `Product Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => {
                return handleProductCount(row);
            }
        },
        {
            key: "eventsCounts",
            text: `Event Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => {
                return handleEventCount(row);
            }
        },
        {
            key: "action",
            text: `Actions`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='livelihood-icon-wrap' onClick={() => handleDelete(row?.id)}>
                        <img src={unmap} alt="delete" height="16" /> Un-map
                    </div>
                );
            },
        },
    ];
};

export default TableColumns;
